import { connect } from 'react-redux';

import { Annotation } from '../../../utils/annotation';
import { wrapContent } from '../../../utils/content';
import { getSelectedHtml } from '../../../utils/selection';
import {
  AppDispatch,
  ReviewItems,
} from '../../app/actions';
import State from '../../app/state';
import {
  Actions,
  ContentAnnotations,
  Properties,
} from '../../components/ContentAnnotations';

interface OwnProps {
  label: string;
  itemId: string;
  contentId: string;
  sectionId: string;
}

const mapStateToProps = (state: State, ownProps: OwnProps): Properties => {
  let content = wrapContent(
    state.itemContent.contentLibrary[ownProps.contentId]
  ).innerHTML;

  let annotations = state.itemAnnotation[ownProps.contentId]
    ?.map((a) => JSON.parse(JSON.stringify(a)) as Annotation)
    .filter((a) => a.decorations.find((d) => d.type === "highlight"))
    .sort((a) => a.range.start);

  let cleanAnnotations: Array<Annotation> = [];
  annotations?.forEach((ann) => {
    if (cleanAnnotations.length === 0) cleanAnnotations.push(ann);
    else {
      let latest = cleanAnnotations.pop()!;
      if (latest.range.end === ann.range.start) {
        latest.range.end = ann.range.end;
        cleanAnnotations.push(latest);
      } else {
        cleanAnnotations.push(latest);
        cleanAnnotations.push(ann);
      }
    }
  });

  let annotationContent = cleanAnnotations
    .map((a) => getSelectedHtml(content, a.range))
    .filter((html, index, self) => self.indexOf(html) === index);

  return {
    label: ownProps.label,
    itemId: ownProps.itemId,
    sectionItems: state.itemContent.sectionItems[ownProps.sectionId],
    annotations: annotationContent,
  };
};

const mapDispatchToProps = (
  dispatch: AppDispatch,
  ownProps: OwnProps
): Actions => {
  return {
    onReviewItem: (itemId: string, sectionItems: Array<string>) =>
      dispatch(ReviewItems(itemId, sectionItems, ownProps.sectionId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContentAnnotations);
