import { connect } from "react-redux";
import { Action, Dispatch } from "redux";
import {
  ItemNavigation,
  REVIEW_ITEMS_COLLAPSE_TOGGLE,
  REVIEW_SUMMARY_COLLAPSE_TOGGLE,
  ToggleReviewFlag,
} from "../app/actions";
import State from "../app/state";
import { Actions, Review, Properties } from "../components/Review";

const mapStateToProps = (state: State): Properties => {
  let items = state.navigation.itemIdentifiers.map((id, index) => ({
    id,
    number: index + 1,
    flagged: state.navigation.itemsFlaggedForReview.indexOf(id) >= 0,
    seen:
      [
        ...state.navigation.itemsPreviouslyPresented,
        state.navigation.itemIdentifiers[state.navigation.currentItemIndex],
      ].indexOf(id) >= 0,
    complete: state.itemState[id] && !!state.itemState[id].response,
  }));

  return {
    itemsSnapshot: items,
    summaryExpanded: state.navigation.reviewSummaryExpanded,
    itemsExpanded: state.navigation.reviewItemsExpanded,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): Actions => {
  return {
    onToggleSummaryExpansion: () =>
      dispatch({ type: REVIEW_SUMMARY_COLLAPSE_TOGGLE }),
    onToggleItemsExpansion: () =>
      dispatch({ type: REVIEW_ITEMS_COLLAPSE_TOGGLE }),
    onItemFlagClicked: (itemId: string) => dispatch(ToggleReviewFlag(itemId)),
    onItemLinkClicked: (itemId: string) => dispatch(ItemNavigation(itemId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Review);
