import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Typography,
} from "@material-ui/core";
import React, { FunctionComponent } from "react";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import CloseIcon from "@material-ui/icons/Close";

export interface Properties {
  title: string;
  canPrevious: boolean;
  canNext: boolean;
}

export interface Actions {
  close: () => void;
  goFirst: () => void;
  goBackward: () => void;
  goForward: () => void;
  goLast: () => void;
}

type AllProperties = Properties & Actions;

export const ReviewHeader: FunctionComponent<AllProperties> = ({
  title,
  close,
  canPrevious,
  canNext,
  goFirst,
  goBackward,
  goForward,
  goLast,
}) => {
  return (
    <AppBar className="app-bar" position="fixed">
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          onClick={close}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
        <Box className="nav-button-container">
          <IconButton
            className="nav-button first"
            color="inherit"
            disabled={!canPrevious}
            onClick={goFirst}
          >
            <FirstPageIcon />
          </IconButton>
          <IconButton
            className="nav-button"
            color="inherit"
            disabled={!canPrevious}
            onClick={goBackward}
          >
            <NavigateBeforeIcon />
          </IconButton>
          <Typography display="inline" variant="h6" className="title">
            {title}
          </Typography>
          <IconButton
            className="nav-button"
            color="inherit"
            disabled={!canNext}
            onClick={goForward}
          >
            <NavigateNextIcon />
          </IconButton>
          <IconButton
            className="nav-button last"
            color="inherit"
            disabled={!canNext}
            onClick={goLast}
          >
            <LastPageIcon />
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
