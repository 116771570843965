import { Box, IconButton, Typography } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import React, { FunctionComponent } from "react";

export interface OwnProps {
  sectionId: string;
  children: React.ReactNode;
}

export interface Properties {
  label: string;
  description: string;
  items: Array<string>;
}

export interface Actions {
  onReviewGroup: (items: Array<string>) => void;
}

type AllProperties = Properties & Actions;

export const SkillGroup: FunctionComponent<AllProperties> = ({
  label,
  description,
  items,
  children,
  onReviewGroup,
}) => {
  return (
    <Box className="skill-group">
      <Box className="horizontal-stack spread overall-label">
        <Typography variant="h4" display="inline" className="main-label">
          {label}
        </Typography>
        <Typography variant="subtitle1" display="inline" className="align-self-center">
          {description}
        </Typography>
        <IconButton onClick={() => onReviewGroup(items)}>
          <VisibilityIcon />
        </IconButton>
      </Box>
      <Box className="horizontal-stack">{children}</Box>
    </Box>
  );
};
