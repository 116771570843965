import React, { FunctionComponent } from "react";

export interface Properties {
  warningTitle: string;
}

export const TimeoutWarningHeader: FunctionComponent<Properties> = ({
  warningTitle,
}) => {
  return (
    <span className="title">
      <span className="icon"></span>
      <span className="text">{warningTitle}</span>
    </span>
  );
};
