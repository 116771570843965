import { connect } from "react-redux";
import State from "../../../../app/state";
import {
  ManageContentDisplaySkillGroups,
  Properties,
  SkillGroup,
} from "../../../../components/ManageContentDisplaySkillGroups";

const mapStateToProperties = (state: State): Properties => {
  return {
    data: (state.manageContent.queryResults?.data as Array<SkillGroup>) || [],
  };
};

export default connect(mapStateToProperties)(ManageContentDisplaySkillGroups);
