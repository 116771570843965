import { connect } from "react-redux";
import State from "../app/state";
import { Header, Properties } from "../components/Header";

const mapStateToProps = (state: State): Properties => {
  return {
    location: state.navigation.location,
    loggedIn: state.user.loggedIn,
    isAdmin: state.user.isAdmin,
  };
};

export default connect(mapStateToProps)(Header);
