import React, { FunctionComponent } from "react";
import { connect } from "react-redux";
import State from "../../app/state";

interface OwnProps {
  sectionId: string;
  children: (label: string) => React.ReactNode;
}

interface Properties {
  groupId: Array<string>;
}

type AllProperties = OwnProps & Properties;

const ForEachSkillGroupInSection: FunctionComponent<AllProperties> = ({
  groupId,
  children,
}) => {
  return (
    <>
      {groupId.map((id) => (
        <div key={id}>{children(id)}</div>
      ))}
    </>
  );
};

const mapStateToProps = (state: State, ownProps: OwnProps): Properties => {
  return {
    groupId: state.skills.sectionGroups[ownProps.sectionId],
  };
};

export default connect(mapStateToProps)(ForEachSkillGroupInSection);
