import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, { FunctionComponent } from "react";
import FlagIcon from "@material-ui/icons/Flag";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import FilterListIcon from "@material-ui/icons/FilterList";
import FlagOutlinedIcon from "@material-ui/icons/FlagOutlined";
import VisibilityIcon from "@material-ui/icons/Visibility";
import moment from "moment";

export interface ItemData {
  id: string;
  number: number;
  isCorrect: boolean;
  preview: string;
  timeInSeconds: number;
  flagged: boolean;
}

export interface Properties {
  correctnessFilter: string;
  flaggedFilter: string;
  items: Array<ItemData>;
}

export interface Actions {
  onReviewItem: (id: string, items: Array<string>) => void;
  nextCorrectnessFilter: () => void;
  nextFlaggedFilter: () => void;
}

type AllProperties = Properties & Actions;

export const ItemsTable: FunctionComponent<AllProperties> = ({
  correctnessFilter,
  flaggedFilter,
  items,
  onReviewItem,
  nextCorrectnessFilter,
  nextFlaggedFilter,
}) => {
  return (
    <>
      <Table className="items-table">
        <TableHead>
          <TableRow>
            <TableCell className="small-column centered">
              <Typography>#</Typography>
            </TableCell>
            <TableCell className="centered">
              <IconButton onClick={nextCorrectnessFilter}>
                {correctnessFilter === "none" && <FilterListIcon />}
                {correctnessFilter === "correct" && <CheckCircleIcon />}
                {correctnessFilter === "incorrect" && <CancelIcon />}
              </IconButton>
            </TableCell>
            <TableCell>
              <Typography>Preview</Typography>
            </TableCell>
            <TableCell className="small-column centered">
              <Typography>Time</Typography>
            </TableCell>
            <TableCell className="small-column centered">
              <IconButton onClick={nextFlaggedFilter}>
                {flaggedFilter === "none" && <FilterListIcon />}
                {flaggedFilter === "flagged" && <FlagIcon />}
                {flaggedFilter === "unflagged" && <FlagOutlinedIcon />}
              </IconButton>
            </TableCell>
            <TableCell className="small-column centered">
              <IconButton
                onClick={() =>
                  items.length > 0 &&
                  onReviewItem(
                    items[0].id,
                    items.map((i) => i.id)
                  )
                }
              >
                <VisibilityIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item, index) => (
            <TableRow key={item.number}>
              <TableCell className="centered">
                <Typography>{item.number}</Typography>
              </TableCell>
              <TableCell className="centered">
                {item.isCorrect ? <CheckIcon /> : <ClearIcon />}
              </TableCell>
              <TableCell>
                <Typography>
                  <span
                    className="item-table-preview"
                    dangerouslySetInnerHTML={{ __html: item.preview }}
                  ></span>
                </Typography>
              </TableCell>
              <TableCell className="centered">
                <Typography>
                  {moment
                    .utc(
                      moment
                        .duration(item.timeInSeconds, "seconds")
                        .asMilliseconds()
                    )
                    .format("m:ss")}
                </Typography>
              </TableCell>
              <TableCell className="centered">
                {item.flagged && <FlagIcon />}
              </TableCell>
              <TableCell className="centered">
                <IconButton
                  onClick={() =>
                    onReviewItem(
                      item.id,
                      items.map((i) => i.id)
                    )
                  }
                >
                  <VisibilityIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};
