import { connect } from "react-redux";
import { AppDispatch, NavigateTo } from "../app/actions";
import State from "../app/state";
import {
  Actions,
  Properties,
  SummarySectionTable,
} from "../components/SummarySectionTable";

const mapStateToProps = (state: State): Properties => {
  return {
    sections: Object.values(state.sectionResults),
  };
};

const mapDispatchToProps = (dispatch: AppDispatch): Actions => {
  return {
    onNavigate: (to: string) => dispatch(NavigateTo(to)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SummarySectionTable);
