import React, { FunctionComponent, useEffect } from "react";
import { onAltHotkey } from "../../utils/hotkeys";

export interface Properties {
  endExam: boolean;
  periodicTable: boolean;
  previous: boolean;
  next: boolean;
  navigation: boolean;
  endSection: boolean;
  reviewAll: boolean;
  reviewIncomplete: boolean;
  reviewFlagged: boolean;
  reviewScreen: boolean;
  treatNextAsEndSection: boolean;
  completableItems: number;
  completedItems: number;
  warnIfNotAllItemsCompleted: boolean;
  itemIsRequired: boolean;
  itemIsCompleted: boolean;
}

export interface Actions {
  onEndExamClicked: () => void;
  onPeriodicTableClicked: () => void;
  onPreviousClicked: () => void;
  onNavigationClicked: () => void;
  onNextClicked: () => void;
  onEndSectionClicked: () => void;
  onReviewAllClicked: () => void;
  onReviewIncompleteClicked: () => void;
  onReviewFlaggedClicked: () => void;
  onReviewScreenClicked: () => void;
  onEndSectionWithPendingItemsWarning: () => void;
  onRequiredItemAbandoned: () => void;
}

type AllProperties = Properties & Actions;

export const NavigationBar: FunctionComponent<AllProperties> = ({
  endExam,
  periodicTable,
  previous,
  next,
  navigation,
  endSection,
  reviewAll,
  reviewIncomplete,
  reviewFlagged,
  reviewScreen,
  treatNextAsEndSection,
  completableItems,
  completedItems,
  warnIfNotAllItemsCompleted,
  itemIsRequired,
  itemIsCompleted,
  onEndExamClicked,
  onPeriodicTableClicked,
  onPreviousClicked,
  onNavigationClicked,
  onNextClicked,
  onEndSectionClicked,
  onReviewAllClicked,
  onReviewIncompleteClicked,
  onReviewFlaggedClicked,
  onReviewScreenClicked,
  onEndSectionWithPendingItemsWarning,
  onRequiredItemAbandoned,
}) => {
  const tryEndSection = () => {
    if (itemIsRequired && !itemIsCompleted) onRequiredItemAbandoned();
    else if (warnIfNotAllItemsCompleted && completableItems > completedItems)
      onEndSectionWithPendingItemsWarning();
    else onEndSectionClicked();
  };

  const tryNext = () => {
    if (itemIsRequired && !itemIsCompleted) onRequiredItemAbandoned();
    else onNextClicked();
  };

  useEffect(() => {
    let hotkeyListeners = [
      onAltHotkey("t", onPeriodicTableClicked, periodicTable),
      onAltHotkey("e", onEndExamClicked, endExam),
      onAltHotkey("e", tryEndSection, endSection),
      onAltHotkey("w", onReviewScreenClicked, reviewScreen),
      onAltHotkey("p", onPreviousClicked, previous),
      onAltHotkey("v", onNavigationClicked, navigation),
      onAltHotkey("n", treatNextAsEndSection ? tryEndSection : tryNext, next),
      onAltHotkey("a", onReviewAllClicked, reviewAll),
      onAltHotkey("i", onReviewIncompleteClicked, reviewIncomplete),
      onAltHotkey("r", onReviewFlaggedClicked, reviewFlagged),
    ];

    return () => hotkeyListeners.forEach((unsub) => unsub());
  });

  return (
    <>
      <div className="nav-bar">
        <div className="left">
          {periodicTable && (
            <span
              className="nav-button periodic-table"
              onClick={onPeriodicTableClicked}
            >
              <span>Periodic&nbsp;</span>
              <span className="underline">T</span>
              <span>able</span>
            </span>
          )}
          {endExam && (
            <span className="nav-button end-exam" onClick={onEndExamClicked}>
              <span className="underline">E</span>
              <span>nd Exam</span>
            </span>
          )}
          {endSection && (
            <span className="nav-button end-section" onClick={tryEndSection}>
              <span className="underline">E</span>
              <span>nd Section</span>
            </span>
          )}
          {reviewScreen && (
            <span
              className="nav-button review-screen"
              onClick={onReviewScreenClicked}
            >
              <span>Revie</span>
              <span className="underline">w</span>
              <span>&nbsp;screen</span>
            </span>
          )}
        </div>

        <div className="right">
          {previous && (
            <span className="nav-button previous" onClick={onPreviousClicked}>
              <span className="underline">P</span>
              <span>revious</span>
            </span>
          )}
          {navigation && (
            <span
              className="nav-button navigation"
              onClick={onNavigationClicked}
            >
              <span>Na</span>
              <span className="underline">v</span>
              <span>igation</span>
            </span>
          )}
          {next && (
            <span
              className="nav-button next"
              onClick={treatNextAsEndSection ? tryEndSection : tryNext}
            >
              <span className="underline">N</span>
              <span>ext</span>
            </span>
          )}
          {reviewAll && (
            <span
              className="nav-button review-all"
              onClick={onReviewAllClicked}
            >
              <span>Review&nbsp;</span>
              <span className="underline">A</span>
              <span>ll</span>
            </span>
          )}
          {reviewIncomplete && (
            <span
              className="nav-button review-incomplete"
              onClick={onReviewIncompleteClicked}
            >
              <span>Review&nbsp;</span>
              <span className="underline">I</span>
              <span>ncomplete</span>
            </span>
          )}
          {reviewFlagged && (
            <span
              className="nav-button review-flagged"
              onClick={onReviewFlaggedClicked}
            >
              <span className="underline">R</span>
              <span>eview Flagged</span>
            </span>
          )}
        </div>
      </div>
    </>
  );
};
