import { connect } from 'react-redux';

import State from '../../app/state';
import {
  ContentAreaBarGraph,
  Properties,
} from '../../components/ContentAreaBarGraph';

interface OwnProps {
  sectionId: string;
  groupId: string;
}

const mapStateToProps = (state: State, ownProps: OwnProps): Properties => {
  let sectionSKills = state.skills.skills[ownProps.sectionId];
  let skills = Object.values(sectionSKills)
    .filter((s) => s.groupId === ownProps.groupId)
    .sort((a, b) => a.label.localeCompare(b.label));
  return {
    skills,
  };
};

export default connect(mapStateToProps)(ContentAreaBarGraph);
