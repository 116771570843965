import { Action } from 'redux';

import { uploadPackage } from '../api/package';
import {
  AppThunk,
  SelectUploadFileAction,
  UPLOAD_PACKAGE_FILE_FAILED,
  UPLOAD_PACKAGE_FILE_SELECTED,
  UPLOAD_PACKAGE_FILE_STARTED,
  UPLOAD_PACKAGE_FILE_SUCCESS,
} from '../app/actions';
import State, { DefaultState } from './state';

const reducer = (state: State, action: Action): State => {
  if (!state) return DefaultState;
  switch (action.type) {
    case UPLOAD_PACKAGE_FILE_SELECTED:
      return {
        ...state,
        fileBeingUploaded: (action as SelectUploadFileAction).file,
      };
    case UPLOAD_PACKAGE_FILE_STARTED:
      return {
        ...state,
        uploadProcessing: true,
        uploadSuccessful: false,
        uploadFailed: false,
      };
    case UPLOAD_PACKAGE_FILE_SUCCESS:
      return {
        ...state,
        fileBeingUploaded: undefined,
        uploadProcessing: false,
        uploadSuccessful: true,
        uploadFailed: false,
      };
    case UPLOAD_PACKAGE_FILE_FAILED:
      return {
        ...state,
        fileBeingUploaded: undefined,
        uploadProcessing: false,
        uploadSuccessful: false,
        uploadFailed: true,
      };
    default:
      return state;
  }
};

export default reducer;

export const BeginPackageFileUpload =
  (): AppThunk => async (dispatch, getState) => {
    try {
      let state = getState().uploadPackage;
      let file = state.fileBeingUploaded;
      if (!file) return;

      dispatch({ type: UPLOAD_PACKAGE_FILE_STARTED });
      await uploadPackage(file);
      dispatch({ type: UPLOAD_PACKAGE_FILE_SUCCESS });
    } catch (error) {
      dispatch({ type: UPLOAD_PACKAGE_FILE_FAILED });
    }
  };
