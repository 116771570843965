export type LoginResult = {
  isAdmin: boolean;
};

type State = {
  loggedIn: boolean;
  isAdmin: boolean;
  profileMenuOpen: boolean;
  identityProvider?: string;
  email?: string;
  profilePic?: string;
  idToken?: string;
};

export default State;

export const DefaultState: State = {
  loggedIn: false,
  isAdmin: false,
  profileMenuOpen: false,
};
