import React, { FunctionComponent, useEffect } from "react";
import { connect } from "react-redux";
import {
  CombineActions,
  AppDispatch,
  RESULTS_LOAD_BEGIN,
} from "../app/actions";
import State from "../app/state";
import { fetchResult } from "../Navigation/reducer";

interface OwnProps {
  id: string;
  children: React.ReactNode;
}

interface Properties {
  loaded: boolean;
  loading: boolean;
}

interface Actions {
  onLoadStart: (id: string) => void;
}

type AllProperties = OwnProps & Properties & Actions;

const Report: FunctionComponent<AllProperties> = ({
  id,
  loading,
  loaded,
  onLoadStart,
  children,
}) => {
  useEffect(() => {
    if (!loaded && !loading) onLoadStart(id);
  }, [id, loaded, loading, onLoadStart]);

  if (loading) return <div>LOADING</div>;

  return <div className="report-container">{children}</div>;
};

const mapDispatchToProps = (state: State): Properties => {
  return {
    loaded: state.navigation.loaded,
    loading: state.navigation.loading,
  };
};

const mapDispatchToActions = (dispatch: AppDispatch): Actions => {
  return {
    onLoadStart: (id: string) =>
      dispatch(CombineActions({ type: RESULTS_LOAD_BEGIN }, fetchResult(id))),
  };
};

export default connect(mapDispatchToProps, mapDispatchToActions)(Report);
