import { connect } from "react-redux";
import {
  AppDispatch,
  REVIEW_ITEMS_CLOSE,
  REVIEW_ITEM_FIRST,
  REVIEW_ITEM_LAST,
  REVIEW_ITEM_NEXT,
  REVIEW_ITEM_PREVIOUS,
} from "../../app/actions";
import State from "../../app/state";
import {
  Actions,
  Properties,
  ReviewHeader,
} from "../../components/ReviewHeader";

const mapStateToProps = (state: State): Properties => {
  let items = state.itemReview.itemsBeingReviewed;
  let sectionItems = state.itemContent.sectionItems[state.itemReview.sectionId];
  let itemId = items[state.itemReview.itemReviewIndex];
  let itemNumber = sectionItems.indexOf(itemId) + 1;
  let reviewingSubset =
    state.itemReview.itemsBeingReviewed.length !== sectionItems.length;
  return {
    canNext: state.itemReview.itemReviewIndex < items.length - 1,
    canPrevious: state.itemReview.itemReviewIndex > 0,
    title: `${state.itemReview.itemReviewIndex + 1} of ${items.length}${
      reviewingSubset ? ` (Item ${itemNumber})` : ""
    }`,
  };
};

const mapDispatchToActions = (dispatch: AppDispatch): Actions => {
  return {
    close: () => dispatch({ type: REVIEW_ITEMS_CLOSE }),
    goBackward: () => dispatch({ type: REVIEW_ITEM_PREVIOUS }),
    goFirst: () => dispatch({ type: REVIEW_ITEM_FIRST }),
    goForward: () => dispatch({ type: REVIEW_ITEM_NEXT }),
    goLast: () => dispatch({ type: REVIEW_ITEM_LAST }),
  };
};

export default connect(mapStateToProps, mapDispatchToActions)(ReviewHeader);
