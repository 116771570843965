type QueryResult<T> = {
  skip: number;
  take: number;
  totalRecords: number;
  data: Array<T>;
  loading: boolean;
};

export type Product = {
  id: string;
  title: string;
  description: string;
  logo: string;
  price: number;
  purchased: boolean;
};

type State = QueryResult<Product>;

export default State;

export const DefaultState: State = {
  skip: 0,
  take: 12,
  totalRecords: 0,
  data: [],
  loading: false,
};
