import { connect } from "react-redux";
import State from "../../app/state";
import {
  Properties,
  ResultDetailCard,
} from "../../components/ResultDetailCard";
import ScaledScoreHelp from "../ScaledScoreHelp";

interface OwnProps {
  sectionId?: string;
}

const mapStateToProps = (state: State, ownProps: OwnProps): Properties => {
  let section = state.sectionResults[ownProps.sectionId || ""];
  if (!section)
    return {
      description: "Scaled Score",
      value: state.overallResults.scaledScore.toString(),
      help: <ScaledScoreHelp forSection={false} />,
    };

  return {
    description: "Scaled Score",
    value: section.scaledScore.toString(),
    help: <ScaledScoreHelp forSection={true} />,
  };
};

export default connect(mapStateToProps)(ResultDetailCard);
