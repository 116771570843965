interface ResponseOption {
  id: string;
  label: string;
  content: string;
}

type PieceOfContentType = "HtmlContent" | "MultipleChoiceResponseContent";

type PieceOfContent = {
  typeOfContent: PieceOfContentType;
  content: string;
  options: Array<ResponseOption>;
};

type Content = Array<PieceOfContent>;

export const wrapContent = (content: Content): HTMLDivElement => {
  let contentPieces = content.map((c) => {
    if (c.typeOfContent === "HtmlContent")
      return wrapWithContentElement(c.content);
    else {
      let options = wrapOptions(c.options);
      let optionsWrapper = wrapWithContentElement("");
      optionsWrapper.append(document.createElement("br"), options);
      return optionsWrapper;
    }
  });

  let outerWrapper = document.createElement("div");
  outerWrapper.append(...contentPieces);
  return outerWrapper;
};

export const wrapWithShadowRoot = (conent: Array<Node | string>, mode: ShadowRootMode = "open"): HTMLDivElement => {
  let host = document.createElement("div");
  let shadowRoot = host.attachShadow( { mode } );
  shadowRoot.append(...conent);
  return host;
};

const wrapWithContentElement = (content: string) => {
  let wrapper = document.createElement("div");
  wrapper.setAttribute("class", "content-element");
  wrapper.insertAdjacentHTML("beforeend", content);
  return wrapper;
};

const wrapOptions = (options: Array<ResponseOption>) => {
  let outerWrapper = document.createElement("div");
  outerWrapper.setAttribute("class", "content-element");

  let wrapper = document.createElement("div");
  wrapper.setAttribute("class", "multiple-choice-responses");

  options
    .map((option) => {
      let optionLabel = document.createElement("span");
      optionLabel.setAttribute("class", "label");
      optionLabel.append(option.label);

      let optionContent = document.createElement("span");
      optionContent.setAttribute("class", "choice-content");
      optionContent.insertAdjacentHTML("beforeend", option.content);

      let optionWrapper = document.createElement("div");
      optionWrapper.setAttribute("class", "multiple-choice-response");
      optionWrapper.append(optionLabel);
      optionWrapper.append(optionContent);

      return optionWrapper;
    })
    .forEach((el) => wrapper.append(el, document.createElement("br")));

  outerWrapper.append(wrapper);
  return outerWrapper;
};
