import { Action, Reducer } from "redux";
import {
  COUNTER_CLICKED,
  PartialStateAction,
  HIGHLIGHT_CLEAR_DROPDOWN_CLICKED,
  HIGHLIGHT_DROPDOWN_CLICKED,
  HIGHLIGHT_DROPDOWN_ROW_CLICKED,
  SECTION_LOADED,
  TIMER_CLICKED,
  BEGIN_LOAD_EXAM,
} from "../app/actions";
import State, { Default } from "./state";

const onTimeRemainingClicked = (state: State): State => {
  return { ...state, timeCollapsed: !state.timeCollapsed };
};

const onQuestionCounterClicked = (state: State): State => {
  return {
    ...state,
    questionCounterCollapsed: !state.questionCounterCollapsed,
  };
};

const onHighlightDropdownClicked = (state: State): State => {
  return {
    ...state,
    highlightDropdownOpen: !state.highlightDropdownOpen,
  };
};

const onHighlightDropdownRowClicked = (state: State): State => {
  return {
    ...state,
    highlightDropdownOpen: false,
  };
};

const onHighlightDropdownClearClicked = (state: State): State => {
  return {
    ...state,
    highlightDropdownOpen: false,
  };
};

const reducer: Reducer<State, Action> = (state, action) => {
  if (!state) return Default;
  switch (action.type) {
    case BEGIN_LOAD_EXAM:
      return Default;
    case SECTION_LOADED:
      let newState = (action as PartialStateAction).state.header;
      if (newState) return { ...newState };
      return state;
    case TIMER_CLICKED:
      return onTimeRemainingClicked(state);
    case COUNTER_CLICKED:
      return onQuestionCounterClicked(state);
    case HIGHLIGHT_DROPDOWN_CLICKED:
      return onHighlightDropdownClicked(state);
    case HIGHLIGHT_DROPDOWN_ROW_CLICKED:
      return onHighlightDropdownRowClicked(state);
    case HIGHLIGHT_CLEAR_DROPDOWN_CLICKED:
      return onHighlightDropdownClearClicked(state);
    default:
      return state;
  }
};

export default reducer;
