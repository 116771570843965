import React, { FunctionComponent } from "react";

export interface Properties {
  warningMessage: string;
}

export const TimeoutWarning: FunctionComponent<Properties> = ({
  warningMessage,
}) => {
  return <div dangerouslySetInnerHTML={{ __html: warningMessage }}></div>;
};
