type Skill = {
  id: string;
  groupId: string;
  label: string;
  description: string;
  items: number;
  correct: number;
};

type SkillGroup = {
  id: string;
  label: string;
  description: string;
};

type State = {
  groups: Record<string, SkillGroup>;
  skills: Record<string, Record<string, Skill>>;
  sectionGroups: Record<string, Array<string>>;
};

export default State;

export const DefaultState: State = {
  groups: {},
  skills: {},
  sectionGroups: {},
};
