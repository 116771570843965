import { connect } from 'react-redux';

import { getSelectedHtml } from '../../../utils/selection';
import {
  AppDispatch,
  NEXT_CORRECT_FILTER,
  NEXT_FLAGGED_FILTER,
  ReviewItems,
} from '../../app/actions';
import State from '../../app/state';
import {
  Actions,
  ItemData,
  ItemsTable,
  Properties,
} from '../../components/ItemsTable';

interface OwnProps {
  sectionId: string;
}

const mapStateToProps = (state: State, ownProps: OwnProps): Properties => {
  const getPreview = (id: string) =>
    getSelectedHtml(
      state.itemContent.contentLibrary[
        state.itemContent.items[id].primaryContent
      ][0].content.toString(),
      { start: 0, end: 200 },
      true
    );

  let items: Array<ItemData> = state.itemContent.sectionItems[
    ownProps.sectionId
  ]
    .map((i) => ({
      content: state.itemContent.items[i],
      state: state.itemState[i],
    }))
    .filter((i) =>
      state.itemsTable.flaggedFilter === "flagged"
        ? i.state?.flagged
        : state.itemsTable.flaggedFilter === "unflagged"
        ? !i.state?.flagged
        : true
    )
    .filter((i) =>
      state.itemsTable.correctnessFilter === "correct"
        ? i.state?.isCorrect
        : state.itemsTable.correctnessFilter === "incorrect"
        ? !i.state?.isCorrect
        : true
    )
    .map((data, index) => ({
      id: data.content.id,
      isCorrect: data.state?.isCorrect,
      flagged: data.state?.flagged,
      timeInSeconds: data.state?.timeInSeconds,
      number:
        state.itemContent.sectionItems[ownProps.sectionId].indexOf(
          data.content.id
        ) + 1,
      preview: getPreview(data.content.id),
    }));

  return {
    correctnessFilter: state.itemsTable.correctnessFilter,
    flaggedFilter: state.itemsTable.flaggedFilter,
    items,
  };
};

const mapDispatchToProps = (
  dispatch: AppDispatch,
  ownProps: OwnProps
): Actions => {
  return {
    nextCorrectnessFilter: () => dispatch({ type: NEXT_CORRECT_FILTER }),
    nextFlaggedFilter: () => dispatch({ type: NEXT_FLAGGED_FILTER }),
    onReviewItem: (item: string, items: Array<string>) =>
      dispatch(ReviewItems(item, items, ownProps.sectionId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemsTable);
