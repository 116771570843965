import { Action } from "redux";
import {
  NEXT_CORRECT_FILTER,
  NEXT_FLAGGED_FILTER,
  RESULTS_LOAD_BEGIN,
} from "../app/actions";
import State, { DefaultState } from "./state";

const onNextFlaggedFilter = (state: State): State => {
  return {
    ...state,
    flaggedFilter:
      state.flaggedFilter === "flagged"
        ? "unflagged"
        : state.flaggedFilter === "unflagged"
        ? "none"
        : "flagged",
  };
};

const onNextCorrectFilter = (state: State): State => {
  return {
    ...state,
    correctnessFilter:
      state.correctnessFilter === "correct"
        ? "incorrect"
        : state.correctnessFilter === "incorrect"
        ? "none"
        : "correct",
  };
};

const reducer = (state: State, action: Action): State => {
  if (!state) return DefaultState;
  switch (action.type) {
    case RESULTS_LOAD_BEGIN:
      return DefaultState;
    case NEXT_FLAGGED_FILTER:
      return onNextFlaggedFilter(state);
    case NEXT_CORRECT_FILTER:
      return onNextCorrectFilter(state);
    default:
      return state;
  }
};

export default reducer;
