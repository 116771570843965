import { connect } from "react-redux";
import State from "../../app/state";
import {
  OwnProps,
  Properties,
  ScoreDistributionCurve,
} from "../../components/ScoreDistributionCurve";

const mapStateToProps = (state: State, ownProps: OwnProps): Properties => {
  let section = state.sectionResults[ownProps.sectionId || ""];

  if (!section)
    return {
      leftLabel: state.overallResults.minScaledScore.toString(),
      rightLabel: state.overallResults.maxScaledScore.toString(),
      score: state.overallResults.scaledScore.toString(),
      percentage: state.overallResults.percent,
    };

  return {
    leftLabel: section.minScaledScore.toString(),
    rightLabel: section.maxScaledScore.toString(),
    score: section.scaledScore.toString(),
    percentage: section.percentage,
  };
};

export default connect(mapStateToProps)(ScoreDistributionCurve);
