import { TimeMultiplier } from "../Purchases/state";
import { TokenCache } from "../utils/token";
import settings from "./settings";

const purchaseResource = (id?: string) =>
  `${settings.baseAddress}/Purchase/${id || ""}`;

const createRunForPurchaseResource = (id: string) =>
  `${purchaseResource(id)}/run`;

export const getPurchases = (): Promise<Array<any>> => {
  return new Promise((resolve, reject) => {
    fetch(purchaseResource(), {
      headers: {
        Authorization: `${TokenCache.provider} ${TokenCache.token}`,
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error();
        return response;
      })
      .then((response) => response.json())
      .then((data) => resolve(data))
      .catch(reject);
  });
};

export const getPurchase = (id: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    fetch(purchaseResource(id), {
      headers: {
        Authorization: `${TokenCache.provider} ${TokenCache.token}`,
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error();
        return response;
      })
      .then((response) => response.json())
      .then((data) => resolve(data))
      .catch(reject);
  });
};

export type CreateRunForPurchaseDto = {
  timeMultiplier: TimeMultiplier | undefined;
};

export const createRunForPurchase = (id: string, body: CreateRunForPurchaseDto): Promise<any> => {
  return new Promise((resolve, reject) => {
    fetch(createRunForPurchaseResource(id), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${TokenCache.provider} ${TokenCache.token}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (!response.ok) throw new Error();
        return response;
      })
      .then(resolve)
      .catch(reject);
  });
};
