type QueryResult<T> = {
  skip: number;
  take: number;
  totalRecords: number;
  data: Array<T>;
};

type Product = {
  id: string;
  title: string;
  description: string;
  logo: string;
  examDefinitionId: string;
  price: number;
};

type State = {
  products: QueryResult<Product>;
  productBeingUpdated?: Product;
  newProduct?: Product;
};

export default State;

export const DefaultState: State = {
  products: {
    skip: 0,
    take: 10,
    totalRecords: 0,
    data: [],
  },
};

export const DefaultProduct: Product = {
  id: "",
  title: "",
  description: "",
  logo: "",
  examDefinitionId: "",
  price: 0,
};
