import React, {
  FunctionComponent,
  useEffect,
} from 'react';

import { connect } from 'react-redux';

import {
  Box,
  Container,
  Tab,
  Tabs,
} from '@material-ui/core';

import {
  AppDispatch,
  ChangeContentQueryParameters,
  ChangeManageContentTab,
} from '../../../app/actions';
import State from '../../../app/state';
import { Pagination } from '../../../components/Pagination';
import { fetchContentQuery } from '../../../ManageContent/reducer';
import {
  AssetsTab,
  ContentDefinitionsTab,
  ContentDefinitionUploadFormat,
  SkillGroupsTab,
  SkillGroupsUploadFormat,
  SkillsTab,
  SkillsUploadFormat,
} from '../../../ManageContent/state';
import ContentDefinitions from './tabs/ContentDefinitions';
import SkillGroups from './tabs/SkillGroups';
import Skills from './tabs/Skills';
import UploadFile from './UploadFile';

interface Properties {
  tab: string;
  skip: number;
  take: number;
  totalRecords: number;
  loggedIn: boolean;
}

interface Actions {
  changeTab: (tab: string) => void;
  loadData: () => void;
  onUpdateQueryParams: (skip: number, take: number) => void;
}

type AllProperties = Properties & Actions;

const ManageContent: FunctionComponent<AllProperties> = ({
  tab,
  skip,
  take,
  totalRecords,
  loggedIn,
  changeTab,
  loadData,
  onUpdateQueryParams,
}) => {
  useEffect(() => {
    if (loggedIn) loadData();
  }, [loadData, tab, skip, take, loggedIn]);
  return (
    <>
      <Container className="query-tabs">
        <Tabs value={tab} onChange={(evt, val) => changeTab(val)}>
          <Tab label="Content Definitions" value={ContentDefinitionsTab} />
          <Tab label="Skills" value={SkillsTab} />
          <Tab label="Skill Groups" value={SkillGroupsTab} />
          <Tab label="Assets" value={AssetsTab} />
        </Tabs>
        <UploadFile
          contentDefinitionsTab={ContentDefinitionsTab}
          skillsTab={SkillsTab}
          skillGroupsTab={SkillGroupsTab}
          contentDefinitionUploadFormat={ContentDefinitionUploadFormat}
          skillsUploadFormat={SkillsUploadFormat}
          skillGroupsUploadFormat={SkillGroupsUploadFormat}
        />
      </Container>
      <Container className="query-results">
        {tab === ContentDefinitionsTab && <ContentDefinitions />}
        {tab === SkillsTab && <Skills />}
        {tab === SkillGroupsTab && <SkillGroups />}
        {tab === AssetsTab && <div>Assets</div>}
      </Container>
      <Box className="query-pagination">
        <Pagination
          skip={skip}
          take={take}
          totalRecords={totalRecords}
          onUpdateQueryParams={onUpdateQueryParams}
        />
      </Box>
    </>
  );
};

const mapStateToProperties = (state: State): Properties => {
  let query = state.manageContent.queryResults || {
    skip: 0,
    take: 0,
    totalRecords: 0,
  };

  return {
    tab: state.manageContent.tab,
    skip: query.skip,
    take: query.take,
    totalRecords: query.totalRecords,
    loggedIn: state.user.loggedIn,
  };
};

const mapDispatchToActions = (dispatch: AppDispatch): Actions => {
  return {
    changeTab: (tab: string) => dispatch(ChangeManageContentTab(tab)),
    loadData: () => dispatch(fetchContentQuery()),
    onUpdateQueryParams: (skip: number, take: number) =>
      dispatch(ChangeContentQueryParameters(skip, take)),
  };
};

export default connect(
  mapStateToProperties,
  mapDispatchToActions
)(ManageContent);
