import { Action, Reducer } from "redux";
import {
  PartialStateAction,
  ItemAction,
  ITEM_RESPONSE_SELECTED,
  ResponseAction,
  SECTION_LOADED,
  BEGIN_LOAD_EXAM,
} from "../app/actions";
import State, { Default } from "./state";

const onItemResponseSelected = (
  state: State,
  id: string,
  response: string
): State => {
  if (!id) return state;
  let item = state[id];
  let newItem = !item
    ? {
        id,
        response,
      }
    : {
        ...item,
        response: item.response === response ? "" : response,
      };
  return { ...state, [id]: newItem };
};

const reducer: Reducer<State, Action> = (state, action) => {
  if (!state) return Default;
  switch (action.type) {
    case BEGIN_LOAD_EXAM:
      return Default;
    case SECTION_LOADED:
      let newState = (action as PartialStateAction).state.itemState;
      if (newState) return { ...newState };
      return state;
    case ITEM_RESPONSE_SELECTED:
      return onItemResponseSelected(
        state,
        (action as ItemAction).itemId,
        (action as ResponseAction).response
      );
    default:
      return state;
  }
};

export default reducer;
