import React, { FunctionComponent } from 'react';

import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from '@material-ui/core';

export interface Properties {
  message: string;
  details: string;
}

const CentrallyDisplayedCard: FunctionComponent<Properties> = ({
  message,
  details,
}) => {
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ height: "100%" }}
    >
      <Grid item>
        <Card elevation={5} style={{ textAlign: "center" }}>
          <CardHeader title={message}></CardHeader>
          <CardContent>
            <Typography>{details}</Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default CentrallyDisplayedCard;
