import { Action } from 'redux';
import {
  ThunkAction,
  ThunkDispatch,
} from 'redux-thunk';

import {
  ExaminationRoute,
  ManageContentRoute,
  ManageExamsRoute,
  ManageProductsRoute,
  MarketplaceRoute,
  MyPurchasesRoute,
  ScoreReportRoute,
  UploadPackageRoute,
} from '../Navigation/state';
import { Product } from '../Products/state';
import { Purchase } from '../Purchases/state';
import State from './state';

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  State,
  unknown,
  Action<string>
>;

export const PURCHASE_UPDATED = "PURCHASE_UPDATED";
export const MY_PURCHASES_LOADED = "MY_PURCHASES_LOADED";
export const PURCHASE_RUNS_EXPANDED_TOGGLED = "PURCHASE_RUNS_EXPANDED_TOGGLED";

export const PRODUCTS_LOADING = "PRODUCTS_LOADING";
export const PRODUCTS_LOADED = "PRODUCTS_LOADED";
export const PRODUCT_UPDATED = "PRODUCT_UPDATED";
export const CHANGE_MARKETPLACE_QUERY_PARAMETERS =
  "CHANGE_MARKETPLACE_QUERY_PARAMETERS";

export const UPDATE_LOCATION_STATE = "UPDATE_LOCATION_STATE";

export const EXAM_DEFS_LOADED = "EXAM_DEFS_LOADED";
export const EXAM_DEF_EXTRACTED = "EXAM_DEF_EXTRACTED";
export const EXAM_DEF_DRAWER_TOGGLE = "EXAM_DEF_DRAWER_TOGGLE";
export const UPLOAD_EXAM_FILE_SELECTED = "UPLOAD_EXAM_FILE_SELECTED";
export const UPLOAD_EXAM_FILE_STARTED = "UPLOAD_EXAM_FILE_STARTED";
export const UPLOAD_EXAM_FILE_SUCCESS = "UPLOAD_EXAM_FILE_SUCCESS";
export const UPLOAD_EXAM_FILE_FAILED = "UPLOAD_EXAM_FILE_FAILED";
export const CHANGE_MANAGE_EXAMS_QUERY_PARAMETERS =
  "CHANGE_MANAGE_EXAMS_QUERY_PARAMETERS";

export const PRODUCT_DEFINITIONS_LOADED = "PRODUCT_DEFINITIONS_LOADED";
export const PRODUCT_DEFINITION_EDIT_START = "PRODUCT_DEFINITION_EDIT_START";
export const PRODUCT_DEFINITION_EDIT_COMPLETED =
  "PRODUCT_DEFINITION_EDIT_COMPLETED";
export const PRODUCT_DEFINITION_BEING_EDITED_UPDATED =
  "PRODUCT_DEFINITION_BEING_EDITED_UPDATED";
export const PRODUCT_DEFINITION_BEING_EDITED_LOGO_SERIALIZED =
  "PRODUCT_DEFINITION_BEING_EDITED_LOGO_SERIALIZED";
export const CHANGE_MANAGE_PRODUCTS_QUERY_PARAMETERS =
  "CHANGE_MANAGE_PRODUCTS_QUERY_PARAMETERS";

export const PRODUCT_DEFINITION_CREATE_NEW = "PRODUCT_DEFINITION_CREATE_NEW";
export const PRODUCT_DEFINITION_NEW_COMPLETED =
  "PRODUCT_DEFINITION_NEW_COMPLETED";
export const PRODUCT_DEFINITION_NEW_UPDATED = "PRODUCT_DEFINITION_NEW_UPDATED";
export const PRODUCT_DEFINITION_NEW_LOGO_SERIALIZED =
  "PRODUCT_DEFINITION_NEW_LOGO_SERIALIZED";

export const CHANGE_CONTENT_QUERY_PARAMETERS =
  "CHANGE_CONTENT_QUERY_PARAMETERS";
export const CHAGNE_MANAGE_CONTENT_TAB = "CHAGNE_MANAGE_CONTENT_TAB";
export const LOAD_MANAGE_CONTENT_QUERY_RESULT =
  "LOAD_MANAGE_CONTENT_QUERY_RESULT";

export const UPLOAD_CONTENT_FILE_SELECTED = "UPLOAD_CONTENT_FILE_SELECTED";
export const UPLOAD_CONTENT_FILE_STARTED = "UPLOAD_CONTENT_FILE_STARTED";
export const UPLOAD_CONTENT_FILE_SUCCESS = "UPLOAD_CONTENT_FILE_SUCCESS";
export const UPLOAD_CONTENT_FILE_FAILED = "UPLOAD_CONTENT_FILE_FAILED";

export const TOGGLE_SHOW_UPLOAD_CONTENT_FORMAT =
  "TOGGLE_SHOW_UPLOAD_CONTENT_FORMAT";

export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGOUT = "USER_LOGOUT";
export const USER_LOGIN_PROCESSED = "USER_LOGIN_PROCESSED";
export const USER_PROFILE_MENU_TOGGLE = "USER_PROFILE_MENU_TOGGLE";

export const START_EXAM_RUN_RESET = "START_EXAM_RUN_RESET";
export const CANCEL_EXAM_RUN_RESET = "CANCEL_EXAM_RUN_RESET";

export const UPLOAD_PACKAGE_FILE_SELECTED = "UPLOAD_PACKAGE_FILE_SELECTED";
export const UPLOAD_PACKAGE_FILE_FAILED = "UPLOAD_PACKAGE_FILE_FAILED";
export const UPLOAD_PACKAGE_FILE_STARTED = "UPLOAD_PACKAGE_FILE_STARTED";
export const UPLOAD_PACKAGE_FILE_SUCCESS = "UPLOAD_PACKAGE_FILE_SUCCESS";

export interface LocationUpdateAction extends Action {
  to: string;
  id?: string;
}

export interface PartialStateAction extends Action {
  state: Partial<State>;
}

export interface PurchaseUpdatedAction extends Action {
  purchase: Purchase;
}

export interface ProductUpdatedAction extends Action {
  product: Product;
}

export interface PurchaseAction extends Action {
  id: string;
}

export interface ExamDefsLoadedAction extends Action {
  queryResults: any;
}

export interface ExamDefExtractedAction extends Action {
  id: string;
  json: string;
}

export interface SelectUploadFileAction extends Action {
  file: File;
}

export interface ProductDefinitionsLoadedAction extends Action {
  queryResults: any;
}

export interface ProductDefinitionEditAction extends Action {
  id: string;
}

export interface ProductDefinitionEditUpdateAction extends Action {
  product: any;
}

export interface ProductDefinitionLogoSerializedAction extends Action {
  data: string;
}

export interface ContentQueryFetchedAction extends Action {
  queryResult: any;
  tab: string;
}

export interface ManageContentTabChangeAction extends Action {
  tab: string;
}

export interface QueryParametersUpdateAction extends Action {
  skip: number;
  take: number;
}

export interface LoginAction extends Action {
  provider: string;
  email: string;
  profilePic: string;
  idToken: string;
}

export interface ExamRunResetAction extends Action {
  purchaseId: string;
  examId: string;
}

export interface UserLoginProcessedAction extends Action {
  result: any;
}

export type AppDispatch = ThunkDispatch<State, unknown, Action>;

export const CombineActions = (
  ...actions: Array<Action | AppThunk>
): AppThunk => {
  return (dispatch) => {
    actions.forEach(dispatch);
  };
};

export const ChainActions = (
  ...actions: Array<Action | AppThunk>
): AppThunk => {
  return async (dispatch) => {
    for (let index = 0; index < actions.length; index++) {
      let action = actions[index] as any;
      let actionRun = dispatch(action);
      await actionRun;
    }
  };
};

export const UpdateLocationToMatchMyPurchases = (): LocationUpdateAction => ({
  type: UPDATE_LOCATION_STATE,
  to: MyPurchasesRoute,
});

export const UpdateLocationToMatchMarketplace = (): LocationUpdateAction => ({
  type: UPDATE_LOCATION_STATE,
  to: MarketplaceRoute,
});

export const UpdateLocationToMatchExam = (
  id: string
): LocationUpdateAction => ({
  type: UPDATE_LOCATION_STATE,
  to: ExaminationRoute(id),
  id,
});

export const UpdateLocationToMatchReport = (
  id: string
): LocationUpdateAction => ({
  type: UPDATE_LOCATION_STATE,
  to: ScoreReportRoute(id),
  id,
});

export const UpdateLocationToMatchManageExams = () => ({
  type: UPDATE_LOCATION_STATE,
  to: ManageExamsRoute,
});

export const UpdateLocationToMatchManageProducts = () => ({
  type: UPDATE_LOCATION_STATE,
  to: ManageProductsRoute,
});

export const UpdateLocationToMatchManageContent = () => ({
  type: UPDATE_LOCATION_STATE,
  to: ManageContentRoute,
});

export const UpdateLocationToMatchUploadPackage = () => ({
  type: UPDATE_LOCATION_STATE,
  to: UploadPackageRoute,
});

export const PurchasesLoaded = (purchases: Array<any>): PartialStateAction => ({
  type: MY_PURCHASES_LOADED,
  state: { purchases: purchases },
});

export const ProductsLoaded = (queryResults: any): PartialStateAction => ({
  type: PRODUCTS_LOADED,
  state: { products: queryResults },
});

export const PurchaseUpdated = (purchase: any): PurchaseUpdatedAction => ({
  type: PURCHASE_UPDATED,
  purchase,
});

export const ProductUpdated = (product: any): ProductUpdatedAction => ({
  type: PRODUCT_UPDATED,
  product,
});

export const TogglePurchaseRunsExpanded = (id: string): PurchaseAction => ({
  type: PURCHASE_RUNS_EXPANDED_TOGGLED,
  id,
});

export const ExamDefsLoaded = (queryResults: any): ExamDefsLoadedAction => ({
  type: EXAM_DEFS_LOADED,
  queryResults,
});

export const ExamDefExtracted = (
  id: string,
  json: string
): ExamDefExtractedAction => ({
  type: EXAM_DEF_EXTRACTED,
  id,
  json,
});

export const UploadExamFileSelected = (file: File): SelectUploadFileAction => ({
  type: UPLOAD_EXAM_FILE_SELECTED,
  file,
});

export const UploadPackageFileSelected = (
  file: File
): SelectUploadFileAction => ({
  type: UPLOAD_PACKAGE_FILE_SELECTED,
  file,
});

export const UploadContentFileSelected = (
  file: File
): SelectUploadFileAction => ({
  type: UPLOAD_CONTENT_FILE_SELECTED,
  file,
});

export const ProductDefinitionsLoaded = (
  queryResults: any
): ProductDefinitionsLoadedAction => ({
  type: PRODUCT_DEFINITIONS_LOADED,
  queryResults,
});

export const BeginEditOfProductDefinition = (
  id: string
): ProductDefinitionEditAction => ({
  type: PRODUCT_DEFINITION_EDIT_START,
  id,
});

export const CreateNewProductDefinition = (): Action => ({
  type: PRODUCT_DEFINITION_CREATE_NEW,
});

export const UpdateProductBeingEdited = (
  product: any
): ProductDefinitionEditUpdateAction => ({
  type: PRODUCT_DEFINITION_BEING_EDITED_UPDATED,
  product,
});

export const UpdateNewProduct = (
  product: any
): ProductDefinitionEditUpdateAction => ({
  type: PRODUCT_DEFINITION_NEW_UPDATED,
  product,
});

export const ProductDefinitionLogoSerialized = (
  data: string
): ProductDefinitionLogoSerializedAction => ({
  type: PRODUCT_DEFINITION_BEING_EDITED_LOGO_SERIALIZED,
  data,
});

export const NewProductDefinitionLogoSerialized = (
  data: string
): ProductDefinitionLogoSerializedAction => ({
  type: PRODUCT_DEFINITION_NEW_LOGO_SERIALIZED,
  data,
});

export const LoadQueryResult = (
  queryResult: any,
  tab: string
): ContentQueryFetchedAction => ({
  type: LOAD_MANAGE_CONTENT_QUERY_RESULT,
  queryResult,
  tab,
});

export const ChangeManageContentTab = (
  tab: string
): ManageContentTabChangeAction => ({
  type: CHAGNE_MANAGE_CONTENT_TAB,
  tab,
});

export const ChangeContentQueryParameters = (
  skip: number,
  take: number
): QueryParametersUpdateAction => ({
  type: CHANGE_CONTENT_QUERY_PARAMETERS,
  skip,
  take,
});

export const UpdateProductQueryParams = (
  skip: number,
  take: number
): QueryParametersUpdateAction => ({
  type: CHANGE_MARKETPLACE_QUERY_PARAMETERS,
  skip,
  take,
});

export const UpdateManageProductsQueryParams = (
  skip: number,
  take: number
): QueryParametersUpdateAction => ({
  type: CHANGE_MANAGE_PRODUCTS_QUERY_PARAMETERS,
  skip,
  take,
});

export const UpdateManageExamsQueryParams = (
  skip: number,
  take: number
): QueryParametersUpdateAction => ({
  type: CHANGE_MANAGE_EXAMS_QUERY_PARAMETERS,
  skip,
  take,
});

export const UserLogin = (
  provider: string,
  email: string,
  profilePic: string,
  idToken: string
): LoginAction => ({
  type: USER_LOGIN,
  provider,
  email,
  profilePic,
  idToken,
});

export const UserLoginProcessed = (result: any): UserLoginProcessedAction => ({
  type: USER_LOGIN_PROCESSED,
  result,
});

export const StartResetConfirmation = (
  purchaseId: string,
  examId: string
): ExamRunResetAction => ({
  type: START_EXAM_RUN_RESET,
  purchaseId,
  examId,
});

export const CancelResetConfirmation = (
  purchaseId: string,
  examId: string
): ExamRunResetAction => ({
  type: CANCEL_EXAM_RUN_RESET,
  purchaseId,
  examId,
});
