import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, { FunctionComponent } from "react";

export interface ExamDef {
  id: string;
  title: string;
}

export interface Properties {
  defs: Array<ExamDef>;
}

export interface Actions {
  extractDef: (id: string) => void;
}

type AllProperties = Properties & Actions;

export const ManageExamsDisplayTable: FunctionComponent<AllProperties> = ({
  defs,
  extractDef,
}) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>ID</TableCell>
          <TableCell>Title</TableCell>
          <TableCell>Extract</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {defs.map((def) => (
          <TableRow key={def.id}>
            <TableCell>
              <Typography>{def.id}</Typography>
            </TableCell>
            <TableCell>
              <Typography>{def.title}</Typography>
            </TableCell>
            <TableCell>
              <Button onClick={() => extractDef(def.id)}>Extract</Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
