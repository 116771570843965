import React, { Dispatch, FunctionComponent, useEffect } from "react";
import { connect } from "react-redux";
import { Action } from "redux";

export const TICK = "TICK";

const mapStateToProps = (state: any, ownProps: any) => {
  return ownProps;
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
  return {
    onTimerTick: () => dispatch({ type: TICK }),
  };
};

interface Actions {
  onTimerTick: () => void;
}

const Timer: FunctionComponent<Actions> = ({ onTimerTick, children }) => {
  useEffect(() => {
    let ticker = setInterval(onTimerTick, 1000);
    return () => clearInterval(ticker);
  });

  return <>{children}</>;
};

export const AppTimer = connect(mapStateToProps, mapDispatchToProps)(Timer);
