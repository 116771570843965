import { connect } from "react-redux";
import { Action, Dispatch } from "redux";
import { COUNTER_CLICKED, TIMER_CLICKED } from "../app/actions";
import State from "../app/state";
import { Actions, Header, Properties } from "../components/Header";

const mapStateToProps = (state: State): Properties => {
  return {
    title: `${state.exam.title} - ${state.exam.candidateName}`,
    timed: state.timing.timed,
    timeRemaining: state.timing.timeRemaining,
    showCounter:
      !state.navigation.onReviewScreen && state.section.showQuestionCounter,
    timerCollapsed: state.header.timeCollapsed,
    counterCollapsed: state.header.questionCounterCollapsed,
    counterCurrent: state.navigation.currentItemIndex + 1,
    counterTotal: state.navigation.itemIdentifiers.length,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): Actions => {
  return {
    onCounterClicked: () => dispatch({ type: COUNTER_CLICKED }),
    onTimerClicked: () => dispatch({ type: TIMER_CLICKED }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
