import {
  Button,
  CircularProgress,
  Dialog,
  Grid,
  Typography,
} from "@material-ui/core";
import React, { FunctionComponent } from "react";

export interface Properties {
  tab: string;
  uploadFile: File | undefined;
  uploadProcessing: boolean;
  uploadSuccessful: boolean;
  uploadFailed: boolean;
  showFormat: boolean;
  contentDefinitionsTab: string;
  contentDefinitionUploadFormat: any;
  skillGroupsTab: string;
  skillGroupsUploadFormat: any;
  skillsTab: string;
  skillsUploadFormat: any;
}

export interface Actions {
  onFileSelected: (file: File) => void;
  toggleShowFormat: () => void;
}

type AllProperties = Properties & Actions;

export const ManageContentUploadFile: FunctionComponent<AllProperties> = ({
  tab,
  uploadFile,
  uploadProcessing,
  uploadSuccessful,
  uploadFailed,
  showFormat,
  contentDefinitionsTab,
  contentDefinitionUploadFormat,
  skillGroupsTab,
  skillGroupsUploadFormat,
  skillsTab,
  skillsUploadFormat,
  onFileSelected,
  toggleShowFormat,
}) => {
  return (
    <>
      <Grid container>
        <Grid item xs={12} className="upload-content-container">
          <Button
            variant="contained"
            component="label"
            className="select-file-button"
          >
            Upload {tab === contentDefinitionsTab && "Content Definitions"}
            {tab === skillsTab && "Skills"}
            {tab === skillGroupsTab && "Skill Groups"}
            <input
              type="file"
              hidden
              onChange={(event) => {
                event.target?.files &&
                  event.target.files[0] &&
                  onFileSelected(event.target.files[0]);
                event.target.value = "";
              }}
            />
          </Button>
          <Button onClick={toggleShowFormat}>See Format</Button>
          <Typography display="inline" className="upload-status">
            {uploadProcessing && `Uploading ${uploadFile?.name}...`}
            {uploadSuccessful && `Upload Successful`}
            {uploadFailed && `Upload Failed`}
          </Typography>
          {uploadProcessing && <CircularProgress />}
        </Grid>
      </Grid>
      <Dialog
        open={showFormat}
        onClose={toggleShowFormat}
        classes={{ paper: "upload-content-format" }}
      >
        <pre className="format-container">
          {tab === contentDefinitionsTab &&
            JSON.stringify(contentDefinitionUploadFormat, null, 2)}
          {tab === skillsTab && JSON.stringify(skillsUploadFormat, null, 2)}
          {tab === skillGroupsTab &&
            JSON.stringify(skillGroupsUploadFormat, null, 2)}
        </pre>
      </Dialog>
    </>
  );
};
