export class ColorCombo {
  constructor(public main: string, public darker: string) {}
}

const colorCombos = [
  new ColorCombo("#fce8e8", "#e64545"),
  new ColorCombo("#fcf3e8", "#e69745"),
  new ColorCombo("#fcfae8", "#e6e145"),
  new ColorCombo("#e9fce8", "#47e645"),
  new ColorCombo("#e8fcf9", "#45d0e6"),
  new ColorCombo("#efe8fc", "#7b45e6"),
  new ColorCombo("#fce8f5", "#e645b4"),
];

export const GetColor = (id: string) => {
  let code = id
    .split("")
    .map((c) => c.charCodeAt(0))
    .reduce((total, val) => total + val, 0);
  let index = code % colorCombos.length;
  return colorCombos[index];
};
