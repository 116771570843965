import { connect } from 'react-redux';

import {
  AppDispatch,
  END_SECTION,
  OPTIONAL_SECTION_DELIVERY_CONFIRMED,
} from '../app/actions';
import State from '../app/state';
import {
  Actions,
  Properties,
  Section,
} from '../components/Section';
import { fetchSection } from '../Section/reducer';

const mapStateToProps = (state: State): Properties => {
  return {
    id: state.exam.currentSectionId || "",
    loadedId: state.section.id,
    optional: state.section.isOptional || false,
    optionalMessageContent: state.section.optionalMessageContent || "",
  };
};

const mapDispatchToProps = (dispatch: AppDispatch): Actions => {
  return {
    loadSection: (id: string) => dispatch(fetchSection(id)),
    onDeliverOptionalSection: () =>
      dispatch({ type: OPTIONAL_SECTION_DELIVERY_CONFIRMED }),
    onSkipOptionalSection: () => dispatch({ type: END_SECTION }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Section);
