import { connect } from "react-redux";
import { CreateRunForPurchaseDto } from "../../api/purchase";
import {
  AppDispatch,
  CancelResetConfirmation,
  ChainActions,
  StartResetConfirmation,
  TogglePurchaseRunsExpanded,
} from "../../app/actions";
import State from "../../app/state";
import { Actions, Properties, Purchase } from "../../components/Purchase";
import { ExaminationRoute, ScoreReportRoute } from "../../Navigation/state";
import { createRunFor, resetRun } from "../../Purchases/reducer";

interface OwnProps {
  id: string;
  index: number;
  history: any;
}

const mapStateToProps = (state: State, ownProps: OwnProps): Properties => {
  let purchase = state.purchases.find((p) => p.id === ownProps.id);
  return {
    index: ownProps.index,
    name: purchase?.name || "",
    description: purchase?.description || "",
    runs: purchase?.examRuns || [],
    runsExpanded: purchase?.runsExpanded || false,
  };
};

const mapDispatchToProps = (
  dispatch: AppDispatch,
  ownProps: OwnProps
): Actions => {
  return {
    onAddRun: (options: CreateRunForPurchaseDto) => dispatch(createRunFor(ownProps.id, options)),
    onResetExam: (examId: string) =>
      dispatch(
        ChainActions(
          CancelResetConfirmation(ownProps.id, examId),
          resetRun(ownProps.id, examId)
        )
      ),
    onExpandRunsToggled: () =>
      dispatch(TogglePurchaseRunsExpanded(ownProps.id)),
    onLaunchExam: (id: string) => ownProps.history.push(ExaminationRoute(id)),
    onLaunchScoreReport: (id: string) =>
      ownProps.history.push(ScoreReportRoute(id)),
    onStartResetConfirmation: (id: string) =>
      dispatch(StartResetConfirmation(ownProps.id, id)),
    onCancelResetConfirmation: (id: string) =>
      dispatch(CancelResetConfirmation(ownProps.id, id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Purchase);
