import React, { FunctionComponent } from 'react';

import {
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';

import { wrapContent } from '../utils/content';
import { getSelectedHtml } from '../utils/selection';

export interface Option {
  id: string;
  label: string;
  content: string;
}

interface ResponseOption {
  id: string;
  label: string;
  content: string;
}

type PieceOfContentType = "HtmlContent" | "MultipleChoiceResponseContent";

type PieceOfContent = {
  typeOfContent: PieceOfContentType;
  content: string;
  options: Array<ResponseOption>;
};

type Content = Array<PieceOfContent>;

export interface ContentDefinition {
  id: string;
  correctResponse: string;
  skills: Array<string>;
  isContentQuestion: boolean;
  isPassage: boolean;
  content: Content;
}

export interface Properties {
  data: Array<ContentDefinition>;
}

export const ManageContentDisplayContentDefinitions: FunctionComponent<
  Properties
> = ({ data }) => {
  return (
    <Grid container spacing={3} justifyContent="center" className="card-grid">
      {data.map((item) => (
        <Grid item key={item.id} xs={6}>
          <Card elevation={3}>
            <CardContent>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell className="item-card-label-column">
                      <Typography>Id</Typography>
                    </TableCell>
                    <TableCell className="item-card-value-column">
                      <Typography>{item.id}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="item-card-label-column">
                      <Typography>Is Content</Typography>
                    </TableCell>
                    <TableCell className="item-card-value-column">
                      <Typography>
                        {item.isContentQuestion.toString()}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="item-card-label-column">
                      <Typography>Is Passage</Typography>
                    </TableCell>
                    <TableCell className="item-card-value-column">
                      <Typography>{item.isPassage.toString()}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="item-card-label-column">
                      <Typography>Correct Response</Typography>
                    </TableCell>
                    <TableCell className="item-card-value-column">
                      <Typography>
                        {item.correctResponse?.toString() || "N/A"}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="item-card-label-column">
                      <Typography>Preview</Typography>
                    </TableCell>
                    <TableCell className="item-card-value-column">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: getSelectedHtml(wrapContent(item.content).innerHTML, {
                            start: 0,
                            end: 200,
                          }),
                        }}
                      ></div>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="item-card-label-column">
                      <Typography>Skills</Typography>
                    </TableCell>
                    <TableCell className="item-card-value-column">
                      {item.skills.map((skill, index) => (
                        <Typography key={index}>{skill}</Typography>
                      ))}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};
