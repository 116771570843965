import { connect } from "react-redux";
import { AppDispatch, NavigateTo } from "../app/actions";
import State from "../app/state";
import {
  Actions,
  HeaderNavLink,
  OwnProps,
  Properties,
} from "../components/HeaderNavLink";

const mapStateToProps = (state: State, ownProps: OwnProps): Properties => {
  let section = state.sectionResults[ownProps.sectionId];
  return {
    content: section.name,
  };
};

const mapDispatchToProps = (
  dispatch: AppDispatch,
  ownProps: OwnProps
): Actions => {
  return {
    onNavigate: () => dispatch(NavigateTo(ownProps.sectionId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderNavLink);
