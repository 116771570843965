import React, { FunctionComponent } from "react";

interface Item {
  id: string;
  number: number;
  seen: boolean;
  complete: boolean;
  flagged: boolean;
}

export interface Properties {
  itemsPerRow?: number;
  itemsSnapshot: Array<Item>;
  summaryExpanded: boolean;
  itemsExpanded: boolean;
}

export interface Actions {
  onItemFlagClicked: (itemId: string) => void;
  onToggleSummaryExpansion: () => void;
  onToggleItemsExpansion: () => void;
  onItemLinkClicked: (itemId: string) => void;
}

type AllProperties = Properties & Actions;

export const Review: FunctionComponent<AllProperties> = ({
  itemsPerRow = 3,
  itemsSnapshot,
  summaryExpanded,
  itemsExpanded,
  onItemFlagClicked,
  onToggleSummaryExpansion,
  onToggleItemsExpansion,
  onItemLinkClicked,
}) => {
  const unseenOrIncomplete = itemsSnapshot.filter((i) => !i.seen || !i.complete)
    .length;

  const totalRows = Math.ceil(itemsSnapshot.length / itemsPerRow);
  const safeGetItem = (index: number) =>
    itemsSnapshot.length - 1 >= index ? itemsSnapshot[index] : undefined;
  const itemRow = (rowIndex: number) =>
    [
      safeGetItem(rowIndex),
      safeGetItem(rowIndex + totalRows),
      safeGetItem(rowIndex + totalRows * 2),
    ].filter((a) => !!a);

  return (
    <div className="content-container review">
      <div className="review-header">Section Review</div>
      <div
        className={`expandable summary ${
          summaryExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="expandable-header">
          <span className="icon" onClick={onToggleSummaryExpansion}></span>
          <span>Instructions</span>
        </div>
        <div className="expandable-contents scrollable">
          <p>
            The table below is a summary of your answers in this section. You
            can review your answers in four different ways using the features at
            the bottom of the Section Review:
          </p>
          <ol>
            <li>
              Select Review All or the Alt+A keyboard shortcut to review all the
              questions and answers from the beginning, starting with Question
              1.
            </li>
            <li>
              Select Review Incomplete or the Alt+I keyboard shortcut to review
              only the questions that are Incomplete or Unseen.
            </li>
            <li>
              Select Review Flagged or the Alt+R keyboard shortcut to review
              only the questions that are flagged for review.
            </li>
            <li>
              Select an individual question number to review a question of your
              choice. This will take you directly to the question. After you’ve
              reviewed the question, select Review Screen or the Alt+W keyboard
              shortcut at the bottom of the question to return to the Section
              Review.
            </li>
          </ol>
          <p>
            If you do not wish to review any of your answers, or you’ve finished
            reviewing your answers, select End Section or the Alt+E keyboard
            shortcut.
          </p>
        </div>
      </div>
      <div
        className={`expandable items ${
          itemsExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="expandable-header">
          <span className="icon" onClick={onToggleItemsExpansion}></span>
          <span>
            Psychological, Social, and Biological Foundations of Behavior
            Section
          </span>
          <span className="incomplete-unseen">
            ({unseenOrIncomplete} Unseen/Incomplete)
          </span>
        </div>
        <div className="expandable-contents scrollable">
          <table className="review-items">
            <tbody>
              {[...new Array(Math.ceil(itemsSnapshot.length / 3))].map(
                (_, row) => (
                  <tr key={row} className="review-item-row">
                    {itemRow(row).map((item) => (
                      <td key={item!.id} className="review-item-cell">
                        <span
                          className={`review-item-flag ${
                            item?.flagged ? "flagged" : ""
                          }`}
                          onClick={() => onItemFlagClicked(item!.id)}
                        ></span>
                        <span
                          className="review-item-link question-link"
                          onClick={() => onItemLinkClicked(item!.id)}
                        >
                          Question {item!.number}
                        </span>
                        <span className="review-item-status">
                          {(!item!.seen || (item!.seen && !item!.complete)) && (
                            <span className="question-status incomplete">
                              Incomplete
                            </span>
                          )}
                          {item!.seen && item!.complete && (
                            <span className="question-status complete">
                              Complete
                            </span>
                          )}
                        </span>
                      </td>
                    ))}
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
