import {
  applyMiddleware,
  compose,
  createStore,
  StoreEnhancerStoreCreator,
} from "redux";
import thunkMiddleware from "redux-thunk";

import loggerMiddleware from "../utils/middleware/logger";
import monitorReducerEnhancer from "../utils/middleware/monitorReducer";
import reducer from "./reducer";

const middleware = applyMiddleware(loggerMiddleware, thunkMiddleware);
const enhancers = compose<StoreEnhancerStoreCreator>(
  middleware,
  monitorReducerEnhancer
);

const store = createStore(reducer, enhancers);

export default store;
