import React, { FunctionComponent, useEffect, useState } from "react";

export interface OwnProps {
  sectionId?: string;
  height?: number;
  width?: number;
  margin?: { top: number; left: number; bottom: number; right: number };
  textColor?: string;
  textSize?: string;
}

export interface Properties {
  leftLabel?: string;
  rightLabel?: string;
  score?: string;
  percentage: number;
}

type AllProperties = OwnProps & Properties;

export const ScoreDistributionCurve: FunctionComponent<AllProperties> = ({
  height = 250,
  width = 500,
  margin = { top: 10, left: 10, bottom: 20, right: 10 },
  textColor = "black",
  textSize = "large",
  leftLabel = "",
  rightLabel = "",
  score = "",
  percentage,
}) => {
  const contentHeight = height - (margin.top + margin.bottom);
  const contentWidth = width - (margin.left + margin.right);

  const mean = 0;
  const stDev = 1;
  const scaleMin = -2.75;
  const scaleMax = 2.75;

  const f = (x: number) =>
    Math.E ** -((x - mean) ** 2 / (2 * stDev ** 2)) /
    (stDev * Math.sqrt(2 * Math.PI));

  const scale = (i: number) =>
    (i * (scaleMax - scaleMin)) / contentWidth + scaleMin;

  const calcY = (x: number) => contentHeight - f(scale(x)) * contentHeight * 2;

  const points = [...new Array(contentWidth)].map((_, x) => calcY(x));

  const getPercentageX = (p: number) => contentWidth * (p / 100);
  const [renderedPercentage, setRenderedPercentage] = useState(0);

  useEffect(() => {
    let timeout = setTimeout(
      () => setRenderedPercentage(Math.min(renderedPercentage + 1, percentage)),
      percentage - renderedPercentage > 10 ? 10 : 20
    );
    return () => clearTimeout(timeout);
  }, [percentage, renderedPercentage]);

  return (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      style={{ userSelect: "none", overflow: "initial" }}
    >
      <text x={5} y={height - 5} fontSize={textSize} fill={textColor}>
        {leftLabel}
      </text>
      <text
        x={width - 5}
        y={height - 5}
        fontSize={textSize}
        textAnchor="end"
        fill={textColor}
      >
        {rightLabel}
      </text>
      <g transform={`translate(${margin.left} ${margin.top})`}>
        <path
          d={`M 0 0 ${points
            .filter((_, i) => i < getPercentageX(renderedPercentage))
            .map((p, i) => `L ${i} ${p}`)
            .join(" ")} L ${getPercentageX(renderedPercentage)} ${calcY(
            getPercentageX(renderedPercentage)
          )} L ${getPercentageX(
            renderedPercentage
          )} ${contentHeight} L 0 ${contentHeight}`}
          stroke="none"
          fill="#F8B332"
        />
        <path
          d={`M 0 ${points[0]} ${points
            .map((p, i) => `L ${i} ${p}`)
            .join(" ")}`}
          fill="none"
          stroke="black"
          strokeWidth={2}
        />
        <circle
          cx={getPercentageX(renderedPercentage)}
          cy={calcY(getPercentageX(renderedPercentage)) - 5}
          r={5}
          fill="red"
          stroke="none"
        />
        <line
          x1={getPercentageX(renderedPercentage)}
          y1={contentHeight}
          x2={getPercentageX(renderedPercentage)}
          y2={calcY(getPercentageX(renderedPercentage))}
          stroke="red"
          strokeDasharray={2}
        />
        {renderedPercentage >= percentage && (
          <text
            x={getPercentageX(renderedPercentage)}
            y={calcY(getPercentageX(renderedPercentage)) - 15}
            textAnchor="middle"
            fontSize={textSize}
            fill={textColor}
          >
            {score}
          </text>
        )}
      </g>
    </svg>
  );
};
