import { connect } from "react-redux";
import State from "../../../../app/state";
import {
  ManageContentDisplaySkills,
  Properties,
  Skill,
} from "../../../../components/ManageContentDisplaySkills";

const mapStateToProperties = (state: State): Properties => {
  return {
    data: (state.manageContent.queryResults?.data as Array<Skill>) || [],
  };
};

export default connect(mapStateToProperties)(ManageContentDisplaySkills);
