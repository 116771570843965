import {
  Action,
  Reducer,
} from 'redux';

import { getSection } from '../../api/section';
import { UnrecoverableException } from '../../utils/AppError';
import {
  AppThunk,
  BEGIN_LOAD_EXAM,
  END_SECTION,
  OPTIONAL_SECTION_DELIVERY_CONFIRMED,
  PartialStateAction,
  SECTION_LOADED,
  SectionLoaded,
} from '../app/actions';
import State, { Default } from './state';

const onSectionEnd = (state: State): State => {
  return {
    ...state,
    ending: true,
  };
};

const onOptionalSectionDeliveryConfirmed = (state: State): State => {
  return {
    ...state,
    isOptional: false,
  };
};

const reducer: Reducer<State, Action> = (state, action) => {
  if (!state) return Default;

  switch (action.type) {
    case BEGIN_LOAD_EXAM:
      return Default;
    case SECTION_LOADED:
      let newState = (action as PartialStateAction).state.section;
      if (newState) return { ...newState };
      return state;
    case END_SECTION:
      return onSectionEnd(state);
    case OPTIONAL_SECTION_DELIVERY_CONFIRMED:
      return onOptionalSectionDeliveryConfirmed(state);
    default:
      return state;
  }
};

export default reducer;

export const fetchSection =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      const section = await getSection(id);
      if (section) dispatch(SectionLoaded(section));
    } catch (error) {
      dispatch(UnrecoverableException(error as Error));
    }
  };
