import {
  Box,
  Container,
  IconButton,
  Slide,
  Typography,
} from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import React, { FunctionComponent } from "react";

export interface SectionData {
  id: string;
  name: string;
  minScaledScore: number;
  maxScaledScore: number;
  scaledScore: number;
  percentage: number;
}

export interface OwnProps {
  children: React.ReactNode;
  onReturn: () => void;
}

export interface Properties {
  title: string;
}

type AllProperties = OwnProps & Properties;

export const ReportHeader: FunctionComponent<AllProperties> = ({
  children,
  onReturn,
  title,
}) => {
  return (
    <Slide direction="down" in={true} timeout={500}>
      <Box className="report-header">
        <div>
          <IconButton onClick={onReturn} className="return-button">
            <ArrowBack />
          </IconButton>
          <Typography variant="h6" className="return-label">
            Return to portal
          </Typography>
        </div>
        <Container>
          <Box className="content vertical-stack">
            <Typography variant="h4" className="title">
              {title}
            </Typography>
            <Box className="horizontal-stack even">{children}</Box>
          </Box>
        </Container>
      </Box>
    </Slide>
  );
};
