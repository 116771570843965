import { TokenCache } from "../utils/token";
import settings from "./settings";

const resultsResource = (id: string) => `${settings.baseAddress}/Result/${id}`;

export const getResults = (id: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    fetch(resultsResource(id), {
      headers: {
        Authorization: `${TokenCache.provider} ${TokenCache.token}`,
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error();
        return response;
      })
      .then((response) => response.json())
      .then((data) => resolve(data))
      .catch(reject);
  });
};
