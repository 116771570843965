import React, { FunctionComponent, useEffect } from "react";
import { onAltHotkey } from "../../utils/hotkeys";

export interface Properties {
  itemId: string;
  canHighlight: boolean;
  canStrikethrough: boolean;
  highlightDropdownOpen: boolean;
  availableHighlightSelections: Array<string>;
  highlightSelection: string;
  canFlag: boolean;
  isFlagged: boolean;
}

export interface Actions {
  onHighlight: () => void;
  onStrikethrough: () => void;
  onFlagToggle: (id: string) => void;
  onHighlightDropdownClicked: () => void;
  onHighlightDropdownRowClicked: (color: string) => void;
  onHighlightDropdownClearClicked: () => void;
}

type AllProperties = Properties & Actions;

export const Subheader: FunctionComponent<AllProperties> = ({
  itemId,
  canHighlight,
  canStrikethrough,
  highlightDropdownOpen,
  availableHighlightSelections,
  highlightSelection,
  canFlag,
  isFlagged,
  onHighlight,
  onStrikethrough,
  onFlagToggle,
  onHighlightDropdownClicked,
  onHighlightDropdownRowClicked,
  onHighlightDropdownClearClicked,
}) => {
  useEffect(() => {
    let hotkeyListeners = [
      onAltHotkey("h", onHighlight, canHighlight),
      onAltHotkey("s", onStrikethrough, canStrikethrough),
      onAltHotkey("f", () => onFlagToggle(itemId), canFlag),
    ];

    return () => hotkeyListeners.forEach((unsub) => unsub());
  });

  return (
    <>
      <div className="subheader">
        <span className="left">
          {canHighlight && (
            <>
              <span className="highlight link-like">
                <span
                  className="highlight-dropdown-icon-and-arrow"
                  onClick={onHighlightDropdownClicked}
                >
                  <span
                    className={`highlight-icon ${highlightSelection}`}
                  ></span>
                </span>
                <span onClick={onHighlight}>
                  <span className="underline">H</span>ighlight
                </span>
              </span>
              {highlightDropdownOpen && (
                <span className="highlight-dropdown">
                  {availableHighlightSelections.map((highlight, index) => (
                    <span
                      className="highlight-dropdown-row link-like"
                      key={index}
                      onClick={() => onHighlightDropdownRowClicked(highlight)}
                    >
                      <span className={`highlight-icon ${highlight}`}></span>
                    </span>
                  ))}
                  <span
                    className="highlight-dropdown-row link-like final"
                    onClick={onHighlightDropdownClearClicked}
                  >
                    <span className="highlight-icon clear"></span>
                    <span className="label">Remove Highlight</span>
                  </span>
                </span>
              )}
            </>
          )}
          {canStrikethrough && (
            <span className="strikethrough link-like" onClick={onStrikethrough}>
              <span className="underline">S</span>trikethrough
            </span>
          )}
        </span>
        <span className="right">
          {canFlag && (
            <span
              className={`flag ${isFlagged ? "flagged" : ""} link-like`}
              onClick={() => onFlagToggle(itemId)}
            >
              <span className="underline">F</span>lag for Review
            </span>
          )}
        </span>
      </div>
      <hr className="divider" />
    </>
  );
};
