import { connect } from "react-redux";
import { Action, Dispatch } from "redux";
import { DialogClose } from "../app/actions";
import State from "../app/state";
import {
  Actions,
  ItemResponseRequired,
  Properties,
} from "../components/ItemResponseRequired";
import { ItemResponseRequiredDialog } from "../Dialog/state";

const mapStateToProps = (state: State): Properties => {
  return {};
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): Actions => {
  return {
    onOk: () => dispatch(DialogClose(ItemResponseRequiredDialog)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ItemResponseRequired);
