import { connect } from "react-redux";
import {
  AppDispatch,
  CLOSE_HELP,
  OPEN_SCALED_SCORE_HELP,
} from "../app/actions";
import State from "../app/state";
import {
  Actions,
  ScaledScoreHelp,
  Properties,
} from "../components/ScaledScoreHelp";

const mapStateToProps = (state: State): Properties => {
  return {
    open: state.help.scaledScoreHelpOpen,
  };
};

const mapDispatchToActions = (dispatch: AppDispatch): Actions => {
  return {
    onHelpOpen: () => dispatch({ type: OPEN_SCALED_SCORE_HELP }),
    onHelpClose: () => dispatch({ type: CLOSE_HELP }),
  };
};

export default connect(mapStateToProps, mapDispatchToActions)(ScaledScoreHelp);
