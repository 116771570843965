type ResponseOption = {
  id: string;
  label: string;
  content: string;
};

export type MultipleChoiceResponseContent = {
  options: Array<ResponseOption>;
};

export type Content = Array<PieceOfContent>;

export type PieceOfContentType =
  | "HtmlContent"
  | "MultipleChoiceResponseContent";

export type PieceOfContent = {
  typeOfContent: PieceOfContentType;
  content: string;
  options: Array<ResponseOption>;
};

type ItemContentDefinition = {
  id: string;

  split: boolean;
  primaryContent: string;
  secondaryContent?: string;

  completable: boolean;

  canHighlight: boolean;
  canStrikethrough: boolean;
  canFlagForReview: boolean;

  responseIsDecision?: boolean;
};

type State = {
  contentLibrary: Record<string, Content>;
  items: Record<string, ItemContentDefinition>;
  contentCss?: string;
};

export const Default: State = {
  contentLibrary: {},
  items: {},
  contentCss:
    ".content { padding: 40px; font-family: Verdana; font-size: 16px }",
};

export default State;
