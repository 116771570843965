import React, { FunctionComponent } from "react";
import { connect } from "react-redux";
import State from "../app/state";
import Break from "./Break";
import Item from "./Item";
import Review from "./Review";

type Properties = {
  onReviewScreen: boolean;
  onBreak: boolean;
};

const MainDisplay: FunctionComponent<Properties> = ({
  onReviewScreen,
  onBreak,
}) => {
  if (onReviewScreen) return <Review />;
  if (onBreak) return <Break />;
  return <Item />;
};

const mapStateToProps = (state: State): Properties => {
  return {
    onReviewScreen: state.navigation.onReviewScreen,
    onBreak: state.section.isBreak || false,
  };
};

export default connect(mapStateToProps)(MainDisplay);
