import { Action } from "redux";
import {
  CLOSE_HELP,
  OPEN_PERCENTILE_RANK_HELP,
  OPEN_SCALED_SCORE_HELP,
  RESULTS_LOAD_BEGIN,
} from "../app/actions";
import State, { DefaultState } from "./state";

const onCloseHelp = (): State => {
  return {
    percentileRankHelpOpen: false,
    scaledScoreHelpOpen: false,
  };
};

const onOpenScaledScoreHelp = (state: State): State => {
  return {
    ...state,
    scaledScoreHelpOpen: true,
  };
};

const onOpenPercentileRanksHelp = (state: State): State => {
  return {
    ...state,
    percentileRankHelpOpen: true,
  };
};

const reducer = (state: State, action: Action): State => {
  if (!state) return DefaultState;
  switch (action.type) {
    case RESULTS_LOAD_BEGIN:
      return DefaultState;
    case CLOSE_HELP:
      return onCloseHelp();
    case OPEN_PERCENTILE_RANK_HELP:
      return onOpenScaledScoreHelp(state);
    case OPEN_SCALED_SCORE_HELP:
      return onOpenPercentileRanksHelp(state);
    default:
      return state;
  }
};

export default reducer;
