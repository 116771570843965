import { connect } from "react-redux";
import State from "../../app/state";
import { AppDispatch, ReviewItems } from "../../app/actions";
import { Actions, Properties, SkillGroup } from "../../components/SkillGroup";
import React from "react";

interface OwnProps {
  sectionId: string;
  groupId: string;
  children: React.ReactNode;
}

const mapStateToProps = (state: State, ownProps: OwnProps): Properties => {
  let itemIds = state.itemContent.sectionItems[ownProps.sectionId];
  let items = itemIds.map((id) => state.itemContent.items[id]);
  let group = state.skills.groups[ownProps.groupId];
  let sectionSkills = state.skills.skills[ownProps.sectionId];
  let groupSkills = Object.values(sectionSkills)
    .filter((skl) => skl.groupId === ownProps.groupId)
    .map((skl) => skl.id);
  let groupItems = items
    .filter(
      (i) =>
        i.skills.filter((sklId) => groupSkills.indexOf(sklId) > -1).length > 0
    )
    .map((i) => i.id);
  return {
    label: group.label,
    description: group.description,
    items: groupItems,
  };
};

const mapDispatchToProps = (
  dispatch: AppDispatch,
  ownProps: OwnProps
): Actions => {
  return {
    onReviewGroup: (items: Array<string>) =>
      dispatch(ReviewItems(items[0], items, ownProps.sectionId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SkillGroup);
