export const secondsToTimeDisplay = (
  totalSeconds: number,
  trimZeros: boolean = false
) => {
  var h = Math.floor(totalSeconds / 3600);
  var m = Math.floor((totalSeconds % 3600) / 60);
  var s = Math.floor((totalSeconds % 3600) % 60);

  let hours = trimZeros && h === 0 ? "" : !trimZeros && h < 10 ? `0${h}` : h;
  let minutes = trimZeros && m === 0 ? "" : !trimZeros && m < 10 ? `0${m}` : m;
  let seconds = trimZeros && s === 0 ? "" : !trimZeros && s < 10 ? `0${s}` : s;

  return `${hours}${hours ? ":" : ""}${minutes}${minutes ? ":" : ""}${seconds}`;
};
