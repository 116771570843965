export interface AnnotationRange {
  start: number;
  end: number;
}

export type AnnotationDecoration = {
  type: "highlight" | "strikethrough";
  subtype: string;
};

export type Annotation = {
  range: AnnotationRange;
  decorations: Array<AnnotationDecoration>;
};

type State = Record<string, Array<Annotation>>;

export default State;

export const Default: State = {};
