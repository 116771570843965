import { Link, Tooltip, Typography } from "@material-ui/core";
import React from "react";
import HelpIcon from "@material-ui/icons/Help";

export const ContentAndSkillsHelp = () => {
  return (
    <Tooltip
      title={
        <Typography variant="subtitle2">
          "Content and Skills" shows what foundational concepts, content
          category, and skill the question tests, as well as its discipline. The
          score report will include a breakdown of how well you performed in
          each foundational concept, content category, skill and discipline.
          Learn more about the Content and Skills breakdown by clicking this
          icon.
        </Typography>
      }
    >
      <Link
        rel="noopener noreferrer"
        target="_blank"
        href="https://students-residents.aamc.org/applying-medical-school/article/whats-mcat-exam/"
      >
        <HelpIcon
          fontSize="small"
          style={{
            fontSize: "1rem",
            marginLeft: 5,
            cursor: "pointer",
          }}
        />
      </Link>
    </Tooltip>
  );
};
