type State = {
  percentileRankHelpOpen: boolean;
  scaledScoreHelpOpen: boolean;
};

export default State;

export const DefaultState: State = {
  percentileRankHelpOpen: false,
  scaledScoreHelpOpen: false,
};
