import { connect } from "react-redux";
import { annotateInPlace, Annotation } from "../../../utils/annotation";
import { wrapContent, wrapWithShadowRoot } from "../../../utils/content";
import State from "../../app/state";
import {
  ContentContainer,
  Properties,
} from "../../components/ContentContainer";

import { contentStyles as scoreReportAppStyles } from '../../../assets/css/ScoreReportApp';
import { Content } from "../../ItemContent/state";

const mapStateToProps = (state: State): Properties => {
  let itemId = state.itemReview.itemsBeingReviewed[state.itemReview.itemReviewIndex];
  let item = state.itemContent.items[itemId];

  let primary = state.itemContent.contentLibrary[item.primaryContent];
  let secondary = item.secondaryContent && state.itemContent.contentLibrary[item.secondaryContent];

  let contentBlocks: AnnotatatedContent[] = [];
  if (secondary)
    contentBlocks.push({ 
      content: secondary, 
      annotations: item.secondaryContent ? state.itemAnnotation[item.secondaryContent] : []
    });
  contentBlocks.push({ 
    content: primary, 
    annotations: state.itemAnnotation[item.primaryContent] 
  });

  return {
    itemId,
    answerRationales: state.itemRationale[itemId],
    contentElement: buildContentElement(contentBlocks, state.itemContent.contentCss),
    correct: state.itemState[itemId]?.isCorrect,
    correctAnswer: state.itemContent.items[itemId].correctResponse,
    direction: state.itemReview.direction,
  };
};

export default connect(mapStateToProps)(ContentContainer);

type AnnotatatedContent = { 
  content: Content, 
  annotations: Annotation[] 
};

function buildContentElement(contentBlocks: AnnotatatedContent[], contentCss: string | undefined) {
  const styles: HTMLStyleElement[] = [
    createStyleElement(scoreReportAppStyles),
  ];

  if (contentCss)
    styles.push(createStyleElement(contentCss));

  const wrappedElements = contentBlocks.map(({ content, annotations }) => {
    const wrappedElement = wrapContent(content);
    annotateInPlace(wrappedElement, annotations);
    return wrappedElement;
  });

  let contentElements: Node[] = [];
  for (let i = 0; i < wrappedElements.length; i++) {
    if (i > 0)
      contentElements.push(document.createElement("hr"));
    contentElements.push(wrappedElements[i]);
  }

  const root = document.createElement("div");
  root.className = 'content';
  root.append(...contentElements);
  
  return wrapWithShadowRoot([...styles, root]);
}

function createStyleElement(innerHTML: string) {
  const style = document.createElement("style");
  style.innerHTML = innerHTML;
  return style;
}
