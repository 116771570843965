import { Box, Drawer, IconButton, Typography } from "@material-ui/core";
import { ExpandLess, ExpandMore, FileCopy } from "@material-ui/icons";
import React, { FunctionComponent } from "react";
import { selectTextWithinElement } from "../utils/selection";

export interface Properties {
  extractedDef: string;
  drawerOpen: boolean;
}

export interface Actions {
  toggleDrawer: () => void;
}

type AllProperties = Properties & Actions;

export const ManageExamsExtractedDefDrawer: FunctionComponent<AllProperties> = ({
  extractedDef,
  drawerOpen,
  toggleDrawer,
}) => {
  return (
    <>
      {extractedDef && (
        <>
          {!drawerOpen && (
            <IconButton
              onClick={toggleDrawer}
              className="extracted-drawer-handle outer"
            >
              <ExpandLess />
            </IconButton>
          )}
          <Drawer
            anchor="bottom"
            open={drawerOpen}
            onClose={toggleDrawer}
            classes={{ paper: "extracted-drawer" }}
          >
            <Box className="extracted-drawer-header">
              <IconButton
                className="copy-content-button"
                onClick={() => selectTextWithinElement("extracted-content")}
              >
                <FileCopy />
                <Typography>Select All</Typography>
              </IconButton>
              <IconButton
                onClick={toggleDrawer}
                className="extracted-drawer-handle inner"
              >
                <ExpandMore />
              </IconButton>
            </Box>
            <pre id="extracted-content">
              {JSON.stringify(JSON.parse(extractedDef), null, 2)}
            </pre>
          </Drawer>
        </>
      )}
    </>
  );
};
