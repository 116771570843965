import { connect } from "react-redux";
import {
  AppDispatch,
  ChainActions,
  TOGGLE_SHOW_UPLOAD_CONTENT_FORMAT,
  UploadContentFileSelected,
} from "../../../app/actions";
import State from "../../../app/state";
import {
  Actions,
  ManageContentUploadFile,
  Properties,
} from "../../../components/ManageContentUploadFile";
import { beginContentFileUpload } from "../../../ManageContent/reducer";

interface OwnProps {
  contentDefinitionsTab: string;
  contentDefinitionUploadFormat: any;
  skillGroupsTab: string;
  skillGroupsUploadFormat: any;
  skillsTab: string;
  skillsUploadFormat: any;
}

const mapStateToProps = (state: State, ownProps: OwnProps): Properties => {
  return {
    tab: state.manageContent.tab,
    uploadFailed: state.manageContent.uploadFailed || false,
    uploadSuccessful: state.manageContent.uploadSuccessful || false,
    uploadProcessing: state.manageContent.uploadProcessing || false,
    uploadFile: state.manageContent.fileBeingUploaded,
    showFormat: state.manageContent.showFormat,
    ...ownProps,
  };
};

const mapDispatchToActions = (dispatch: AppDispatch): Actions => {
  return {
    onFileSelected: (file: File) =>
      dispatch(
        ChainActions(UploadContentFileSelected(file), beginContentFileUpload())
      ),
    toggleShowFormat: () =>
      dispatch({ type: TOGGLE_SHOW_UPLOAD_CONTENT_FORMAT }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToActions
)(ManageContentUploadFile);
