import { Action } from 'redux';

import { serializeImage } from '../api/imageserialization';
import {
  createProductDefinition,
  getProductDefinitions,
  updateProductDefinition,
} from '../api/productdefinition';
import {
  AppThunk,
  CHANGE_MANAGE_PRODUCTS_QUERY_PARAMETERS,
  NewProductDefinitionLogoSerialized,
  PRODUCT_DEFINITION_BEING_EDITED_LOGO_SERIALIZED,
  PRODUCT_DEFINITION_BEING_EDITED_UPDATED,
  PRODUCT_DEFINITION_CREATE_NEW,
  PRODUCT_DEFINITION_EDIT_COMPLETED,
  PRODUCT_DEFINITION_EDIT_START,
  PRODUCT_DEFINITION_NEW_COMPLETED,
  PRODUCT_DEFINITION_NEW_LOGO_SERIALIZED,
  PRODUCT_DEFINITION_NEW_UPDATED,
  PRODUCT_DEFINITIONS_LOADED,
  ProductDefinitionEditAction,
  ProductDefinitionEditUpdateAction,
  ProductDefinitionLogoSerialized,
  ProductDefinitionLogoSerializedAction,
  ProductDefinitionsLoaded,
  ProductDefinitionsLoadedAction,
  QueryParametersUpdateAction,
} from '../app/actions';
import { UnrecoverableException } from '../utils/AppError';
import State, {
  DefaultProduct,
  DefaultState,
} from './state';

const onEditStart = (state: State, id: string): State => {
  let product = state.products.data.find((p) => p.id === id);
  if (!product) return state;

  return { ...state, productBeingUpdated: Object.assign({}, product) };
};

const reducer = (state: State, action: Action): State => {
  if (!state) return DefaultState;
  switch (action.type) {
    case PRODUCT_DEFINITIONS_LOADED:
      return {
        ...state,
        products: (action as ProductDefinitionsLoadedAction).queryResults,
      };
    case PRODUCT_DEFINITION_EDIT_START:
      return onEditStart(state, (action as ProductDefinitionEditAction).id);
    case PRODUCT_DEFINITION_EDIT_COMPLETED:
      return { ...state, productBeingUpdated: undefined };
    case PRODUCT_DEFINITION_BEING_EDITED_UPDATED:
      return {
        ...state,
        productBeingUpdated: (action as ProductDefinitionEditUpdateAction)
          .product,
      };
    case PRODUCT_DEFINITION_BEING_EDITED_LOGO_SERIALIZED:
      return {
        ...state,
        productBeingUpdated: Object.assign({}, state.productBeingUpdated, {
          logo: (action as ProductDefinitionLogoSerializedAction).data,
        }),
      };
    case PRODUCT_DEFINITION_CREATE_NEW:
      return { ...state, newProduct: DefaultProduct };
    case PRODUCT_DEFINITION_NEW_COMPLETED:
      return { ...state, newProduct: undefined };
    case PRODUCT_DEFINITION_NEW_UPDATED:
      return {
        ...state,
        newProduct: (action as ProductDefinitionEditUpdateAction).product,
      };
    case PRODUCT_DEFINITION_NEW_LOGO_SERIALIZED:
      return {
        ...state,
        newProduct: Object.assign({}, state.newProduct, {
          logo: (action as ProductDefinitionLogoSerializedAction).data,
        }),
      };
    case CHANGE_MANAGE_PRODUCTS_QUERY_PARAMETERS:
      let skip = (action as QueryParametersUpdateAction).skip;
      let take = (action as QueryParametersUpdateAction).take;
      return {
        ...state,
        products: Object.assign({}, state.products, { skip, take }),
      };
    default:
      return state;
  }
};

export default reducer;

export const fetchProducts = (): AppThunk => async (dispatch, getState) => {
  try {
    let state = getState().manageProducts;
    let response = await getProductDefinitions(
      state.products.skip,
      state.products.take
    );
    dispatch(ProductDefinitionsLoaded(response));
  } catch (error) {
    dispatch(UnrecoverableException(error as Error));
  }
};

export const updateProduct = (): AppThunk => async (dispatch, getState) => {
  try {
    let updated = getState().manageProducts.productBeingUpdated;
    if (updated) await updateProductDefinition(updated.id, updated);
    dispatch({ type: PRODUCT_DEFINITION_EDIT_COMPLETED });
  } catch (error) {
    dispatch(UnrecoverableException(error as Error));
  }
};

export const createProduct = (): AppThunk => async (dispatch, getState) => {
  try {
    let newProduct = getState().manageProducts.newProduct;
    if (newProduct) await createProductDefinition(newProduct);
    dispatch({ type: PRODUCT_DEFINITION_NEW_COMPLETED });
  } catch (error) {
    dispatch(UnrecoverableException(error as Error));
  }
};

export const serializeAndUpdateProductDefLogo =
  (format: string, file: File): AppThunk =>
  async (dispatch) => {
    try {
      let serialized = await serializeImage(format, file);
      dispatch(ProductDefinitionLogoSerialized(serialized));
    } catch (error) {
      // ignore
    }
  };

export const serializeAndUpdateNewProductDefLogo =
  (format: string, file: File): AppThunk =>
  async (dispatch) => {
    try {
      let serialized = await serializeImage(format, file);
      dispatch(NewProductDefinitionLogoSerialized(serialized));
    } catch (error) {
      // ignore
    }
  };
