import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import React, { useState, FunctionComponent, ChangeEvent } from "react";
import { CreateRunForPurchaseDto } from "../api/purchase";
import { TimeMultiplier } from "../Purchases/state";

const TIME_MULTIPLIER = { 
  unlimited: 'unlimited', 
  empty: '' 
};

interface ExamRun {
  examId: string;
  createdOn: string;
  isCompleted: boolean;
  timeMultiplier?: TimeMultiplier;
  resetConfirmationInProgress?: boolean;
}

export interface Properties {
  index: number;
  name: string;
  description: string;
  runs: Array<ExamRun>;
  runsExpanded: boolean;
}

export interface Actions {
  onAddRun: (options: CreateRunForPurchaseDto) => void;
  onResetExam: (id: string) => void;
  onExpandRunsToggled: () => void;
  onLaunchExam: (id: string) => void;
  onLaunchScoreReport: (id: string) => void;
  onStartResetConfirmation: (id: string) => void;
  onCancelResetConfirmation: (id: string) => void;
}

type AllProperties = Properties & Actions;

export const Purchase: FunctionComponent<AllProperties> = ({
  index,
  name,
  description,
  runs,
  runsExpanded,
  onAddRun,
  onResetExam,
  onExpandRunsToggled,
  onLaunchExam,
  onLaunchScoreReport,
  onStartResetConfirmation,
  onCancelResetConfirmation,
}) => {
  const numberOfRuns = runs.length;
  const numberOfCompletedRuns = runs.filter((r) => r.isCompleted).length;
  const numberOfUnfinishedRuns = numberOfRuns - numberOfCompletedRuns;

  const [selectedExtendTime, selectExtendTime] = useState('');
  const handleExtendTimeChange = (event: ChangeEvent<{ value: unknown }>) =>
   selectExtendTime(event.target.value as string);
  
  const onAddRunClick = () => {
    if (selectedExtendTime === TIME_MULTIPLIER.empty)
      return onAddRun({ timeMultiplier: undefined });

    const unlimited = selectedExtendTime === TIME_MULTIPLIER.unlimited;
    const timeMultiplier: TimeMultiplier = {
      type: unlimited ? "Unlimited" : "Value",
      value: unlimited ? 0 : Number.parseFloat(selectedExtendTime),
    };
    onAddRun({ timeMultiplier });
  };

  const extendTimeSelectLabelId = "extend-time-select-label-" + index;

  return (
    <Box
      className="purchase-container"
      style={{ animationDuration: `${0.5 + 0.1 * index}s` }}
    >
      <Card className="purchase" elevation={3}>
        <CardHeader title={<Typography>{name}</Typography>}></CardHeader>
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            {description}
          </Typography>
        </CardContent>
        <CardActions>
          <Button onClick={onAddRunClick} className="create-run-button">
            Create New Exam Run
          </Button>
          <FormControl className="extend-time-select-control">
            <InputLabel id={extendTimeSelectLabelId}>Extend time</InputLabel>
            <Select
              labelId={extendTimeSelectLabelId}
              value={selectedExtendTime}
              onChange={handleExtendTimeChange}
            >
              <MenuItem value={TIME_MULTIPLIER.empty}><em>None</em></MenuItem>
              <MenuItem value={'1.25'}>x1.25 time</MenuItem>
              <MenuItem value={'1.5'}>x1.5 time</MenuItem>
              <MenuItem value={'2'}>x2 time</MenuItem>
              <MenuItem value={TIME_MULTIPLIER.unlimited}>Unlimited</MenuItem>
            </Select>
          </FormControl>
        </CardActions>
      </Card>
      {runs.length > 0 && (
        <Accordion
          className="runs-expander"
          expanded={runsExpanded}
          onChange={onExpandRunsToggled}
          elevation={3}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            className="exam-runs-header"
          >
            <Typography className="one-third primary">Exam Runs</Typography>
            <Typography className="one-third secondary">
              {numberOfRuns} Total
            </Typography>
            {numberOfUnfinishedRuns > 0 && (
              <Typography className="one-third secondary">
                ({numberOfUnfinishedRuns} Unfinished)
              </Typography>
            )}
          </AccordionSummary>
          <AccordionDetails className="runs-expander-details">
            <Table className="runs-table">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {runs.map((run) => (
                  <TableRow key={run.examId}>
                    <TableCell>
                      {new Date(Date.parse(run.createdOn)).toLocaleString()}
                    </TableCell>
                    <TableCell>
                      {run.isCompleted && <Typography>Complete</Typography>}
                    </TableCell>
                    <TableCell>
                      {run.timeMultiplier?.type === 'Value' && <Typography>{`x${run.timeMultiplier.value} timed`}</Typography>}
                      {run.timeMultiplier?.type === 'Unlimited' && <Typography>Unlimited</Typography>}
                    </TableCell>
                    <TableCell>
                      {!run.resetConfirmationInProgress && (
                        <Button
                          className="reset-start-button"
                          onClick={() => onStartResetConfirmation(run.examId)}
                        >
                          Reset
                        </Button>
                      )}
                      {run.resetConfirmationInProgress && (
                        <>
                          <Typography display="inline">
                            Are you sure?
                          </Typography>
                          <Button
                            className="reset-cancel-button"
                            onClick={() =>
                              onCancelResetConfirmation(run.examId)
                            }
                          >
                            Cancel
                          </Button>
                          <Button
                            className="reset-button"
                            onClick={() => onResetExam(run.examId)}
                          >
                            Reset
                          </Button>
                        </>
                      )}
                    </TableCell>
                    <TableCell>
                      {!run.isCompleted && (
                        <Button
                          className="run-launch"
                          onClick={() => onLaunchExam(run.examId)}
                        >
                          Launch
                        </Button>
                      )}
                    </TableCell>
                    <TableCell>
                      <Button
                        className="run-results"
                        onClick={() => onLaunchScoreReport(run.examId)}
                      >
                        Results
                      </Button>
                      {!run.isCompleted && (
                        <Typography display="inline">(partial)</Typography>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </AccordionDetails>
        </Accordion>
      )}
    </Box>
  );
};
