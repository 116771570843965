import { Box, Container } from "@material-ui/core";
import React, { FunctionComponent, useEffect } from "react";
import { connect } from "react-redux";
import { UpdateManageExamsQueryParams } from "../../../app/actions";
import State from "../../../app/state";
import { AppDispatch } from "../../../app/actions";
import { LoadExamDefinitions } from "../../../ManageExams/reducer";
import { Pagination } from "../../../components/Pagination";
import DisplayTable from "./DisplayTable";
import UploadNew from "./UploadNew";
import ExtractedDefDrawer from "./ExtractedDefDrawer";

interface Properties {
  skip: number;
  take: number;
  totalRecords: number;
  loggedIn: boolean;
}

interface Actions {
  loadExamDefs: () => void;
  onUpdateQueryParams: (skip: number, take: number) => void;
}

type AllProperties = Properties & Actions;

const ManageExams: FunctionComponent<AllProperties> = ({
  skip,
  take,
  totalRecords,
  loggedIn,
  loadExamDefs,
  onUpdateQueryParams,
}) => {
  useEffect(() => {
    if (loggedIn) loadExamDefs();
  }, [loadExamDefs, skip, take, loggedIn]);
  return (
    <>
      <Container className="query-results admin">
        <UploadNew />
        <DisplayTable />
      </Container>
      <Box className="query-pagination">
        <Pagination
          skip={skip}
          take={take}
          totalRecords={totalRecords}
          onUpdateQueryParams={onUpdateQueryParams}
        />
      </Box>
      <ExtractedDefDrawer />
    </>
  );
};

const mapStateToProperties = (state: State): Properties => {
  return {
    skip: state.manageExams.defs.skip,
    take: state.manageExams.defs.take,
    totalRecords: state.manageExams.defs.totalRecords,
    loggedIn: state.user.loggedIn,
  };
};

const mapDispatchToActions = (dispatch: AppDispatch): Actions => {
  return {
    loadExamDefs: () => dispatch(LoadExamDefinitions()),
    onUpdateQueryParams: (skip: number, take: number) =>
      dispatch(UpdateManageExamsQueryParams(skip, take)),
  };
};

export default connect(mapStateToProperties, mapDispatchToActions)(ManageExams);
