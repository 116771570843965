import { connect } from "react-redux";
import {
  AppDispatch,
  BeginEditOfProductDefinition,
  ChainActions,
  PRODUCT_DEFINITION_EDIT_COMPLETED,
  UpdateProductBeingEdited,
} from "../../../app/actions";
import State from "../../../app/state";
import {
  Actions,
  ManageProductsDisplayTable,
  Product,
  Properties,
} from "../../../components/ManageProductsDisplayTable";
import {
  fetchProducts,
  serializeAndUpdateProductDefLogo,
  updateProduct,
} from "../../../ManageProducts/reducer";

const mapStateToProps = (state: State): Properties => {
  return {
    products: state.manageProducts.products.data,
    productBeingEdited: state.manageProducts.productBeingUpdated,
  };
};

const mapDispatchToActions = (dispatch: AppDispatch): Actions => {
  return {
    startEditOf: (id: string) => dispatch(BeginEditOfProductDefinition(id)),
    completeEdit: () =>
      dispatch(ChainActions(updateProduct(), fetchProducts())),
    cancelEdit: () => dispatch({ type: PRODUCT_DEFINITION_EDIT_COMPLETED }),
    updateProductBeingEdited: (product: Product) =>
      dispatch(UpdateProductBeingEdited(product)),
    onImageSelected: (format: string, file: File) =>
      dispatch(serializeAndUpdateProductDefLogo(format, file)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToActions
)(ManageProductsDisplayTable);
