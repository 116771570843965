import { connect } from "react-redux";
import { AppDispatch } from "../../../app/actions";
import State from "../../../app/state";
import {
  Actions,
  ManageExamsDisplayTable,
  Properties,
} from "../../../components/ManageExamsDisplayTable";
import { ExtractExamDefinition } from "../../../ManageExams/reducer";

const mapStateToProps = (state: State): Properties => {
  return {
    defs: state.manageExams.defs.data,
  };
};

const mapDispatchToActions = (dispatch: AppDispatch): Actions => {
  return {
    extractDef: (id: string) => dispatch(ExtractExamDefinition(id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToActions
)(ManageExamsDisplayTable);
