import { combineReducers } from 'redux';

import manageContentReducer from '../ManageContent/reducer';
import manageExamsReducer from '../ManageExams/reducer';
import manageProductsReducer from '../ManageProducts/reducer';
import navigationReducer from '../Navigation/reducer';
import productsReducer from '../Products/reducer';
import purchasesReducer from '../Purchases/reducer';
import uploadPackageReducer from '../UploadPackage/reducer';
import userReducer from '../User/reducer';
import { errorCatchingReducer } from '../utils/AppError';

const reducer = combineReducers({
  navigation: navigationReducer,
  purchases: purchasesReducer,
  products: productsReducer,
  manageExams: manageExamsReducer,
  manageProducts: manageProductsReducer,
  manageContent: manageContentReducer,
  uploadPackage: uploadPackageReducer,
  user: userReducer,
  errorState: errorCatchingReducer,
});

export default reducer;
