import { useCallback, useEffect, useState } from "react";

export function useDocumentEventHandler<T extends keyof DocumentEventMap>(
  eventType: T,
  options?: boolean | AddEventListenerOptions
): [(fn: Function | undefined) => void, Function | undefined] {
  const [eventHandler, setEventHandler] = useState<Function>();
  
  const setEventHandlerRef = useCallback((fn: Function | undefined) => {
    setEventHandler((prev) => fn);
  }, [setEventHandler]);

  useEffect(() => {
    if (!eventHandler) return;

    function handleEvent() {
      eventHandler && eventHandler();
    }

    document.addEventListener(eventType, handleEvent, options);
    return () => {
      document.removeEventListener(eventType, handleEvent, options);
    };
  }, [eventHandler, eventType, options]);

  return [setEventHandlerRef, eventHandler];
}
