import { loadStripe, Stripe } from "@stripe/stripe-js";

let stripePromise: Promise<Stripe | null>;

export const initializeStripe = (publishableKey: string) => {
  stripePromise = loadStripe(publishableKey);
};

export const redirectToCheckoutSession = async (sessionId: string) => {
  const stripe = await stripePromise;
  await stripe?.redirectToCheckout({ sessionId });
};
