import { Action } from 'redux';

import { resetExam } from '../api/exam';
import {
  createRunForPurchase,
  CreateRunForPurchaseDto,
  getPurchase,
  getPurchases,
} from '../api/purchase';
import {
  AppThunk,
  CANCEL_EXAM_RUN_RESET,
  ExamRunResetAction,
  MY_PURCHASES_LOADED,
  PartialStateAction,
  PURCHASE_RUNS_EXPANDED_TOGGLED,
  PURCHASE_UPDATED,
  PurchaseAction,
  PurchasesLoaded,
  PurchaseUpdated,
  PurchaseUpdatedAction,
  START_EXAM_RUN_RESET,
} from '../app/actions';
import { UnrecoverableException } from '../utils/AppError';
import State, {
  DefaultState,
  Purchase,
} from './state';

const onPurchaseUpdated = (state: State, update: Purchase): State => {
  let updatedElement = state.find((p) => p.id === update.id);
  if (!updatedElement) return state;

  let updateIndex = state.indexOf(updatedElement);
  if (updateIndex === -1) return state;

  return [
    ...state.slice(0, updateIndex),
    Object.assign({}, update, {
      runsExpanded:
        updatedElement.runsExpanded ||
        updatedElement.examRuns.length !== update.examRuns.length,
    }),
    ...state.slice(updateIndex + 1),
  ];
};

const onPurchaseRunsExpandToggled = (state: State, id: string): State => {
  let updatedElement = state.find((p) => p.id === id);
  if (!updatedElement) return state;

  let updateIndex = state.indexOf(updatedElement);
  if (updateIndex === -1) return state;

  return [
    ...state.slice(0, updateIndex),
    Object.assign({}, updatedElement, {
      runsExpanded: !updatedElement.runsExpanded,
    }),
    ...state.slice(updateIndex + 1),
  ];
};

const onStartReset = (
  state: State,
  purchaseId: string,
  examId: string
): State => {
  let updatedElement = state.find((p) => p.id === purchaseId);
  if (!updatedElement) return state;

  let updateIndex = state.indexOf(updatedElement);
  if (updateIndex === -1) return state;

  let updatedRun = updatedElement.examRuns.find((r) => r.examId === examId);
  if (!updatedRun) return state;

  let updatedRunIndex = updatedElement.examRuns.indexOf(updatedRun);

  let newRuns = [
    ...updatedElement.examRuns.slice(0, updatedRunIndex),
    Object.assign({}, updatedRun, {
      resetConfirmationInProgress: true,
    }),
    ...updatedElement.examRuns.slice(updatedRunIndex + 1),
  ];

  return [
    ...state.slice(0, updateIndex),
    Object.assign({}, updatedElement, {
      examRuns: newRuns,
    }),
    ...state.slice(updateIndex + 1),
  ];
};

const onCancelReset = (
  state: State,
  purchaseId: string,
  examId: string
): State => {
  let updatedElement = state.find((p) => p.id === purchaseId);
  if (!updatedElement) return state;

  let updateIndex = state.indexOf(updatedElement);
  if (updateIndex === -1) return state;

  let updatedRun = updatedElement.examRuns.find((r) => r.examId === examId);
  if (!updatedRun) return state;

  let updatedRunIndex = updatedElement.examRuns.indexOf(updatedRun);

  let newRuns = [
    ...updatedElement.examRuns.slice(0, updatedRunIndex),
    Object.assign({}, updatedRun, {
      resetConfirmationInProgress: false,
    }),
    ...updatedElement.examRuns.slice(updatedRunIndex + 1),
  ];

  return [
    ...state.slice(0, updateIndex),
    Object.assign({}, updatedElement, {
      examRuns: newRuns,
    }),
    ...state.slice(updateIndex + 1),
  ];
};

const reducer = (state: State, action: Action): State => {
  if (!state) return DefaultState;
  switch (action.type) {
    case MY_PURCHASES_LOADED:
      return (action as PartialStateAction).state.purchases || state;
    case PURCHASE_UPDATED:
      return onPurchaseUpdated(
        state,
        (action as PurchaseUpdatedAction).purchase
      );
    case PURCHASE_RUNS_EXPANDED_TOGGLED:
      return onPurchaseRunsExpandToggled(state, (action as PurchaseAction).id);
    case START_EXAM_RUN_RESET:
      return onStartReset(
        state,
        (action as ExamRunResetAction).purchaseId,
        (action as ExamRunResetAction).examId
      );
    case CANCEL_EXAM_RUN_RESET:
      return onCancelReset(
        state,
        (action as ExamRunResetAction).purchaseId,
        (action as ExamRunResetAction).examId
      );
    default:
      return state;
  }
};

export default reducer;

export const fetchPurchases = (): AppThunk => async (dispatch) => {
  try {
    let response = await getPurchases();
    dispatch(PurchasesLoaded(response));
  } catch (error) {
    dispatch(UnrecoverableException(error as Error));
  }
};

export const createRunFor =
  (id: string, options: CreateRunForPurchaseDto): AppThunk =>
  async (dispatch) => {
    try {
      await createRunForPurchase(id, options);
      dispatch(refreshPurchase(id));
    } catch (error) {
      dispatch(UnrecoverableException(error as Error));
    }
  };

export const resetRun =
  (id: string, examId: string): AppThunk =>
  async (dispatch) => {
    try {
      await resetExam(examId);
      dispatch(refreshPurchase(id));
    } catch (error) {
      dispatch(UnrecoverableException(error as Error));
    }
  };

export const refreshPurchase =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      let purchase = await getPurchase(id);
      dispatch(PurchaseUpdated(purchase));
    } catch (error) {
      dispatch(UnrecoverableException(error as Error));
    }
  };
