import React, { FunctionComponent } from 'react';

import { connect } from 'react-redux';

import CentrallyDisplayedCard from '../../utils/CentrallyDisplayedCard';
import State from '../app/state';

interface Properties {
  loading: boolean;
}

const LoadingSplash: FunctionComponent<Properties> = ({
  loading,
  children,
}) => {
  if (loading)
    return (
      <CentrallyDisplayedCard
        message="Loading..."
        details="One moment please"
      />
    );
  return <>{children}</>;
};

const mapStateToProps = (state: State): Properties => {
  return {
    loading:
      state.exam.loading || state.exam.sectionNavigationInProgress || false,
  };
};

export default connect(mapStateToProps)(LoadingSplash);
