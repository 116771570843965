type State = {
  sortOn: string;
  sortDir: string;
};

export const Default: State = {
  sortOn: "question",
  sortDir: "asc",
};

export default State;
