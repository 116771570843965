export const contentStyles = `
  .content {
    padding: unset !important;
  }

  .multiple-choice-responses {
    padding-left: 16px;
  }

  .multiple-choice-response {
    display: flex;
  }

  .multiple-choice-response .label {
    font-weight: bold;
    width: 23px;
  }

  .multiple-choice-response .label::after {
    content: ".";
    margin-right: 5px;
  }

  .user-annotation.highlight-yellow {
    background-color: #ffff00;
  }

  .user-annotation.highlight-yellow.img {
    background-color: unset;
  }

  .user-annotation.highlight-yellow.img img {
    border: 5px solid #ffff00;
    margin: -5px;
  }

  .user-annotation.strikethrough {
    text-decoration: line-through;
  }

  .user-annotation.strikethrough.img {
    display: inline-block;
    position: relative;
  }

  .user-annotation.strikethrough.img::after {
    position: absolute;
    content: "";
    left: -5%;
    top: 50%;
    right: 5%;
    width: 110%;
    border-top: 1px solid;
    border-color: inherit;
    transform: rotate(-10deg);
  }
`;