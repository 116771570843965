import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { FunctionComponent } from "react";
import { EditProductDefinition } from "./EditProductDefinition";

export interface Product {
  id: string;
  title: string;
  description: string;
  logo: string;
  examDefinitionId: string;
  price: number;
}

export interface Properties {
  products: Array<Product>;
  productBeingEdited: Product | undefined;
}

export interface Actions {
  startEditOf: (id: string) => void;
  completeEdit: () => void;
  cancelEdit: () => void;
  updateProductBeingEdited: (product: Product) => void;
  onImageSelected: (format: string, file: File) => void;
}

type AllProperties = Properties & Actions;

export const ManageProductsDisplayTable: FunctionComponent<AllProperties> = ({
  products,
  productBeingEdited,
  startEditOf,
  completeEdit,
  cancelEdit,
  updateProductBeingEdited,
  onImageSelected,
}) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>ID</TableCell>
          <TableCell>Title</TableCell>
          <TableCell>Description</TableCell>
          <TableCell>Exam Id</TableCell>
          <TableCell>Logo</TableCell>
          <TableCell>Price</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {products.map((product) => (
          <TableRow key={product.id}>
            {productBeingEdited?.id === product.id && (
              <TableCell colSpan={7}>
                <EditProductDefinition
                  isNew={false}
                  product={productBeingEdited}
                  onUpdate={updateProductBeingEdited}
                  onLogoSelect={onImageSelected}
                  onAccept={completeEdit}
                  onCancel={cancelEdit}
                />
              </TableCell>
            )}
            {productBeingEdited?.id !== product.id && (
              <>
                <TableCell>
                  <Typography>{product.id}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{product.title}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{product.description}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{product.examDefinitionId}</Typography>
                </TableCell>
                <TableCell>
                  <Tooltip title={<img src={product.logo} alt="logo-full" />}>
                    <img
                      className="product-def-logo"
                      src={product.logo}
                      alt="logo"
                    />
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Typography>{product.price}</Typography>
                </TableCell>
                <TableCell>
                  <Button
                    onClick={() => startEditOf(product.id)}
                    disabled={!!productBeingEdited}
                  >
                    Edit
                  </Button>
                </TableCell>
              </>
            )}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
