import { connect } from "react-redux";
import State from "../../app/state";
import {
  Properties,
  ResultDetailCard,
} from "../../components/ResultDetailCard";

interface OwnProps {
  sectionId: string;
}

const mapStateToProps = (state: State, ownProps: OwnProps): Properties => {
  let section = state.sectionResults[ownProps.sectionId];
  return {
    description: "Questions Incomplete",
    value: section.incomplete.toString(),
  };
};

export default connect(mapStateToProps)(ResultDetailCard);
