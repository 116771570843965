type State = {
  correctnessFilter: "none" | "correct" | "incorrect";
  flaggedFilter: "none" | "flagged" | "unflagged";
};

export default State;

export const DefaultState: State = {
  correctnessFilter: "none",
  flaggedFilter: "none",
};
