import { Action, Middleware } from "redux";
import shouldFilter from "./actionFilter";

const logger: Middleware = (store: any) => (next: any) => (action: Action) => {
  if (shouldFilter(action)) return next(action);

  console.group(action.type);
  console.info("dispatching", action);
  let result = next(action);
  console.log("next state", store.getState());
  console.groupEnd();
  return result;
};

export default logger;
