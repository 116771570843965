import React, { FunctionComponent, useEffect } from "react";

export type OwnProps = {
  id: string;
  requestedAt: number;
  children: React.ReactNode;
};

export type Properties = {
  lastLoaded: number;
  loading: boolean;
  currentSection: string | null;
};

export type Actions = {
  fetchExam: (id: string) => void;
  onCompletionOrSuspension: () => void;
};

type AllProperties = Properties & Actions & OwnProps;

export const Exam: FunctionComponent<AllProperties> = ({
  id,
  requestedAt,
  lastLoaded,
  loading,
  currentSection,
  fetchExam,
  onCompletionOrSuspension,
  children,
}) => {
  useEffect(() => {
    if (lastLoaded < requestedAt) fetchExam(id);
    if (lastLoaded >= requestedAt && !loading && !currentSection)
      onCompletionOrSuspension();
  }, [
    id,
    fetchExam,
    onCompletionOrSuspension,
    currentSection,
    loading,
    lastLoaded,
    requestedAt,
  ]);

  return <>{children}</>;
};
