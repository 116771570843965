import { TokenCache } from "../utils/token";
import settings from "./settings";

const sectionResource = (id: string) => `${settings.baseAddress}/Section/${id}`;

export const getSection = (id: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    fetch(sectionResource(id), {
      headers: {
        Authorization: `${TokenCache.provider} ${TokenCache.token}`,
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error();
        return response;
      })
      .then((response) => response.json())
      .then((data) => resolve(data))
      .catch(reject);
  });
};

export const saveSection = (id: string, data: any) => {
  return new Promise((resolve, reject) => {
    fetch(sectionResource(id), {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: `${TokenCache.provider} ${TokenCache.token}`,
      },
    })
      .then(async (response) => {
        if (!response.ok) throw await response.text();
        return response;
      })
      .then(resolve)
      .catch((response) =>
        reject(new Error(`Unable to save section data\n\n${response}`))
      );
  });
};
