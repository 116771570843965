import React, { FunctionComponent, useEffect } from "react";
import { connect } from "react-redux";
import { Action, Dispatch } from "redux";
import { DialogOpen, END_SECTION } from "../app/actions";
import State from "../app/state";
import { TimeoutWarningDialog } from "../Dialog/state";

interface Properties {
  timed: boolean;
  timeRemaining: number;
  warningDisplayed: boolean;
}

interface Actions {
  onTimeout: () => void;
  onDisplayWarning: () => void;
}

type AllProperties = Properties & Actions;

const Timeout: FunctionComponent<AllProperties> = ({
  timed,
  timeRemaining,
  warningDisplayed,
  onTimeout,
  onDisplayWarning,
}) => {
  useEffect(() => {
    if (timed && timeRemaining <= 0) onTimeout();
    if (warningDisplayed) onDisplayWarning();
  }, [onDisplayWarning, onTimeout, timeRemaining, timed, warningDisplayed]);
  return <></>;
};

const mapStateToProps = (state: State): Properties => {
  return {
    timed: state.timing.timed,
    timeRemaining: state.timing.timeRemaining,
    warningDisplayed: state.timing.warningDisplayed,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): Actions => {
  return {
    onTimeout: () => dispatch({ type: END_SECTION }),
    onDisplayWarning: () => dispatch(DialogOpen(TimeoutWarningDialog)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Timeout);
