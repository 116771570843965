type ItemState = {
  id: string;
  response: string;
  isCorrect: boolean;
  timeInSeconds: number;
  flagged: boolean;
};

type State = Record<string, ItemState>;

export const Default: State = {};

export default State;
