import { Dispatch } from "react";
import { connect } from "react-redux";
import { Action } from "redux";
import {
  HighlightDropdownRowClicked,
  HIGHLIGHT_CLEAR_DROPDOWN_CLICKED,
  HIGHLIGHT_CLICKED,
  HIGHLIGHT_DROPDOWN_CLICKED,
  STRIKETHROUGH_CLICKED,
  ToggleReviewFlag,
} from "../app/actions";
import State from "../app/state";
import { Properties, Actions, Subheader } from "../components/Subheader";

const mapStateToProps = (state: State): Properties => {
  let id = state.navigation.itemIdentifiers[state.navigation.currentItemIndex];
  let itemDef = state.itemContent.items[id];

  return {
    itemId: id,
    canHighlight: !state.navigation.onReviewScreen && itemDef?.canHighlight,
    canStrikethrough:
      !state.navigation.onReviewScreen && itemDef?.canStrikethrough,
    canFlag: !state.navigation.onReviewScreen && itemDef?.canFlagForReview,
    isFlagged: state.navigation.itemsFlaggedForReview.indexOf(id) >= 0,
    highlightDropdownOpen: state.header.highlightDropdownOpen,
    availableHighlightSelections: state.itemAnnotation.availableHighlightColors,
    highlightSelection: state.itemAnnotation.currentHighlightSelection,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): Actions => {
  return {
    onHighlight: () => dispatch({ type: HIGHLIGHT_CLICKED }),
    onStrikethrough: () => dispatch({ type: STRIKETHROUGH_CLICKED }),
    onFlagToggle: (id: string) => dispatch(ToggleReviewFlag(id)),
    onHighlightDropdownClicked: () =>
      dispatch({ type: HIGHLIGHT_DROPDOWN_CLICKED }),
    onHighlightDropdownRowClicked: (color: string) =>
      dispatch(HighlightDropdownRowClicked(color)),
    onHighlightDropdownClearClicked: () =>
      dispatch({ type: HIGHLIGHT_CLEAR_DROPDOWN_CLICKED }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Subheader);
