import { combineReducers } from "redux";

import examReducer from "../Exam/reducer";
import sectionReducer from "../Section/reducer";
import navigationReducer from "../Navigation/reducer";
import navigationItemListReducer from "../NavigationItemList/reducer";
import dialogReducer from "../Dialog/reducer";
import timingReducer from "../Timing/reducer";
import headerReducer from "../Header/reducer";
import itemAnnotationReducer from "../ItemAnnotation/reducer";
import itemContentReducer from "../ItemContent/reducer";
import itemStateReducer from "../ItemState/reducer";
import { errorCatchingReducer } from "../../utils/AppError";

const reducer = combineReducers({
  exam: examReducer,
  section: sectionReducer,
  navigation: navigationReducer,
  navigationItemList: navigationItemListReducer,
  dialog: dialogReducer,
  timing: timingReducer,
  header: headerReducer,
  itemAnnotation: itemAnnotationReducer,
  itemContent: itemContentReducer,
  itemState: itemStateReducer,
  errorState: errorCatchingReducer,
});

export default reducer;
