import { AppStateContainingError } from '../../utils/AppError';
import DialogState, { Default as DialogDefault } from '../Dialog/state';
import ExamState, { Default as ExamDefault } from '../Exam/state';
import HeaderState, { Default as HeaderDefault } from '../Header/state';
import ItemAnnotationState, {
  Default as ItemAnnotationDefault,
} from '../ItemAnnotation/state';
import ItemContentState, {
  Default as ItemContentDefault,
} from '../ItemContent/state';
import ItemStateState, {
  Default as ItemStateDefault,
} from '../ItemState/state';
import NavigationState, {
  Default as NavigationDefault,
} from '../Navigation/state';
import NavigationItemListState, {
  Default as NavigationItemListDefault,
} from '../NavigationItemList/state';
import SectionState, { Default as SectionDefault } from '../Section/state';
import TimingState, { Default as TimingDefault } from '../Timing/state';

interface State extends AppStateContainingError {
  exam: ExamState;
  section: SectionState;
  header: HeaderState;
  navigation: NavigationState;
  navigationItemList: NavigationItemListState;
  dialog: DialogState;
  timing: TimingState;
  itemAnnotation: ItemAnnotationState;
  itemContent: ItemContentState;
  itemState: ItemStateState;
}

export const Default: State = {
  exam: ExamDefault,
  section: SectionDefault,
  header: HeaderDefault,
  navigation: NavigationDefault,
  navigationItemList: NavigationItemListDefault,
  dialog: DialogDefault,
  timing: TimingDefault,
  itemAnnotation: ItemAnnotationDefault,
  itemContent: ItemContentDefault,
  itemState: ItemStateDefault,
  errorState: {},
};

export default State;

export interface SectionStateForPersistence {
  examDefinitionId: string;
  timing: TimingState;
  itemAnnotation: ItemAnnotationState;
  itemState: ItemStateState;
  navigation: NavigationState;
}

export const GetSectionStateToSave = (
  state: State
): SectionStateForPersistence => ({
  examDefinitionId: state.section.examDefinitionId,
  timing: { ...state.timing },
  itemAnnotation: { ...state.itemAnnotation },
  itemState: { ...state.itemState },
  navigation: { ...state.navigation },
});
