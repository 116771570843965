export interface ResponseCondition {
  id: string;
  response: string;
}

export interface Path {
  destination: string | null;
  conditions: Array<ResponseCondition>;
  anyConditionSufficient: boolean;
}

type State = {
  id: string;
  loading?: boolean;
  sectionNavigationInProgress?: boolean;
  loaded?: boolean;
  lastLoaded: number;
  title: string;
  candidateName: string;
  currentSectionId: string | null;
  sectionRouting: Record<string, Array<Path>>;
  decisions: Record<string, string>;
  deliveredSections: Array<string>;
};

export default State;

export const Default: State = {
  id: "",
  title: "",
  lastLoaded: 0,
  candidateName: "",
  currentSectionId: "",
  sectionRouting: {},
  decisions: {},
  deliveredSections: [],
};
