import React from 'react';

import { connect } from 'react-redux';

import {
  Grid,
  Slide,
} from '@material-ui/core';

import DateAttemptedDetailCard from './detailcards/DateAttemptedDetailCard';
import PercentileRankDetailCard from './detailcards/PercentileRankDetailCard';
import QuestionsCorrectDetailCard
  from './detailcards/QuestionsCorrectDetailCard';
import ScaledScoreDetailCard from './detailcards/ScaledScoreDetailCard';
import TimeSpentDetailCard from './detailcards/TimeSpentDetailCard';

const SummaryDetailCards = () => {
  return (
    <Slide direction="left" in={true} timeout={500}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className="details-grid"
      >
        <Grid item>
          <ScaledScoreDetailCard />
        </Grid>
        <Grid item>
          <PercentileRankDetailCard />
        </Grid>
        <Grid item>
          <QuestionsCorrectDetailCard />
        </Grid>
        <Grid item>
          <TimeSpentDetailCard />
        </Grid>
        <Grid item>
          <DateAttemptedDetailCard />
        </Grid>
      </Grid>
    </Slide>
  );
};

export default connect()(SummaryDetailCards);
