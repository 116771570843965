type ItemState = {
  id: string;
  response: string;
};

type State = Record<string, ItemState>;

export const Default: State = {};

export default State;
