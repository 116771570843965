import { Action } from 'redux';

import { getResults } from '../../api/result';
import { UnrecoverableException } from '../../utils/AppError';
import {
  AppThunk,
  DETAIL_TAB_SWITCH,
  DetailTabAction,
  NAVIGATE_TO,
  NavigationAction,
  RESULTS_LOAD_BEGIN,
  ResultsLoaded,
} from '../app/actions';
import State, { DefaultState } from './state';

const onNavigateTo = (state: State, destination: string): State => {
  return {
    ...state,
    location: destination,
    detailTab: 0,
  };
};

const onDetailTabSwitch = (state: State, tab: number): State => {
  return {
    ...state,
    detailTab: tab,
  };
};

const reducer = (state: State, action: Action): State => {
  if (!state) return DefaultState;

  switch (action.type) {
    case RESULTS_LOAD_BEGIN:
      return DefaultState;
    case NAVIGATE_TO:
      return onNavigateTo(state, (action as NavigationAction).destination);
    case DETAIL_TAB_SWITCH:
      return onDetailTabSwitch(state, (action as DetailTabAction).index);
  }
  return state;
};

export default reducer;

export const fetchResult =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      let response = await getResults(id);
      dispatch(ResultsLoaded(response));
    } catch (error) {
      dispatch(UnrecoverableException(error as Error));
    }
  };
