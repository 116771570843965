import { connect } from "react-redux";
import {
  DialogClose,
  END_SECTION,
  AppDispatch,
  SaveSectionState,
  SaveExamState,
  ChainActions,
} from "../app/actions";
import State from "../app/state";
import {
  Actions,
  EndIncompleteSection,
  Properties,
} from "../components/EndIncompleteSection";
import { EndSectionIncompleteWarningDialog } from "../Dialog/state";

const mapStateToProps = (state: State): Properties => {
  let items = state.navigation.itemIdentifiers;
  let completable = items.filter(
    (id) => state.itemContent.items[id].completable
  ).length;
  let completed = state.navigation.itemsCompleted.length;

  return {
    incomplete: completable - completed,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch): Actions => {
  return {
    onYesSelected: () =>
      dispatch(
        ChainActions(
          DialogClose(EndSectionIncompleteWarningDialog),
          SaveSectionState(),
          { type: END_SECTION },
          SaveExamState()
        )
      ),
    onNoSelected: () =>
      dispatch(DialogClose(EndSectionIncompleteWarningDialog)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EndIncompleteSection);
