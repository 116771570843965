import { connect } from "react-redux";
import { secondsToTimeDisplay } from "../../../utils/formatting";
import State from "../../app/state";
import {
  Properties,
  ResultDetailCard,
} from "../../components/ResultDetailCard";

const mapStateToProps = (state: State): Properties => {
  return {
    description: "Time Spent",
    value: secondsToTimeDisplay(state.overallResults.timeSpentInSeconds),
  };
};

export default connect(mapStateToProps)(ResultDetailCard);
