import { Action } from "redux";
import {
  PartialStateAction,
  RESULTS_LOADED,
  RESULTS_LOAD_BEGIN,
} from "../app/actions";
import State, { DefaultState } from "./state";

const reducer = (state: State, action: Action): State => {
  if (!state) return DefaultState;
  switch (action.type) {
    case RESULTS_LOAD_BEGIN:
      return DefaultState;
    case RESULTS_LOADED:
      return {
        ...state,
        ...(action as PartialStateAction).newState.sectionResults,
      };
    default:
      return state;
  }
};

export default reducer;
