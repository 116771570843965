import { Box, Container, Grid } from "@material-ui/core";
import React, { FunctionComponent, useEffect } from "react";
import { connect } from "react-redux";
import { AppDispatch, UpdateProductQueryParams } from "../../app/actions";
import State from "../../app/state";
import { Pagination } from "../../components/Pagination";
import { fetchProducts } from "../../Products/reducer";
import Product from "./Product";

interface Properties {
  ids: Array<string>;
  skip: number;
  take: number;
  totalRecords: number;
  loggedIn: boolean;
}

interface Actions {
  loadProducts: () => void;
  onUpdateQueryParams: (skip: number, take: number) => void;
}

type AllProperties = Properties & Actions;

const Marketplace: FunctionComponent<AllProperties> = ({
  ids,
  skip,
  take,
  totalRecords,
  loggedIn,
  loadProducts,
  onUpdateQueryParams,
}) => {
  useEffect(() => {
    loadProducts();
  }, [loadProducts, skip, take, loggedIn]);
  return (
    <>
      <Container className="query-results marketplace">
        <Grid container spacing={3}>
          {ids.map((id, index) => (
            <Grid item key={id} lg={3} md={4} sm={6} xs={12}>
              <Product id={id} index={index} />
            </Grid>
          ))}
        </Grid>
      </Container>
      {(skip > 0 || totalRecords > take) && (
        <Box className="query-pagination">
          <Pagination
            skip={skip}
            take={take}
            totalRecords={totalRecords}
            onUpdateQueryParams={onUpdateQueryParams}
          />
        </Box>
      )}
    </>
  );
};

const mapStateToProps = (state: State): Properties => {
  return {
    ids: state.products.data.map((p) => p.id),
    skip: state.products.skip,
    take: state.products.take,
    totalRecords: state.products.totalRecords,
    loggedIn: state.user.loggedIn,
  };
};

const mapDispatchToActions = (dispatch: AppDispatch): Actions => {
  return {
    loadProducts: () => dispatch(fetchProducts()),
    onUpdateQueryParams: (skip: number, take: number) =>
      dispatch(UpdateProductQueryParams(skip, take)),
  };
};

export default connect(mapStateToProps, mapDispatchToActions)(Marketplace);
