import React, { FunctionComponent, useEffect } from "react";
import { onAltHotkey } from "../../utils/hotkeys";

export interface Properties {}

export interface Actions {
  onOk: () => void;
}

type AllProperties = Properties & Actions;

export const ItemResponseRequired: FunctionComponent<AllProperties> = ({
  onOk,
}) => {
  useEffect(() => {
    let hotkeyListeners = [onAltHotkey("o", onOk)];
    return () => hotkeyListeners.forEach((unsub) => unsub());
  });

  return (
    <>
      <div className="content">
        <div className="icon"></div>
        <div className="message">
          You cannot advance without answering this question.
        </div>
      </div>
      <div className="action-buttons">
        <div className="action-button ok" onClick={onOk}>
          <span className="underline">O</span>
          <span>K</span>
        </div>
      </div>
    </>
  );
};
