import { connect } from 'react-redux';

import {
  AppDispatch,
  ChainActions,
  UploadPackageFileSelected,
} from '../../../app/actions';
import State from '../../../app/state';
import {
  Actions,
  Properties,
  UploadPackage,
} from '../../../components/UploadPackage';
import { BeginPackageFileUpload } from '../../../UploadPackage/reducer';

const mapStateToProps = (state: State): Properties => {
  return {
    uploadFile: state.uploadPackage.fileBeingUploaded,
    uploadProcessing: state.uploadPackage.uploadProcessing || false,
    uploadSuccessful: state.uploadPackage.uploadSuccessful || false,
    uploadFailed: state.uploadPackage.uploadFailed || false,
  };
};

const mapDispatchToActions = (dispatch: AppDispatch): Actions => {
  return {
    onFileSelected: (file: File) =>
      dispatch(
        ChainActions(UploadPackageFileSelected(file), BeginPackageFileUpload())
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToActions)(UploadPackage);
