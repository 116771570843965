type TimeoutWarning = {
  atTime: number;
  warningMessage: string;
  warningTitle: string;
};

type State = {
  timed: boolean;
  timeRemaining: number;
  timeoutWarnings: Array<TimeoutWarning>;
  warningDisplayed: boolean;
  currentWarningMessage: string;
  currentWarningTitle: string;
  timeSpentInSeconds: number;
};

export const Default: State = {
  timed: false,
  timeRemaining: 0,
  timeoutWarnings: [],
  warningDisplayed: false,
  currentWarningMessage: "",
  currentWarningTitle: "",
  timeSpentInSeconds: 0,
};

export default State;
