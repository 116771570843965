import { Action, Reducer } from "redux";
import {
  ColumnAction,
  PartialStateAction,
  NAVIGATION_DIALOG_COLUMN_HEADER_CLICKED,
  SECTION_LOADED,
  BEGIN_LOAD_EXAM,
} from "../app/actions";
import State, { Default } from "./state";

const onItemDialogColumnClicked = (state: State, column: string): State => {
  return {
    ...state,
    sortOn: column,
    sortDir:
      state.sortOn !== column
        ? "asc"
        : state.sortDir === "asc"
        ? "desc"
        : "asc",
  };
};

const reducer: Reducer<State, Action> = (state, action) => {
  if (!state) return Default;

  switch (action.type) {
    case BEGIN_LOAD_EXAM:
      return Default;
    case SECTION_LOADED:
      let newState = (action as PartialStateAction).state.navigationItemList;
      if (newState) return { ...newState };
      return state;
    case NAVIGATION_DIALOG_COLUMN_HEADER_CLICKED:
      return onItemDialogColumnClicked(state, (action as ColumnAction).column);
    default:
      return state;
  }
};

export default reducer;
