import { connect } from 'react-redux';

import {
  AppDispatch,
  BEGIN_LOAD_EXAM,
  CombineActions,
} from '../app/actions';
import State from '../app/state';
import {
  Actions,
  Exam,
  Properties,
} from '../components/Exam';
import { fetchExam } from '../Exam/reducer';

interface OwnProps {
  onCompletionOrSuspension: () => void;
}

const mapStateToProps = (state: State): Properties => {
  return {
    lastLoaded: state.exam.lastLoaded,
    loading: state.exam.loading || false,
    currentSection: state.exam.currentSectionId,
  };
};

const mapDispatchToProps = (
  dispatch: AppDispatch,
  ownProps: OwnProps
): Actions => {
  return {
    fetchExam: (id: string) =>
      dispatch(CombineActions({ type: BEGIN_LOAD_EXAM }, fetchExam(id))),
    onCompletionOrSuspension: ownProps.onCompletionOrSuspension,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Exam);
