import React, { FunctionComponent, useEffect } from "react";
import { onAltHotkey } from "../../utils/hotkeys";

export interface OwnProps {
  dialogName: string;
  dialogClass?: string;
  header: JSX.Element;
  contents: JSX.Element;
  showFooter?: boolean;
  canResize?: boolean;
  canMove?: boolean;
  modal?: boolean;
}

export interface Properties {
  open: boolean;
  top: number;
  left: number;
  width: number;
  height: number;
}

export interface Actions {
  onDialogCloseClicked: () => void;

  onHeaderMouseDown: (event: React.PointerEvent) => void;
  onHeaderMouseUp: (event: React.PointerEvent) => void;
  onHeaderMouseMove: (event: React.PointerEvent) => void;
  onSouthWestResizeDown: (event: React.PointerEvent) => void;
  onSouthWestResizeUp: (event: React.PointerEvent) => void;
  onSouthWestResizeMove: (event: React.PointerEvent) => void;
  onSouthEastResizeDown: (event: React.PointerEvent) => void;
  onSouthEastResizeUp: (event: React.PointerEvent) => void;
  onSouthEastResizeMove: (event: React.PointerEvent) => void;

  onNorthResizeDown: (event: React.PointerEvent) => void;
  onNorthResizeUp: (event: React.PointerEvent) => void;
  onNorthResizeMove: (event: React.PointerEvent) => void;

  onSouthResizeDown: (event: React.PointerEvent) => void;
  onSouthResizeUp: (event: React.PointerEvent) => void;
  onSouthResizeMove: (event: React.PointerEvent) => void;

  onEastResizeDown: (event: React.PointerEvent) => void;
  onEastResizeUp: (event: React.PointerEvent) => void;
  onEastResizeMove: (event: React.PointerEvent) => void;

  onWestResizeDown: (event: React.PointerEvent) => void;
  onWestResizeUp: (event: React.PointerEvent) => void;
  onWestResizeMove: (event: React.PointerEvent) => void;
}

type AllProperties = OwnProps & Properties & Actions;

export const Dialog: FunctionComponent<AllProperties> = ({
  open,
  top,
  left,
  width,
  height,
  header,
  contents,
  dialogClass,
  dialogName,
  showFooter = true,
  canMove = true,
  canResize = true,
  modal = false,
  onDialogCloseClicked,
  onHeaderMouseDown,
  onHeaderMouseUp,
  onHeaderMouseMove,
  onSouthWestResizeDown,
  onSouthWestResizeUp,
  onSouthWestResizeMove,
  onSouthEastResizeDown,
  onSouthEastResizeUp,
  onSouthEastResizeMove,
  onNorthResizeDown,
  onNorthResizeUp,
  onNorthResizeMove,
  onSouthResizeDown,
  onSouthResizeUp,
  onSouthResizeMove,
  onEastResizeDown,
  onEastResizeUp,
  onEastResizeMove,
  onWestResizeDown,
  onWestResizeUp,
  onWestResizeMove,
}) => {
  useEffect(() => {
    let hotkeyListeners = [onAltHotkey("c", onDialogCloseClicked, open)];
    return () => hotkeyListeners.forEach((unsub) => unsub());
  });

  return (
    <>
      {modal && (
        <span className={`modal-backdrop ${open ? "open" : "closed"}`}></span>
      )}
      <div
        data-dialog-name={dialogName}
        className={`${dialogClass || ""} dialog ${open ? "open" : "closed"}`}
        style={{
          top,
          left,
          height,
          width,
        }}
      >
        {canResize && (
          <>
            <span
              className="resize n"
              onPointerDown={(event) => {
                event.currentTarget.setPointerCapture(event.pointerId);
                onNorthResizeDown(event);
              }}
              onPointerUp={(event) => {
                event.currentTarget.releasePointerCapture(event.pointerId);
                onNorthResizeUp(event);
              }}
              onPointerMove={onNorthResizeMove}
            ></span>
            <span
              className="resize s"
              onPointerDown={(event) => {
                event.currentTarget.setPointerCapture(event.pointerId);
                onSouthResizeDown(event);
              }}
              onPointerUp={(event) => {
                event.currentTarget.releasePointerCapture(event.pointerId);
                onSouthResizeUp(event);
              }}
              onPointerMove={onSouthResizeMove}
            ></span>
            <span
              className="resize e"
              onPointerDown={(event) => {
                event.currentTarget.setPointerCapture(event.pointerId);
                onEastResizeDown(event);
              }}
              onPointerUp={(event) => {
                event.currentTarget.releasePointerCapture(event.pointerId);
                onEastResizeUp(event);
              }}
              onPointerMove={onEastResizeMove}
            ></span>
            <span
              className="resize w"
              onPointerDown={(event) => {
                event.currentTarget.setPointerCapture(event.pointerId);
                onWestResizeDown(event);
              }}
              onPointerUp={(event) => {
                event.currentTarget.releasePointerCapture(event.pointerId);
                onWestResizeUp(event);
              }}
              onPointerMove={onWestResizeMove}
            ></span>
          </>
        )}
        <div className="dialog-header">
          <div
            className={canMove ? "dragable" : ""}
            onPointerDown={(event) => {
              if (!canMove) return;
              event.currentTarget.setPointerCapture(event.pointerId);
              onHeaderMouseDown(event);
            }}
            onPointerUp={(event) => {
              if (!canMove) return;
              event.currentTarget.releasePointerCapture(event.pointerId);
              onHeaderMouseUp(event);
            }}
            onPointerMove={(event) => {
              if (!canMove) return;
              onHeaderMouseMove(event);
            }}
          >
            {open && header}
          </div>
          <span className="close" onClick={onDialogCloseClicked}></span>
        </div>
        <div className="dialog-content">{open && contents}</div>
        {showFooter && (
          <div className="dialog-footer">
            {canResize && (
              <span
                className="resize sw"
                onPointerDown={(event) => {
                  event.currentTarget.setPointerCapture(event.pointerId);
                  onSouthWestResizeDown(event);
                }}
                onPointerUp={(event) => {
                  event.currentTarget.releasePointerCapture(event.pointerId);
                  onSouthWestResizeUp(event);
                }}
                onPointerMove={onSouthWestResizeMove}
              />
            )}
            <span className="close" onClick={onDialogCloseClicked}>
              <span className="underline">C</span>
              <span>lose</span>
            </span>
            {canResize && (
              <span
                className="resize se"
                onPointerDown={(event) => {
                  event.currentTarget.setPointerCapture(event.pointerId);
                  onSouthEastResizeDown(event);
                }}
                onPointerUp={(event) => {
                  event.currentTarget.releasePointerCapture(event.pointerId);
                  onSouthEastResizeUp(event);
                }}
                onPointerMove={onSouthEastResizeMove}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};
