import React, { FunctionComponent } from 'react';

import { connect } from 'react-redux';

import {
  Grid,
  Slide,
} from '@material-ui/core';

import FlaggedCorrectDetailCard from './detailcards/FlaggedCorrectDetailCard';
import FlaggedIncorrectDetailCard
  from './detailcards/FlaggedIncorrectDetailCard';
import PercentileRankDetailCard from './detailcards/PercentileRankDetailCard';
import QuestionsCorrectDetailCard
  from './detailcards/QuestionsCorrectDetailCard';
import QuestionsIncompleteDetailCard
  from './detailcards/QuestionsIncompleteDetailCard';
import ScaledScoreDetailCard from './detailcards/ScaledScoreDetailCard';

interface OwnProps {
  sectionId: string;
}

const SectionDetailCards: FunctionComponent<OwnProps> = ({ sectionId }) => {
  return (
    <Slide direction="right" in={true} timeout={500}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className="details-grid"
      >
        <Grid item>
          <ScaledScoreDetailCard sectionId={sectionId} />
        </Grid>
        <Grid item>
          <PercentileRankDetailCard sectionId={sectionId} />
        </Grid>
        <Grid item>
          <QuestionsCorrectDetailCard sectionId={sectionId} />
        </Grid>
        <Grid item>
          <QuestionsIncompleteDetailCard sectionId={sectionId} />
        </Grid>
        <Grid item>
          <FlaggedCorrectDetailCard sectionId={sectionId} />
        </Grid>
        <Grid item>
          <FlaggedIncorrectDetailCard sectionId={sectionId} />
        </Grid>
      </Grid>
    </Slide>
  );
};

export default connect()(SectionDetailCards);
