import { Button, Grid, TextField, Tooltip } from "@material-ui/core";
import React, { FunctionComponent } from "react";

interface Product {
  id: string;
  title: string;
  description: string;
  logo: string;
  examDefinitionId: string;
  price: number;
}

export interface Properties {
  product: Product;
  isNew: boolean;
}

export interface Actions {
  onUpdate: (update: Product) => void;
  onLogoSelect: (format: string, file: File) => void;
  onAccept: () => void;
  onCancel: () => void;
}

type AllProperties = Properties & Actions;

export const EditProductDefinition: FunctionComponent<AllProperties> = ({
  product,
  isNew,
  onUpdate,
  onLogoSelect,
  onAccept,
  onCancel,
}) => {
  return (
    <Grid container>
      <Grid item xs={10}>
        <Grid container spacing={5}>
          {!isNew && (
            <Grid item xs={6}>
              <TextField
                value={product.id}
                label="Id"
                disabled={true}
                fullWidth={true}
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <TextField
              value={product.title}
              label="Title"
              fullWidth={true}
              onChange={(event) =>
                onUpdate(
                  Object.assign({}, product, {
                    title: event.target.value,
                  })
                )
              }
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              value={product.description}
              label="Description"
              multiline={true}
              fullWidth={true}
              onChange={(event) =>
                onUpdate(
                  Object.assign({}, product, {
                    description: event.target.value,
                  })
                )
              }
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              value={product.examDefinitionId}
              label="Exam ID"
              fullWidth={true}
              onChange={(event) =>
                onUpdate(
                  Object.assign({}, product, {
                    examDefinitionId: event.target.value,
                  })
                )
              }
            />
          </Grid>
          <Grid item className="logo-edit-holder" xs={6}>
            <Tooltip title={<img src={product.logo} alt="logo-full" />}>
              <img className="product-def-logo" src={product.logo} alt="logo" />
            </Tooltip>
            <Button
              variant="contained"
              component="label"
              className="select-file-button"
            >
              Select New
              <input
                type="file"
                hidden
                onChange={(event) => {
                  event.target?.files &&
                    event.target.files[0] &&
                    onLogoSelect(
                      event.target.files[0].name.split(".").reverse()[0],
                      event.target.files[0]
                    );
                  event.target.value = "";
                }}
              />
            </Button>
          </Grid>
          <Grid item xs={6}>
            <TextField
              value={product.price}
              label="Price"
              fullWidth={true}
              onChange={(event) =>
                onUpdate(
                  Object.assign({}, product, {
                    price: event.target.value,
                  })
                )
              }
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={2}>
        <Button onClick={onAccept}>Accept</Button>
        <Button onClick={onCancel}>Cancel</Button>
      </Grid>
    </Grid>
  );
};
