import React, { FunctionComponent } from "react";

type ItemStatus = "unseen" | "incomplete" | "complete";

interface Item {
  id: string;
  number: number;
  seen: boolean;
  complete: boolean;
  flagged: boolean;
}

export interface Properties {
  sortOn: string;
  sortDir: string;
  itemsSnapshot: Array<Item>;
}

export interface Actions {
  onItemNavigation: (id: string) => void;
  onColumnClicked: (column: string) => void;
}

type AllProperties = Properties & Actions;

export const NavigationItemList: FunctionComponent<AllProperties> = ({
  sortOn,
  sortDir,
  itemsSnapshot,
  onItemNavigation,
  onColumnClicked,
}) => {
  const getItemStatus = (item: Item): ItemStatus =>
    !item.seen ? "unseen" : item.complete ? "complete" : "incomplete";
  const getItemSortProp = (item: Item) => {
    switch (sortOn) {
      case "question":
        return item.number;
      case "status":
        return getItemStatus(item);
      default:
        return item.flagged;
    }
  };

  const orderedItems = itemsSnapshot.sort((itemOne, itemTwo) => {
    let propOne = getItemSortProp(itemOne);
    let propTwo = getItemSortProp(itemTwo);

    if (sortDir === "asc") return propOne > propTwo ? 1 : -1;

    return propOne > propTwo ? -1 : 1;
  });

  const unseenOrIncomplete = itemsSnapshot.filter((i) => !i.seen || !i.complete)
    .length;

  return (
    <>
      <div className="item-list scrollable">
        <table>
          <thead>
            <tr>
              <th
                className="column"
                onClick={() => onColumnClicked("question")}
              >
                Question #
                {sortOn === "question" && sortDir === "asc" && (
                  <span className="sort-asc"></span>
                )}
                {sortOn === "question" && sortDir === "desc" && (
                  <span className="sort-desc"></span>
                )}
              </th>
              <th className="column" onClick={() => onColumnClicked("status")}>
                Status
                {sortOn === "status" && sortDir === "asc" && (
                  <span className="sort-asc"></span>
                )}
                {sortOn === "status" && sortDir === "desc" && (
                  <span className="sort-desc"></span>
                )}
              </th>
              <th className="column" onClick={() => onColumnClicked("flagged")}>
                Flagged
                {sortOn === "flagged" && sortDir === "asc" && (
                  <span className="sort-asc"></span>
                )}
                {sortOn === "flagged" && sortDir === "desc" && (
                  <span className="sort-desc"></span>
                )}
              </th>
            </tr>
          </thead>
          <tbody>
            {orderedItems.map((item, index) => (
              <tr
                key={item.id}
                className={`question-row ${index % 2 === 0 ? "even" : "odd"}`}
              >
                <td>
                  <span
                    className="question-link"
                    onClick={() => onItemNavigation(item.id)}
                  >
                    Question {item.number}
                  </span>
                </td>
                <td>
                  {!item.seen && (
                    <span className="question-status unseen">Unseen</span>
                  )}
                  {item.seen && !item.complete && (
                    <span className="question-status incomplete">
                      Incomplete
                    </span>
                  )}
                  {item.seen && item.complete && (
                    <span className="question-status complete">Complete</span>
                  )}
                </td>
                <td className="flag-cell">
                  {item.flagged && <span className="flagged-icon"></span>}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="item-list-footer">
        {unseenOrIncomplete} Unseen/Incomplete
      </div>
    </>
  );
};
