import '../assets/css/ExamApp.scss';

import React, { FunctionComponent } from 'react';

import { Provider } from 'react-redux';

import { AppTimer } from '../utils/AppTimer';
import ErrorWrapper from '../utils/ErrorWrapper';
import store from './app/store';
import Dialog from './connections/Dialog';
import EndIncompleteSection from './connections/EndIncompleteSection';
import Exam from './connections/Exam';
import Header from './connections/Header';
import ItemResponseRequired from './connections/ItemResponseRequired';
import LoadingSplash from './connections/LoadingSplash';
import MainDisplay from './connections/MainDisplay';
import NavigationBar from './connections/NavigationBar';
import NavigationItemList from './connections/NavigationItemList';
import Pause from './connections/Pause';
import Section from './connections/Section';
import Subheader from './connections/Subheader';
import Timeout from './connections/Timeout';
import TimeoutWarning from './connections/TimeoutWarning';
import TimeoutWarningHeader from './connections/TimeoutWarningHeader';
import {
  EndSectionIncompleteWarningDialog,
  ItemResponseRequiredDialog,
  NavigationDialog,
  PeriodicTableDialog,
  TimeoutWarningDialog,
} from './Dialog/state';

export interface AppProperties {
  examId: string;
  requestedAt: number;
  onCompletionOrSuspension: () => void;
}

const App: FunctionComponent<AppProperties> = ({
  examId,
  requestedAt,
  onCompletionOrSuspension,
}) => {
  return (
    <Provider store={store}>
      <ErrorWrapper
        message="An error has occurred during your exam administration"
        details="Your progress within the exam has been saved - please refresh your browser and try again"
      >
        <div className="examination-container">
          <Exam
            id={examId}
            requestedAt={requestedAt}
            onCompletionOrSuspension={onCompletionOrSuspension}
          >
            <Section>
              <LoadingSplash>
                <AppTimer>
                  <Timeout />
                  <Header />
                  <Subheader />
                  <Pause onCompletionOrSuspension={onCompletionOrSuspension} />
                  <MainDisplay />
                  <NavigationBar />
                  <Dialog
                    dialogName={NavigationDialog}
                    dialogClass="navigation-item-list"
                    contents={<NavigationItemList />}
                    header={
                      <span className="title">
                        <span className="icon"></span>
                        <span className="text">
                          <span className="important">Navigation</span> - select
                          a question to go to it
                        </span>
                      </span>
                    }
                    modal={true}
                  />
                  <Dialog
                    dialogName={PeriodicTableDialog}
                    dialogClass="periodic-table"
                    contents={
                      <div className="periodic-table-container scrollable">
                        <span className="periodic-table-image"></span>
                      </div>
                    }
                    header={
                      <span className="title">
                        <span className="icon"></span>
                        <span className="text">
                          <span className="important">Periodic Table</span>
                        </span>
                      </span>
                    }
                  />
                  <Dialog
                    dialogName={EndSectionIncompleteWarningDialog}
                    dialogClass="end-section-incomplete-warning"
                    contents={<EndIncompleteSection />}
                    header={<span className="title">End Section</span>}
                    showFooter={false}
                    canResize={false}
                    modal={true}
                  />
                  <Dialog
                    dialogName={TimeoutWarningDialog}
                    dialogClass="timeout-warning-dialog"
                    contents={<TimeoutWarning />}
                    header={<TimeoutWarningHeader />}
                    canResize={false}
                  />
                  <Dialog
                    dialogName={ItemResponseRequiredDialog}
                    dialogClass="item-response-required-dialog"
                    contents={<ItemResponseRequired />}
                    header={<span className="title">Response Required</span>}
                    canResize={false}
                    modal={true}
                    showFooter={false}
                  />
                </AppTimer>
              </LoadingSplash>
            </Section>
          </Exam>
        </div>
      </ErrorWrapper>
    </Provider>
  );
};

export default App;
