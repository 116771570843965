import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { FunctionComponent } from "react";
import HelpIcon from "@material-ui/icons/Help";

export interface OwnProps {
  forSection: boolean;
}

export interface Properties {
  open: boolean;
}

export interface Actions {
  onHelpOpen: () => void;
  onHelpClose: () => void;
}

type AllProperties = OwnProps & Properties & Actions;

export const ScaledScoreHelp: FunctionComponent<AllProperties> = ({
  forSection,
  open,
  onHelpOpen,
  onHelpClose,
}) => {
  return (
    <>
      <Tooltip
        title={
          forSection ? (
            <Typography variant="subtitle2">
              Your section score will range from a low of 118 to a high of 132.
              Learn more about scaled scores by clicking this icon.
            </Typography>
          ) : (
            <Typography variant="subtitle2">
              Your total score is the sum of the four individual section scores
              and will range from 472 to 528. Learn more about scaled scores by
              clicking this icon.
            </Typography>
          )
        }
      >
        <HelpIcon
          fontSize="small"
          style={{ fontSize: "1rem", marginLeft: 5, cursor: "pointer" }}
          onClick={onHelpOpen}
        />
      </Tooltip>
      <Dialog open={open}>
        <DialogTitle>Scaled Score</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You will receive a separate score for each of the four sections of
            the MCAT exam, as well as a total score. Each section is scored from
            a low of 118 to a high of 132. Your total score is the sum of the
            four individual section scores and will range from 472 to 528. There
            is no penalty for guessing on the MCAT exam or on MCAT Official Prep
            practice exams.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onHelpClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
