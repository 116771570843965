import { connect } from "react-redux";
import {
  AppDispatch,
  CLOSE_HELP,
  OPEN_PERCENTILE_RANK_HELP,
} from "../app/actions";
import State from "../app/state";
import {
  Actions,
  PercentileRankHelp,
  Properties,
} from "../components/PercentileRanksHelp";

const mapStateToProps = (state: State): Properties => {
  return {
    open: state.help.percentileRankHelpOpen,
  };
};

const mapDispatchToActions = (dispatch: AppDispatch): Actions => {
  return {
    onHelpOpen: () => dispatch({ type: OPEN_PERCENTILE_RANK_HELP }),
    onHelpClose: () => dispatch({ type: CLOSE_HELP }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToActions
)(PercentileRankHelp);
