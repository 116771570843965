import { Box, Slide, Typography } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import ScoreDistributionCurve from "./graphics/ScoreDistributionCurve";
import SummaryDetailCards from "./SummaryDetailCards";
import SectionSummaryTable from "./SummarySectionTable";

const Summary = () => {
  return (
    <Slide direction="right" in={true}>
      <Box>
        <Box className="summary horizontal-stack spread">
          <Box className="vertical-stack score-curve">
            <Typography variant="h4" className="overall-label">
              Overall Scaled Score
            </Typography>
            <ScoreDistributionCurve />
          </Box>
          <SummaryDetailCards />
        </Box>
        <SectionSummaryTable />
      </Box>
    </Slide>
  );
};

export default connect()(Summary);
