export interface AnnotationRange {
  start: number;
  end: number;
}

export type AnnotationDecoration = {
  type: "highlight" | "strikethrough";
  subtype: string;
};

export type Annotation = {
  range: AnnotationRange;
  decorations: Array<AnnotationDecoration>;
};

export const CLEAR_DECORATION = "clear";

type State = {
  annotations: Record<string, Array<Annotation>>;

  availableHighlightColors: Array<string>;
  currentHighlightSelection: string;

  currentContainerId?: string;
  currentAnnotationTarget?: string;
  currentAnnotationRange?: AnnotationRange;
};

export const Default: State = {
  annotations: {},
  availableHighlightColors: [],
  currentHighlightSelection: "",
};

export default State;
