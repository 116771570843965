type SectionResult = {
  id: string;
  name: string;
  minScaledScore: number;
  maxScaledScore: number;
  scaledScore: number;
  percentage: number;
  totalQuestions: number;
  questionsCorrect: number;
  incomplete: number;
  averageQuestionTimeInSeconds: number;
  flaggedCorrect: number;
  flaggedIncorrect: number;
};

type State = Record<string, SectionResult>;

export default State;

export const DefaultState: State = {};
