import { Action, Reducer } from "redux";
import {
  PartialStateAction,
  RESULTS_LOADED,
  RESULTS_LOAD_BEGIN,
} from "../app/actions";
import State, { Default } from "./state";

const reducer: Reducer<State, Action> = (state, action) => {
  if (!state) return Default;
  switch (action.type) {
    case RESULTS_LOAD_BEGIN:
      return Default;
    case RESULTS_LOADED:
      let newState = (action as PartialStateAction).newState.itemAnnotation;
      if (newState) return { ...newState };
      return state;
    default:
      return state;
  }
};

export default reducer;
