import { connect } from "react-redux";
import { AppDispatch } from "../../app/actions";
import State from "../../app/state";
import { Actions, Product, Properties } from "../../components/Product";
import { redirectToCheckoutThunk } from "../../Products/reducer";

interface OwnProps {
  id: string;
  index: number;
}

const mapStateToProps = (state: State, ownProps: OwnProps): Properties => {
  let product = state.products.data.find((p) => p.id === ownProps.id)!;
  return {
    index: ownProps.index,
    loggedIn: state.user.loggedIn,
    name: product.title,
    description: product.description,
    logo: product.logo,
    price: product.price,
    purchased: product.purchased,
  };
};

const mapDispatchToActions = (
  dispatch: AppDispatch,
  ownProps: OwnProps
): Actions => {
  return {
    onPurchase: () => dispatch(redirectToCheckoutThunk(ownProps.id)),
  };
};

export default connect(mapStateToProps, mapDispatchToActions)(Product);
