type State = {
  reviewing: boolean;
  sectionId: string;
  itemsBeingReviewed: Array<string>;
  itemReviewIndex: number;
  direction?: string;
};

export default State;

export const DefaultState: State = {
  reviewing: false,
  sectionId: "",
  itemsBeingReviewed: [],
  itemReviewIndex: 0,
};
