import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { FunctionComponent } from "react";
import HelpIcon from "@material-ui/icons/Help";

export interface Properties {
  open: boolean;
}

export interface Actions {
  onHelpOpen: () => void;
  onHelpClose: () => void;
}

type AllProperties = Properties & Actions;

export const PercentileRankHelp: FunctionComponent<AllProperties> = ({
  open,
  onHelpOpen,
  onHelpClose,
}) => {
  return (
    <>
      <Tooltip
        title={
          <Typography variant="subtitle2">
            The percentile ranks provided on your score reports are the same
            ones used for the actual MCAT exam. They show how your scores
            compare to the scores of everyone who sat for the actual MCAT exam.
            Click this icon for more information about interpreting your
            results.
          </Typography>
        }
      >
        <HelpIcon
          fontSize="small"
          style={{ fontSize: "1rem", marginLeft: 5, cursor: "pointer" }}
          onClick={onHelpOpen}
        />
      </Tooltip>
      <Dialog open={open}>
        <DialogTitle>Percentile Ranks</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The percentile ranks provided on your score reports are the same
            ones used for the actual MCAT exam. They show the percentages of
            examinees on the actual MCAT exam who received the same or lower
            score on the exam than your practice exam score. They show how your
            scores compare to the scores of everyone who sat for the actual MCAT
            exam. Every year on May 1, the percentile ranks are updated using
            data from the most recent three years. The percentile rank shown in
            your score report reflects the percentile rank in use when you began
            the attempt of the practice exam. For example, if you began an
            attempt on a practice exam in April 2019, your score report reflects
            the percentile rank updated in May 2018. If you begin a practice
            exam in June 2019, your percentile rank will reflect the new
            percentile rank, updated in May 2019. A more detailed description of
            MCAT percentile ranks can be found{" "}
            <Link
              rel="noopener noreferrer"
              target="_blank"
              href="https://students-residents.aamc.org/advisors/article/percentile-ranks-for-the-mcat-exam/"
            >
              here
            </Link>
            .
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onHelpClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
