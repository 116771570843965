import React, { FunctionComponent, useEffect } from "react";
import { secondsToTimeDisplay } from "../../utils/formatting";
import { onAltHotkey } from "../../utils/hotkeys";

export interface Properties {
  timed: boolean;
  timeRemaining: number;
  examTitle: string;
  candidateName: string;
  breakMessageContent: string;
}

export interface Actions {
  onResume: () => void;
}

type AllProperties = Properties & Actions;

export const Break: FunctionComponent<AllProperties> = ({
  timed,
  timeRemaining,
  examTitle,
  candidateName,
  breakMessageContent,
  onResume,
}) => {
  useEffect(() => {
    let hotkeyListeners = [onAltHotkey("r", onResume)];

    return () => hotkeyListeners.forEach((unsub) => unsub());
  });

  return (
    <div className="break-screen">
      <div className="break-info-container">
        <div className="break-header">Active Break</div>
        <div className="candidate-photo"></div>
        <fieldset className="break-information">
          <legend>Break Information</legend>
          <div className="info-line">Candidate Name: {candidateName}</div>
          <div className="info-line">{examTitle}</div>
          {timed && (
            <div className="info-line">
              Total Break Time Remaining: {secondsToTimeDisplay(timeRemaining)}
            </div>
          )}
          <div
            className="info-line content"
            dangerouslySetInnerHTML={{ __html: breakMessageContent }}
          ></div>
        </fieldset>
        <div className="action-button resume" onClick={onResume}>
          <span className="underline">R</span>
          <span>esume</span>
        </div>
      </div>
    </div>
  );
};
