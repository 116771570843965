import { connect } from "react-redux";
import State from "../../app/state";
import {
  Properties,
  ResultDetailCard,
} from "../../components/ResultDetailCard";

const mapStateToProps = (state: State): Properties => {
  return {
    description: "Attempt Date",
    value: new Date(
      Date.parse(state.overallResults.dateAttempted)
    ).toLocaleDateString(),
  };
};

export default connect(mapStateToProps)(ResultDetailCard);
