import { Box, Slide, Tab, Tabs } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import { connect } from "react-redux";
import { AppDispatch, SwitchToDetailTab } from "../../app/actions";
import State from "../../app/state";
import ContentAreaBarGraph from "../graphics/ContentAreaBarGraph";
import ItemsTable from "./ItemsTable";
import SkillGroup from "./SkillGroup";
import SkillsTable from "./SkillsTable";
import ForEachSkillGroupInSection from "../structural/ForEachSkillGroupInSection";
import ContentAnnotations from "./ContentAnnotations";
import ForEachPieceOfContentInSection from "../structural/ForEachPieceOfContentInSection";

interface OwnProps {
  sectionId: string;
}

interface Properties {
  currentDetailTab: number;
}

interface Actions {
  onDetailTabSwitch: (to: number) => void;
}

type AllProperties = OwnProps & Properties & Actions;

const SectionTabs: FunctionComponent<AllProperties> = ({
  sectionId,
  currentDetailTab,
  onDetailTabSwitch,
}) => {
  return (
    <>
      <Tabs
        value={currentDetailTab}
        onChange={(_, value) => onDetailTabSwitch(value)}
      >
        <Tab label="Question Review" />
        <Tab label="Highlights" />
        <Tab label="Content & Skills Breakdown" />
      </Tabs>
      {currentDetailTab === 0 && (
        <Slide direction="up" in={true} timeout={500}>
          <Box>
            <ItemsTable sectionId={sectionId} />
          </Box>
        </Slide>
      )}
      {currentDetailTab === 1 && (
        <Slide direction="up" in={true} timeout={500}>
          <Box>
            <ForEachPieceOfContentInSection sectionId={sectionId}>
              {(contentId, itemId, label) => (
                <ContentAnnotations
                  sectionId={sectionId}
                  contentId={contentId}
                  itemId={itemId}
                  label={label}
                />
              )}
            </ForEachPieceOfContentInSection>
          </Box>
        </Slide>
      )}
      {currentDetailTab === 2 && (
        <Slide direction="up" in={true} timeout={500}>
          <Box className="vertical-stack skill-group">
            <ForEachSkillGroupInSection sectionId={sectionId}>
              {(groupId) => (
                <SkillGroup groupId={groupId} sectionId={sectionId}>
                  <Box className="vertical-stack even graph-container">
                    <ContentAreaBarGraph
                      sectionId={sectionId}
                      groupId={groupId}
                    />
                  </Box>
                  <SkillsTable sectionId={sectionId} groupId={groupId} />
                </SkillGroup>
              )}
            </ForEachSkillGroupInSection>
          </Box>
        </Slide>
      )}
    </>
  );
};

const mapStateToProps = (state: State): Properties => {
  return {
    currentDetailTab: state.navigation.detailTab,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch): Actions => {
  return {
    onDetailTabSwitch: (index: number) => dispatch(SwitchToDetailTab(index)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SectionTabs);
