import {
  Avatar,
  Box,
  Button,
  MenuItem,
  MenuList,
  Popover,
  Typography,
} from "@material-ui/core";
import React, { useRef, FunctionComponent } from "react";
import GoogleButton from "react-google-button";
import GoogleLogin, {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
  GoogleLogout,
} from "react-google-login";

export interface Properties {
  googleClientId: string;
  loggedIn: boolean;
  provider: string;
  email: string;
  profilePic: string;
  profileMenuOpen: boolean;
}

export interface Actions {
  onLogin: (
    provider: string,
    email: string,
    profilePic: string,
    idToken: string
  ) => void;
  onLoginFailure: (error: any) => void;
  onLogout: () => void;
  onToggleProfileMenuOpen: () => void;
}

type AllProperties = Properties & Actions;

export const User: FunctionComponent<AllProperties> = ({
  loggedIn,
  provider,
  googleClientId,
  email,
  profilePic,
  profileMenuOpen,
  onLogin,
  onLoginFailure,
  onLogout,
  onToggleProfileMenuOpen,
}) => {
  let buttonRef = useRef(null);

  const onGoogleLogin = (
    success: GoogleLoginResponse | GoogleLoginResponseOffline
  ) => {
    success = success as GoogleLoginResponse;
    if (!success.googleId) return;

    onLogin(
      "google",
      success.profileObj.email,
      success.profileObj.imageUrl,
      success.tokenObj.id_token
    );
  };

  return (
    <Box className="user-button-and-menu">
      <Button onClick={onToggleProfileMenuOpen} ref={buttonRef}>
        <Avatar className="avatar" src={loggedIn ? profilePic : ""} />
        <Typography className="email">{loggedIn ? email : "Login"}</Typography>
      </Button>
      <Popover
        open={profileMenuOpen}
        onClose={onToggleProfileMenuOpen}
        anchorEl={buttonRef.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        keepMounted={true}
      >
        <MenuList>
          {!loggedIn && (
            <MenuItem className="no-hover-highlight">
              <GoogleLogin
                clientId={googleClientId}
                onSuccess={onGoogleLogin}
                onFailure={onLoginFailure}
                cookiePolicy={"single_host_origin"}
                isSignedIn={true}
                render={(renderProps) => (
                  <GoogleButton
                    type="light"
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                  >
                    Sign in with Google
                  </GoogleButton>
                )}
              />
            </MenuItem>
          )}
          {loggedIn && (
            <MenuItem>
              {provider === "google" && (
                <GoogleLogout
                  clientId={googleClientId}
                  onLogoutSuccess={onLogout}
                  render={(renderProps) => (
                    <Button onClick={renderProps.onClick}>Logout</Button>
                  )}
                />
              )}
            </MenuItem>
          )}
        </MenuList>
      </Popover>
    </Box>
  );
};
