import { connect } from "react-redux";
import State from "../../app/state";
import { InfoContainer, Properties } from "../../components/InfoContainer";

const mapStateToProps = (state: State): Properties => {
  let items = state.itemReview.itemsBeingReviewed;
  let itemId = items[state.itemReview.itemReviewIndex];
  let response = state.itemState[itemId]?.response;
  let skills = Object.keys(state.skills.skills)
    .map((key) => [...Object.values(state.skills.skills[key])])
    .flat();
  return {
    answer: response,
    correctAnswer: state.itemContent.items[itemId].correctResponse,
    correct: state.itemState[itemId]?.isCorrect,
    timeInSeconds: state.itemState[itemId]?.timeInSeconds,
    skills: state.itemContent.items[itemId].skills.map((skillId) => ({
      label: skills.find((skl) => skl.id === skillId)?.label || "",
      description: skills.find((skl) => skl.id === skillId)?.description || "",
    })),
    itemId,
  };
};

export default connect(mapStateToProps)(InfoContainer);
