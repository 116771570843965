import { connect } from "react-redux";
import {
  AppDispatch,
  CombineActions,
  ItemNavigation,
  NavigationColumnClicked,
  SaveSectionState,
} from "../app/actions";
import State from "../app/state";
import {
  Actions,
  NavigationItemList,
  Properties,
} from "../components/NavigationItemList";

const mapStateToProps = (state: State): Properties => {
  let items = state.navigation.itemIdentifiers.map((id, index) => ({
    id,
    number: index + 1,
    flagged: state.navigation.itemsFlaggedForReview.indexOf(id) >= 0,
    seen:
      [
        ...state.navigation.itemsPreviouslyPresented,
        state.navigation.itemIdentifiers[state.navigation.currentItemIndex],
      ].indexOf(id) >= 0,
    complete: state.navigation.itemsCompleted.indexOf(id) > -1,
  }));

  return {
    sortOn: state.navigationItemList.sortOn,
    sortDir: state.navigationItemList.sortDir,
    itemsSnapshot: items,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch): Actions => {
  return {
    onItemNavigation: (id: string) =>
      dispatch(CombineActions(ItemNavigation(id), SaveSectionState())),
    onColumnClicked: (column: string) =>
      dispatch(NavigationColumnClicked(column)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavigationItemList);
