export type DialogState = {
  open: boolean;

  centerOnOpen: boolean;
  resetSizeOnOpen: boolean;

  defaultTop: number;
  defaultLeft: number;
  defaultWidth: number;
  defaultHeight: number;

  top: number;
  left: number;
  width: number;
  height: number;

  topBoundary?: number;
  leftBoundary?: number;
  bottomBoundary?: number;
  rightBoundary?: number;

  minHeight?: number;
  minWidth?: number;
  maxHeight?: number;
  maxWidth?: number;

  isMoving: boolean;
  isResizing: boolean;

  dismissOnNavigation: boolean;
};

type State = Record<string, DialogState>;

export const NavigationDialog = "navigation";
export const PeriodicTableDialog = "periodic-table";
export const EndSectionIncompleteWarningDialog =
  "end-section-incomplete-warning";
export const TimeoutWarningDialog = "timeout-warning";
export const ItemResponseRequiredDialog = "item-response-required";

export const Default: State = {
  [NavigationDialog]: {
    open: false,
    top: window.innerHeight * 0.2,
    left: window.innerWidth * 0.15,
    width: window.innerWidth * 0.7,
    height: window.innerHeight * 0.6,
    leftBoundary: -250,
    topBoundary: -250,
    rightBoundary: window.innerWidth,
    bottomBoundary: window.innerHeight,
    centerOnOpen: true,
    resetSizeOnOpen: false,
    defaultTop: window.innerHeight * 0.2,
    defaultLeft: window.innerWidth * 0.15,
    defaultWidth: window.innerWidth * 0.7,
    defaultHeight: window.innerHeight * 0.6,
    isMoving: false,
    isResizing: false,
    minWidth: 550,
    minHeight: 185,
    maxWidth: 1000,
    maxHeight: 500,
    dismissOnNavigation: true,
  },
  [PeriodicTableDialog]: {
    open: false,
    top: 0,
    left: 0,
    width: 0,
    height: 0,
    leftBoundary: -250,
    topBoundary: -250,
    rightBoundary: window.innerWidth,
    bottomBoundary: window.innerHeight,
    centerOnOpen: true,
    resetSizeOnOpen: true,
    defaultTop: 0,
    defaultLeft: 0,
    defaultWidth: 1029,
    defaultHeight: 773,
    isMoving: false,
    isResizing: false,
    minWidth: 200,
    minHeight: 200,
    dismissOnNavigation: true,
  },
  [EndSectionIncompleteWarningDialog]: {
    open: false,
    top: 0,
    left: 0,
    width: 0,
    height: 0,
    leftBoundary: -250,
    topBoundary: -250,
    rightBoundary: window.innerWidth,
    bottomBoundary: window.innerHeight,
    centerOnOpen: true,
    resetSizeOnOpen: true,
    defaultTop: 0,
    defaultLeft: 0,
    defaultWidth: 700,
    defaultHeight: 230,
    isMoving: false,
    isResizing: false,
    minWidth: 700,
    minHeight: 230,
    dismissOnNavigation: true,
  },
  [TimeoutWarningDialog]: {
    open: false,
    top: 0,
    left: 0,
    width: 0,
    height: 0,
    leftBoundary: -250,
    topBoundary: -250,
    rightBoundary: window.innerWidth,
    bottomBoundary: window.innerHeight,
    centerOnOpen: true,
    resetSizeOnOpen: true,
    defaultTop: 0,
    defaultLeft: 0,
    defaultWidth: 700,
    defaultHeight: 270,
    isMoving: false,
    isResizing: false,
    minWidth: 700,
    minHeight: 270,
    dismissOnNavigation: true,
  },
  [ItemResponseRequiredDialog]: {
    open: false,
    top: 0,
    left: 0,
    width: 0,
    height: 0,
    leftBoundary: -250,
    topBoundary: -250,
    rightBoundary: window.innerWidth,
    bottomBoundary: window.innerHeight,
    centerOnOpen: true,
    resetSizeOnOpen: true,
    defaultTop: 0,
    defaultLeft: 0,
    defaultWidth: 700,
    defaultHeight: 150,
    isMoving: false,
    isResizing: false,
    minWidth: 700,
    minHeight: 150,
    dismissOnNavigation: true,
  },
};

export const DefaultDialog: DialogState = {
  open: false,
  top: window.innerHeight * 0.2,
  left: window.innerWidth * 0.15,
  width: window.innerWidth * 0.7,
  height: window.innerHeight * 0.6,
  leftBoundary: -250,
  topBoundary: -250,
  rightBoundary: window.innerWidth,
  bottomBoundary: window.innerHeight,
  centerOnOpen: true,
  resetSizeOnOpen: false,
  defaultTop: window.innerHeight * 0.2,
  defaultLeft: window.innerWidth * 0.15,
  defaultWidth: window.innerWidth * 0.7,
  defaultHeight: window.innerHeight * 0.6,
  isMoving: false,
  isResizing: false,
  minWidth: 550,
  minHeight: 185,
  maxWidth: 1000,
  maxHeight: 500,
  dismissOnNavigation: true,
};

export default State;
