import { Action } from 'redux';

import { uploadAssets } from '../api/assets';
import {
  getContentDefs,
  uploadContentDefs,
} from '../api/contentdefinition';
import {
  getSkills,
  uploadSkills,
} from '../api/skill';
import {
  getSkillGroups,
  uploadSkillGroups,
} from '../api/skillgroup';
import {
  AppThunk,
  CHAGNE_MANAGE_CONTENT_TAB,
  CHANGE_CONTENT_QUERY_PARAMETERS,
  ContentQueryFetchedAction,
  LOAD_MANAGE_CONTENT_QUERY_RESULT,
  LoadQueryResult,
  ManageContentTabChangeAction,
  QueryParametersUpdateAction,
  SelectUploadFileAction,
  TOGGLE_SHOW_UPLOAD_CONTENT_FORMAT,
  UPLOAD_CONTENT_FILE_FAILED,
  UPLOAD_CONTENT_FILE_SELECTED,
  UPLOAD_CONTENT_FILE_STARTED,
  UPLOAD_CONTENT_FILE_SUCCESS,
} from '../app/actions';
import { UnrecoverableException } from '../utils/AppError';
import State, {
  AssetsTab,
  ContentDefinitionsTab,
  DefaultState,
  SkillGroupsTab,
  SkillsTab,
} from './state';

const reducer = (state: State, action: Action): State => {
  if (!state) return DefaultState;
  switch (action.type) {
    case TOGGLE_SHOW_UPLOAD_CONTENT_FORMAT:
      return { ...state, showFormat: !state.showFormat };
    case CHAGNE_MANAGE_CONTENT_TAB:
      return {
        ...state,
        tab: (action as ManageContentTabChangeAction).tab,
        queryResults: undefined,
        fileBeingUploaded: undefined,
        uploadProcessing: undefined,
        uploadSuccessful: undefined,
        uploadFailed: undefined,
      };
    case LOAD_MANAGE_CONTENT_QUERY_RESULT:
      if ((action as ContentQueryFetchedAction).tab === state.tab)
        return {
          ...state,
          queryResults: (action as ContentQueryFetchedAction).queryResult,
        };
      return state;
    case CHANGE_CONTENT_QUERY_PARAMETERS:
      let skip = (action as QueryParametersUpdateAction).skip;
      let take = (action as QueryParametersUpdateAction).take;
      return {
        ...state,
        queryResults: Object.assign({}, state.queryResults, { skip, take }),
      };
    case UPLOAD_CONTENT_FILE_SELECTED:
      return {
        ...state,
        fileBeingUploaded: (action as SelectUploadFileAction).file,
      };
    case UPLOAD_CONTENT_FILE_STARTED:
      return {
        ...state,
        uploadProcessing: true,
        uploadSuccessful: false,
        uploadFailed: false,
      };
    case UPLOAD_CONTENT_FILE_SUCCESS:
      return {
        ...state,
        fileBeingUploaded: undefined,
        uploadProcessing: false,
        uploadSuccessful: true,
        uploadFailed: false,
      };
    case UPLOAD_CONTENT_FILE_FAILED:
      return {
        ...state,
        fileBeingUploaded: undefined,
        uploadProcessing: false,
        uploadSuccessful: false,
        uploadFailed: true,
      };
    default:
      return state;
  }
};

export default reducer;

export const fetchContentQuery = (): AppThunk => async (dispatch, getState) => {
  try {
    let state = getState().manageContent;
    let response;
    switch (state.tab) {
      case ContentDefinitionsTab:
        response = await getContentDefs(
          state.queryResults?.take || 10,
          state.queryResults?.skip || 0
        );
        break;
      case SkillsTab:
        response = await getSkills(
          state.queryResults?.take || 10,
          state.queryResults?.skip || 0
        );
        break;
      case SkillGroupsTab:
        response = await getSkillGroups(
          state.queryResults?.take || 10,
          state.queryResults?.skip || 0
        );
        break;
    }
    if (response) dispatch(LoadQueryResult(response, state.tab));
  } catch (error) {
    dispatch(UnrecoverableException(error as Error));
  }
};

export const beginContentFileUpload =
  (): AppThunk => async (dispatch, getState) => {
    try {
      let state = getState().manageContent;
      let file = state.fileBeingUploaded;
      if (!file) return;

      let upload;
      switch (state.tab) {
        case ContentDefinitionsTab:
          upload = uploadContentDefs(file);
          break;
        case SkillsTab:
          upload = uploadSkills(file);
          break;
        case SkillGroupsTab:
          upload = uploadSkillGroups(file);
          break;
        case AssetsTab:
          upload = uploadAssets(file);
          break;
      }
      dispatch({ type: UPLOAD_CONTENT_FILE_STARTED });
      await upload;
      dispatch({ type: UPLOAD_CONTENT_FILE_SUCCESS });

      dispatch(fetchContentQuery());
    } catch (error) {
      dispatch({ type: UPLOAD_CONTENT_FILE_FAILED });
    }
  };
