import { connect } from "react-redux";
import {
  AppDispatch,
  ChainActions,
  END_SECTION,
  SaveExamState,
  SaveSectionState,
} from "../app/actions";
import State from "../app/state";
import { Actions, Break, Properties } from "../components/Break";

const mapStateToProps = (state: State): Properties => {
  return {
    timed: state.timing.timed,
    timeRemaining: state.timing.timeRemaining,
    examTitle: state.exam.title,
    candidateName: state.exam.candidateName,
    breakMessageContent: state.section.breakMessageContent || "",
  };
};

const mapDispatchToProps = (dispatch: AppDispatch): Actions => {
  return {
    onResume: () =>
      dispatch(
        ChainActions(SaveSectionState(), { type: END_SECTION }, SaveExamState())
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Break);
