type State = {
  loaded: boolean;
  loading: boolean;
  location: string;
  detailTab: number;
};

export default State;

export const DefaultState: State = {
  loaded: false,
  loading: false,
  location: "",
  detailTab: 0,
};
