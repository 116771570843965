import React, {
  FunctionComponent,
  useEffect,
} from 'react';

import { onAltHotkey } from '../../utils/hotkeys';

export interface Properties {
  id: string;
  loadedId: string;
  optional: boolean;
  optionalMessageContent: string;
}

export interface Actions {
  loadSection: (id: string) => void;
  onDeliverOptionalSection: () => void;
  onSkipOptionalSection: () => void;
}

type AllProperties = Properties & Actions;

export const Section: FunctionComponent<AllProperties> = ({
  id,
  loadedId,
  children,
  optional,
  optionalMessageContent,
  loadSection,
  onDeliverOptionalSection,
  onSkipOptionalSection,
}) => {
  useEffect(() => {
    if (id && id !== loadedId) loadSection(id);

    let hotkeyListeners = [
      onAltHotkey("y", onDeliverOptionalSection, optional),
      onAltHotkey("n", onSkipOptionalSection, optional),
    ];

    return () => hotkeyListeners.forEach((unsub) => unsub());
  }, [
    id,
    loadedId,
    onDeliverOptionalSection,
    optional,
    onSkipOptionalSection,
    loadSection,
  ]);

  if (!id) return <></>;

  if (optional)
    return (
      <div className="optional-section-decision">
        <div className="decision-container">
          <div className="decision-container-header"></div>
          <div className="decision-message">
            <div className="decision-message-icon"></div>
            <div
              className="decision-message-content"
              dangerouslySetInnerHTML={{ __html: optionalMessageContent }}
            ></div>
          </div>
          <div className="action-buttons-wrapper">
            <div className="action-buttons">
              <span
                className="action-button"
                onClick={onDeliverOptionalSection}
              >
                <span className="underline">Y</span>es
              </span>
              <span className="action-button" onClick={onSkipOptionalSection}>
                <span className="underline">N</span>o
              </span>
            </div>
          </div>
        </div>
      </div>
    );

  return <>{children}</>;
};
