import React, { FunctionComponent, useEffect } from "react";
import { onAltHotkey } from "../../utils/hotkeys";

export interface Properties {
  incomplete: number;
}

export interface Actions {
  onNoSelected: () => void;
  onYesSelected: () => void;
}

type AllProperties = Properties & Actions;

export const EndIncompleteSection: FunctionComponent<AllProperties> = ({
  incomplete,
  onNoSelected,
  onYesSelected,
}) => {
  useEffect(() => {
    let hotkeyListeners = [
      onAltHotkey("y", onYesSelected),
      onAltHotkey("n", onNoSelected),
    ];

    return () => hotkeyListeners.forEach((unsub) => unsub());
  });

  return (
    <div className="end-incomplete-section">
      <div className="warning-content">
        <div className="icon"></div>
        <div className="message">
          <p>
            You have chosen to end this exam section, but you have {incomplete}
            &nbsp; incomplete question{incomplete > 1 ? "s" : ""}. Select “Yes”
            to confirm that you wish to end this exam section, or “No” to return
            to the Section Review.
          </p>
          <p>
            If you select “Yes”, you will NOT be able to return to this section.
          </p>
        </div>
      </div>
      <div className="action-buttons-wrapper">
        <div className="action-buttons">
          <span className="action-button link-like" onClick={onYesSelected}>
            <span className="underline">Y</span>es
          </span>
          <span className="action-button link-like" onClick={onNoSelected}>
            <span className="underline">N</span>o
          </span>
        </div>
      </div>
    </div>
  );
};
