import React, { FunctionComponent } from "react";

export interface Properties {
  canPause: boolean;
}

export interface Actions {
  onPause: () => void;
}

type AllProperties = Properties & Actions;

export const Pause: FunctionComponent<AllProperties> = ({
  canPause,
  onPause,
}) => {
  return (
    <>
      {canPause && (
        <div className="pause-bar">
          <span className="pause-link" onClick={onPause}>
            Pause
          </span>
        </div>
      )}
    </>
  );
};
