import React from 'react';
import ReactDOM from 'react-dom';

import apiSettings from './api/settings';
import App from './App';
import { initializeStripe } from './utils/stripe';

fetch("config.json")
  .then((resp) => resp.json())
  .then((config: Configuration) => {
    apiSettings.baseAddress = config.baseAddress;
    apiSettings.googleOauthClientId = config.googleOauthClientId;
    initializeStripe(config.stripePublishableKey);
  })
  .then(() => {
    ReactDOM.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>,
      document.getElementById("root")
    );
  });

interface Configuration {
  baseAddress: string;
  googleOauthClientId: string;
  stripePublishableKey: string;
}
