type State = {
  canPause: boolean;

  next: boolean;
  previous: boolean;
  navigation: boolean;
  endSection: boolean;
  endExam: boolean;
  reviewAll: boolean;
  reviewIncomplete: boolean;
  reviewFlagged: boolean;
  reviewScreen: boolean;
  periodicTable: boolean;

  currentItemIndex: number;
  itemIdentifiers: Array<string>;

  itemsCompleted: Array<string>;
  itemsPreviouslyPresented: Array<string>;
  itemsFlaggedForReview: Array<string>;

  reviewable: boolean;
  reviewMode: boolean;
  onReviewScreen: boolean;
  suppressPeriodicTable: boolean;

  reviewSummaryExpanded: boolean;
  reviewItemsExpanded: boolean;

  currentItemIndexInSubset?: number;
  itemIdentifiersReviewSubset?: Array<string>;

  warnOnIncompleteSubmission: boolean;

  responsesRequiredFor: Array<string>;

  timeSpentInSeconds: Record<string, number>;
};

export const Default: State = {
  canPause: false,
  next: false,
  previous: false,
  navigation: false,
  endExam: false,
  endSection: false,
  periodicTable: false,
  reviewAll: false,
  reviewFlagged: false,
  reviewIncomplete: false,
  reviewScreen: false,
  currentItemIndex: 0,
  itemIdentifiers: [],
  itemsCompleted: [],
  itemsPreviouslyPresented: [],
  itemsFlaggedForReview: [],
  reviewable: false,
  reviewMode: false,
  onReviewScreen: false,
  suppressPeriodicTable: false,
  reviewSummaryExpanded: false,
  reviewItemsExpanded: false,
  warnOnIncompleteSubmission: false,
  responsesRequiredFor: [],
  timeSpentInSeconds: {},
};

export default State;
