import { Action, Reducer } from "redux";
import {
  BEGIN_LOAD_EXAM,
  DialogAction,
  DIALOG_CLOSE,
  PartialStateAction,
  SECTION_LOADED,
} from "../app/actions";
import { TimeoutWarningDialog } from "../Dialog/state";
import { TICK } from "../../utils/AppTimer";
import State, { Default } from "./state";

const onTimerTick = (state: State): State => {
  let newTime = state.timeRemaining - 1;
  let timeSpent = state.timeSpentInSeconds + 1;
  let warning = state.timeoutWarnings.find((w) => w.atTime === newTime);

  if (!warning || state.warningDisplayed)
    return { ...state, timeRemaining: newTime, timeSpentInSeconds: timeSpent };

  return {
    ...state,
    timeRemaining: newTime,
    timeSpentInSeconds: timeSpent,
    warningDisplayed: !!warning,
    currentWarningMessage: warning?.warningMessage || "",
    currentWarningTitle: warning?.warningTitle || "",
  };
};

const onWarningClosed = (state: State): State => {
  return {
    ...state,
    warningDisplayed: false,
  };
};

const reducer: Reducer<State, Action> = (state, action) => {
  if (!state) return Default;

  switch (action.type) {
    case BEGIN_LOAD_EXAM:
      return Default;
    case SECTION_LOADED:
      let newState = (action as PartialStateAction).state.timing;
      if (newState) return { ...newState };
      return state;
    case TICK:
      return onTimerTick(state);
    case DIALOG_CLOSE:
      if ((action as DialogAction).dialog === TimeoutWarningDialog)
        return onWarningClosed(state);
      return state;
    default:
      return state;
  }
};

export default reducer;
