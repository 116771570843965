import { FunctionComponent, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router";
import { AppDispatch, UpdateLocationToMatchReport } from "../app/actions";
import State from "../app/state";
import { MyPurchasesRoute } from "../Navigation/state";
import ScoreReportApp from "../ScoreReportApp/App";

interface NavParams {
  id: string;
}

interface Properties {
  loggedIn: boolean;
}

interface Actions {
  updateLocationInState: (id: string) => void;
}

type AllProperties = Properties & Actions;

const Report: FunctionComponent<AllProperties> = ({
  loggedIn,
  updateLocationInState,
}) => {
  let { id } = useParams<NavParams>();
  let history = useHistory();
  useEffect(() => {
    if (!loggedIn) history.push("/");
    else updateLocationInState(id);
  }, [history, id, loggedIn, updateLocationInState]);

  if (!loggedIn) return <></>;

  return (
    <ScoreReportApp
      reportId={id!}
      onReturn={() => history.push(MyPurchasesRoute)}
    />
  );
};

const mapStateToProps = (state: State): Properties => {
  return {
    loggedIn: state.user.loggedIn,
  };
};

const mapDispatchToActions = (dispatch: AppDispatch): Actions => {
  return {
    updateLocationInState: (id: string) =>
      dispatch(UpdateLocationToMatchReport(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToActions)(Report);
