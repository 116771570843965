import { connect } from "react-redux";
import FlagIcon from "@material-ui/icons/Flag";

import State from "../../app/state";
import {
  Properties,
  ResultDetailCard,
} from "../../components/ResultDetailCard";

interface OwnProps {
  sectionId: string;
}

const mapStateToProps = (state: State, ownProps: OwnProps): Properties => {
  let section = state.sectionResults[ownProps.sectionId];
  return {
    description: (
      <>
        <FlagIcon /> Correct
      </>
    ),
    value: section.flaggedCorrect.toString(),
  };
};

export default connect(mapStateToProps)(ResultDetailCard);
