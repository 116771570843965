import { connect } from "react-redux";
import State from "../../app/state";
import PercentileRankHelp from "../PercentileRanksHelp";
import {
  Properties,
  ResultDetailCard,
} from "../../components/ResultDetailCard";

interface OwnProps {
  sectionId?: string;
}

const mapStateToProps = (state: State, ownProps: OwnProps): Properties => {
  let section = state.sectionResults[ownProps.sectionId || ""];
  if (!section)
    return {
      description: "Percentile Rank",
      value: `${Math.max(0, state.overallResults.percent)}%`,
      help: <PercentileRankHelp />,
    };

  return {
    description: "Percentile Rank",
    value: `${section.percentage}%`,
    help: <PercentileRankHelp />,
  };
};

export default connect(mapStateToProps)(ResultDetailCard);
