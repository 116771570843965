import React, {
  FunctionComponent,
  useEffect,
  useRef,
} from 'react';

import {
  Box,
  Slide,
  Typography,
} from '@material-ui/core';

export interface Properties {
  itemId: string;
  correct: boolean;
  contentElement?: Node;
  correctAnswer: string;
  answerRationales: Array<string>;
  direction?: string;
}

export const ContentContainer: FunctionComponent<Properties> = ({
  itemId,
  correct,
  contentElement,
  correctAnswer,
  answerRationales = [],
  direction,
}) => {
  const refElement = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (contentElement === undefined) return;
    refElement.current?.replaceChildren(contentElement);
  }, [contentElement]);

  return (
    <Box className={`item-container ${correct ? "correct" : "incorrect"}`}>
      <Slide
        direction={
          direction === "forward"
            ? "left"
            : direction === "backward"
            ? "right"
            : "down"
        }
        in={true}
        timeout={450}
        key={itemId}
      >
        <Box>
          <div ref={refElement} className="content-container"></div>
          {!!correctAnswer && (
            <Box
              className={`rationale-container ${
                correct ? "correct" : "incorrect"
              }`}
            >
              <Typography>
                <b>Solution:</b> The correct answer is <b>{correctAnswer}</b>.
              </Typography>
              {answerRationales.length > 0 && (
                <ol type="A">
                  {answerRationales.map((rationale, index) => (
                    <li
                      key={index}
                      className={
                        correctAnswer.charCodeAt(0) ===
                        "A".charCodeAt(0) + index
                          ? "bolded-rationale"
                          : ""
                      }
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: rationale || "",
                        }}
                      ></div>
                    </li>
                  ))}
                </ol>
              )}
            </Box>
          )}
        </Box>
      </Slide>
    </Box>
  );
};
