import { Action } from 'redux';

import {
  extractExamDef,
  getExamDefs,
  uploadExamDef,
} from '../api/examdefinition';
import {
  AppThunk,
  CHANGE_MANAGE_EXAMS_QUERY_PARAMETERS,
  EXAM_DEF_DRAWER_TOGGLE,
  EXAM_DEF_EXTRACTED,
  EXAM_DEFS_LOADED,
  ExamDefExtracted,
  ExamDefExtractedAction,
  ExamDefsLoaded,
  ExamDefsLoadedAction,
  QueryParametersUpdateAction,
  SelectUploadFileAction,
  UPDATE_LOCATION_STATE,
  UPLOAD_EXAM_FILE_FAILED,
  UPLOAD_EXAM_FILE_SELECTED,
  UPLOAD_EXAM_FILE_STARTED,
  UPLOAD_EXAM_FILE_SUCCESS,
} from '../app/actions';
import { UnrecoverableException } from '../utils/AppError';
import State, { DefaultState } from './state';

const reducer = (state: State, action: Action): State => {
  if (!state) return DefaultState;
  switch (action.type) {
    case UPDATE_LOCATION_STATE:
      return { ...state, extractedDef: "" };
    case EXAM_DEFS_LOADED:
      return { ...state, defs: (action as ExamDefsLoadedAction).queryResults };
    case EXAM_DEF_DRAWER_TOGGLE:
      return { ...state, defJsonDrawerOpen: !state.defJsonDrawerOpen };
    case EXAM_DEF_EXTRACTED:
      return {
        ...state,
        extractedDef: (action as ExamDefExtractedAction).json,
        defJsonDrawerOpen: true,
      };
    case UPLOAD_EXAM_FILE_SELECTED:
      return {
        ...state,
        fileBeingUploaded: (action as SelectUploadFileAction).file,
      };
    case UPLOAD_EXAM_FILE_STARTED:
      return {
        ...state,
        uploadProcessing: true,
        uploadSuccessful: false,
        uploadFailed: false,
      };
    case UPLOAD_EXAM_FILE_SUCCESS:
      return {
        ...state,
        fileBeingUploaded: undefined,
        uploadProcessing: false,
        uploadSuccessful: true,
        uploadFailed: false,
      };
    case UPLOAD_EXAM_FILE_FAILED:
      return {
        ...state,
        fileBeingUploaded: undefined,
        uploadProcessing: false,
        uploadSuccessful: false,
        uploadFailed: true,
      };
    case CHANGE_MANAGE_EXAMS_QUERY_PARAMETERS:
      let skip = (action as QueryParametersUpdateAction).skip;
      let take = (action as QueryParametersUpdateAction).take;
      return {
        ...state,
        defs: Object.assign({}, state.defs, { skip, take }),
      };
    default:
      return state;
  }
};

export default reducer;

export const LoadExamDefinitions =
  (): AppThunk => async (dispatch, getState) => {
    try {
      let state = getState().manageExams;
      let defs = await getExamDefs(state.defs.skip, state.defs.take);
      dispatch(ExamDefsLoaded(defs));
    } catch (error) {
      dispatch(UnrecoverableException(error as Error));
    }
  };

export const ExtractExamDefinition =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      let json = await extractExamDef(id);
      dispatch(ExamDefExtracted(id, json));
    } catch (error) {
      dispatch(UnrecoverableException(error as Error));
    }
  };

export const BeginExamFileUpload =
  (): AppThunk => async (dispatch, getState) => {
    try {
      let state = getState();
      let file = state.manageExams.fileBeingUploaded;
      if (!file) return;
      let upload = uploadExamDef(file);
      dispatch({ type: UPLOAD_EXAM_FILE_STARTED });
      await upload;
      dispatch({ type: UPLOAD_EXAM_FILE_SUCCESS });
      dispatch(LoadExamDefinitions());
    } catch (error) {
      dispatch({ type: UPLOAD_EXAM_FILE_FAILED });
    }
  };
