import { Action } from 'redux';

import { loginUser } from '../api/login';
import {
  AppThunk,
  LoginAction,
  USER_LOGIN,
  USER_LOGIN_PROCESSED,
  USER_LOGOUT,
  USER_PROFILE_MENU_TOGGLE,
  UserLoginProcessed,
  UserLoginProcessedAction,
} from '../app/actions';
import { UnrecoverableException } from '../utils/AppError';
import State, {
  DefaultState,
  LoginResult,
} from './state';

const onLoginProcessed = (state: State, result: LoginResult): State => {
  return { ...state, isAdmin: result.isAdmin };
};

const reducer = (state: State, action: Action): State => {
  if (!state) return DefaultState;
  switch (action.type) {
    case USER_LOGIN:
      let { provider, email, profilePic, idToken } = action as LoginAction;
      return {
        ...state,
        profileMenuOpen: false,
        loggedIn: true,
        identityProvider: provider,
        email,
        profilePic,
        idToken,
        isAdmin: false,
      };
    case USER_LOGIN_PROCESSED:
      return onLoginProcessed(
        state,
        (action as UserLoginProcessedAction).result
      );
    case USER_LOGOUT:
      return {
        ...state,
        loggedIn: false,
        isAdmin: false,
        email: undefined,
        profilePic: undefined,
        idToken: undefined,
      };
    case USER_PROFILE_MENU_TOGGLE:
      return { ...state, profileMenuOpen: !state.profileMenuOpen };
    default:
      return state;
  }
};

export default reducer;

export const ValidateUserLogin = (): AppThunk => async (dispatch, getState) => {
  try {
    let state = getState().user;
    if (!state.idToken || !state.identityProvider) return;

    let userLogin = await loginUser(state.identityProvider, state.idToken);
    dispatch(UserLoginProcessed(userLogin));
  } catch (error) {
    dispatch(UnrecoverableException(error as Error));
  }
};
