import React, { FunctionComponent } from "react";
import { connect } from "react-redux";
import State from "../../app/state";

interface OwnProps {
  children: React.ReactNode;
}

interface Properties {
  currentLocation: string;
}

type AllProperties = OwnProps & Properties;

const IfLocationMatches: FunctionComponent<AllProperties> = ({
  currentLocation,
  children,
}) => {
  if (currentLocation === "") return <>{children}</>;
  return <></>;
};

const mapStateToProps = (state: State): Properties => {
  return {
    currentLocation: state.navigation.location,
  };
};

export default connect(mapStateToProps)(IfLocationMatches);
