import {
  Action,
  Reducer,
} from 'redux';

import { getExam } from '../../api/exam';
import { UnrecoverableException } from '../../utils/AppError';
import {
  AppThunk,
  BEGIN_END_SECTION,
  BEGIN_LOAD_EXAM,
  BEGIN_SAVE_EXAM,
  END_SAVE_EXAM,
  END_SECTION,
  EXAM_LOADED,
  ExamLoaded,
  ITEM_RESPONSE_SELECTED,
  ItemResponseAction,
  PartialStateAction,
  PAUSE_EXAM,
  SECTION_LOADED,
} from '../app/actions';
import State, {
  Default,
  Path,
} from './state';

const isPathConditionalSatisfied = (state: State, path: Path): boolean => {
  let conditions = path.conditions;
  if (!conditions || conditions.length === 0) return true;

  let satisfied = conditions.filter(
    (c) => state.decisions[c.id] === c.response
  );

  if (path.anyConditionSufficient) return satisfied.length > 0;

  return satisfied.length === conditions.length;
};

const onEndSection = (state: State): State => {
  if (!state.currentSectionId) return state;
  let paths = state.sectionRouting[state.currentSectionId];
  let path = paths.find((p) => isPathConditionalSatisfied(state, p));
  return {
    ...state,
    currentSectionId: path?.destination || null,
    deliveredSections: [...state.deliveredSections, state.currentSectionId],
  };
};

const onDecision = (state: State, id: string, value: string): State => {
  return { ...state, decisions: { ...state.decisions, [id]: value } };
};

const reducer: Reducer<State, Action> = (state, action) => {
  if (!state) return Default;
  switch (action.type) {
    case BEGIN_END_SECTION:
      return { ...state, sectionNavigationInProgress: true };
    case SECTION_LOADED:
      return { ...state, sectionNavigationInProgress: false };
    case BEGIN_SAVE_EXAM:
      return { ...state, loading: true };
    case END_SAVE_EXAM:
      return { ...state, loading: false };
    case BEGIN_LOAD_EXAM:
      return { ...Default, loading: true };
    case EXAM_LOADED:
      let newState = (action as PartialStateAction).state.exam;
      if (newState)
        return {
          ...newState,
          loaded: true,
          loading: false,
          lastLoaded: Date.now(),
        };
      return state;
    case END_SECTION:
      return onEndSection(state);
    case PAUSE_EXAM:
      return { ...state, id: "" };
    case ITEM_RESPONSE_SELECTED:
      if ((action as ItemResponseAction).isDecisionResponse)
        return onDecision(
          state,
          (action as ItemResponseAction).itemId,
          (action as ItemResponseAction).response
        );
      return state;
    default:
      return state;
  }
};

export default reducer;

export const fetchExam =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      let response = await getExam(id);
      dispatch(ExamLoaded({ exam: response }));
    } catch (error) {
      dispatch(UnrecoverableException(error as Error));
    }
  };
