import { Action } from 'redux';
import {
  ThunkAction,
  ThunkDispatch,
} from 'redux-thunk';

import { saveExam } from '../../api/exam';
import { saveSection } from '../../api/section';
import { UnrecoverableException } from '../../utils/AppError';
import State, { GetSectionStateToSave } from './state';

export const NEXT = "NEXT";
export const PREVIOUS = "PREVIOUS";
export const ITEM_NAVIGATION = "ITEM_NAVIGATION";
export const END_EXAM = "END_EXAM";
export const PAUSE_EXAM = "PAUSE_EXAM";
export const UNPAUSE_EXAM = "UNPAUSE_EXAM";
export const NAVIGATION_DIALOG_COLUMN_HEADER_CLICKED =
  "NAVIGATION_DIALOG_COLUMN_HEADER_CLICKED";
export const TIMER_CLICKED = "TIMER_CLICKED";
export const COUNTER_CLICKED = "COUNTER_CLICKED";
export const STRIKETHROUGH_CLICKED = "STRIKETHROUGH_CLICKED";
export const HIGHLIGHT_CLICKED = "HIGHLIGHT_CLICKED";
export const HIGHLIGHT_DROPDOWN_CLICKED = "HIGHLIGHT_DROPDOWN_CLICKED";
export const HIGHLIGHT_DROPDOWN_ROW_CLICKED = "HIGHLIGHT_DROPDOWN_ROW_CLICKED";
export const HIGHLIGHT_CLEAR_DROPDOWN_CLICKED =
  "CLEAR_HIGHLIGHT_DROPDOWN_CLICKED";
export const FLAG_FOR_REVIEW_CLICKED = "FLAG_FOR_REVIEW_CLICKED";
export const ITEM_RESPONSE_SELECTED = "ITEM_RESPONSE_SELECTED";
export const CONTENT_POINTER_DOWN = "CONTENT_POINTER_DOWN";
export const CONTENT_POINTER_UP = "CONTENT_POINTER_UP";
export const REVIEW_SUMMARY_COLLAPSE_TOGGLE = "REVIEW_SUMMARY_COLLAPSE_TOGGLE";
export const REVIEW_ITEMS_COLLAPSE_TOGGLE = "REVIEW_ITEMS_COLLAPSE_TOGGLE";
export const END_SECTION = "END_SECTION";
export const REVIEW_ALL = "REVIEW_ALL";
export const REVIEW_INCOMPLETE = "REVIEW_INCOMPLETE";
export const REVIEW_FLAGGED = "REVIEW_FLAGGED";
export const REVIEW_SCREEN = "REVIEW_SCREEN";
export const ALT_HOTKEY = "ALT_HOTKEY";
export const SECTION_LOADING = "SECTION_LOADING";
export const SECTION_LOADED = "SECTION_LOADED";
export const OPTIONAL_SECTION_DELIVERY_CONFIRMED =
  "OPTIONAL_SECTION_DELIVERY_CONFIRMED";
export const DISMISS_TIMEOUT_WARNING = "DISMISS_TIMEOUT_WARNING";
export const SECTION_SAVED = "SECTION_SAVED";
export const BEGIN_END_SECTION = "BEGIN_END_SECTION";
export const COMPLETE_END_SECTION = "COMPLETE_END_SECTION";
export const BEGIN_LOAD_EXAM = "BEGIN_LOAD_EXAM";
export const EXAM_LOADED = "EXAM_LOADED";
export const BEGIN_SAVE_EXAM = "BEGIN_SAVE_EXAM";
export const END_SAVE_EXAM = "END_SAVE_EXAM";
export const DIALOG_TOGGLE_OPEN = "DIALOG_TOGGLE_OPEN";
export const DIALOG_OPEN = "DIALOG_OPEN";
export const DIALOG_CLOSE = "DIALOG_CLOSE";
export const DIALOG_HEADER_MOUSE_DOWN = "DIALOG_HEADER_MOUSE_DOWN";
export const DIALOG_HEADER_MOUSE_UP = "DIALOG_HEADER_MOUSE_UP";
export const DIALOG_HEADER_MOUSE_MOVE = "DIALOG_HEADER_MOUSE_MOVE";
export const DIALOG_NORTH_RESIZE_MOUSE_DOWN = "DIALOG_NORTH_RESIZE_MOUSE_DOWN";
export const DIALOG_NORTH_RESIZE_MOUSE_UP = "DIALOG_NORTH_RESIZE_MOUSE_UP";
export const DIALOG_NORTH_RESIZE_MOUSE_MOVE = "DIALOG_NORTH_RESIZE_MOUSE_MOVE";
export const DIALOG_SOUTH_RESIZE_MOUSE_DOWN = "DIALOG_SOUTH_RESIZE_MOUSE_DOWN";
export const DIALOG_SOUTH_RESIZE_MOUSE_UP = "DIALOG_SOUTH_RESIZE_MOUSE_UP";
export const DIALOG_SOUTH_RESIZE_MOUSE_MOVE = "DIALOG_SOUTH_RESIZE_MOUSE_MOVE";
export const DIALOG_EAST_RESIZE_MOUSE_DOWN = "DIALOG_EAST_RESIZE_MOUSE_DOWN";
export const DIALOG_EAST_RESIZE_MOUSE_UP = "DIALOG_EAST_RESIZE_MOUSE_UP";
export const DIALOG_EAST_RESIZE_MOUSE_MOVE = "DIALOG_EAST_RESIZE_MOUSE_MOVE";
export const DIALOG_WEST_RESIZE_MOUSE_DOWN = "DIALOG_WEST_RESIZE_MOUSE_DOWN";
export const DIALOG_WEST_RESIZE_MOUSE_UP = "DIALOG_WEST_RESIZE_MOUSE_UP";
export const DIALOG_WEST_RESIZE_MOUSE_MOVE = "DIALOG_WEST_RESIZE_MOUSE_MOVE";
export const DIALOG_SOUTH_WEST_RESIZE_MOUSE_DOWN =
  "DIALOG_SOUTH_WEST_RESIZE_MOUSE_DOWN";
export const DIALOG_SOUTH_WEST_RESIZE_MOUSE_UP =
  "DIALOG_SOUTH_WEST_RESIZE_MOUSE_UP";
export const DIALOG_SOUTH_WEST_RESIZE_MOUSE_MOVE =
  "DIALOG_SOUTH_WEST_RESIZE_MOUSE_MOVE";
export const DIALOG_SOUTH_EAST_RESIZE_MOUSE_DOWN =
  "DIALOG_SOUTH_EAST_RESIZE_MOUSE_DOWN";
export const DIALOG_SOUTH_EAST_RESIZE_MOUSE_UP =
  "DIALOG_SOUTH_EAST_RESIZE_MOUSE_UP";
export const DIALOG_SOUTH_EAST_RESIZE_MOUSE_MOVE =
  "DIALOG_SOUTH_EAST_RESIZE_MOUSE_MOVE";

export interface ItemAction extends Action {
  itemId: string;
}

export interface ResponseAction extends Action {
  response: string;
  currentResponse: string;
  isDecisionResponse: boolean;
}

export interface ColumnAction extends Action {
  column: string;
}

export interface HighlightSelectionAction extends Action {
  color: string;
}

export interface ContentAction extends Action {
  contentId: string;
  containerId: string;
}

export interface HotkeyAction extends Action {
  key: string;
}

export interface PartialStateAction extends Action {
  state: Partial<State>;
}

export interface SectionAction extends Action {
  sectionId: string;
}

export interface DialogAction extends Action {
  dialog: string;
}

export interface PointerMovement {
  movementX: number;
  movementY: number;
}

export interface RangeSelection {
  range: AnnotationRange;
}

export interface AnnotationRange {
  start: number;
  end: number;
}

export interface PointerAction extends Action, PointerMovement {}

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  State,
  unknown,
  Action<string>
>;

export type AppDispatch = ThunkDispatch<State, unknown, Action>;

export type DialogPointerAction = DialogAction & PointerAction;
export type ItemResponseAction = ItemAction & ResponseAction;
export type ItemContentAction = ItemAction & ContentAction;
export type ItemContentSelectionAction = ItemContentAction & RangeSelection;

export const ItemNavigation = (itemId: string): ItemAction => ({
  type: ITEM_NAVIGATION,
  itemId,
});

export const ToggleReviewFlag = (itemId: string): ItemAction => ({
  type: FLAG_FOR_REVIEW_CLICKED,
  itemId,
});

export const NavigationColumnClicked = (column: string): ColumnAction => ({
  type: NAVIGATION_DIALOG_COLUMN_HEADER_CLICKED,
  column,
});

export const ItemResponseSelected = (
  item: string,
  response: string,
  currentResponse: string,
  isDecisionResponse: boolean
): ItemResponseAction => ({
  type: ITEM_RESPONSE_SELECTED,
  itemId: item,
  response,
  currentResponse,
  isDecisionResponse,
});

export const HighlightDropdownRowClicked = (
  color: string
): HighlightSelectionAction => ({
  type: HIGHLIGHT_DROPDOWN_ROW_CLICKED,
  color,
});

export const ContentPointerUp = (
  itemId: string,
  contentId: string,
  containerId: string,
  range: AnnotationRange
): ItemContentSelectionAction => ({
  type: CONTENT_POINTER_UP,
  itemId,
  contentId,
  containerId,
  range,
});

export const ContentPointerDown = (
  itemId: string,
  contentId: string,
  containerId: string
): ItemContentAction => ({
  type: CONTENT_POINTER_DOWN,
  itemId,
  contentId,
  containerId,
});

export const AltHotkey = (key: string) => ({
  type: ALT_HOTKEY,
  key,
});

export const SectionLoaded = (state: Partial<State>): PartialStateAction => ({
  type: SECTION_LOADED,
  state,
});

export const ExamLoaded = (state: Partial<State>): PartialStateAction => ({
  type: EXAM_LOADED,
  state,
});

export const SectionSaved = (sectionId: string): SectionAction => ({
  type: SECTION_SAVED,
  sectionId,
});

export const CombineActions = (
  ...actions: Array<Action | AppThunk>
): AppThunk => {
  return (dispatch) => {
    actions.forEach(dispatch);
  };
};

export const ChainActions = (
  ...actions: Array<Action | AppThunk>
): AppThunk => {
  return async (dispatch) => {
    for (let index = 0; index < actions.length; index++) {
      let action = actions[index] as any;
      let actionRun = dispatch(action);
      await actionRun;
    }
  };
};

export const SaveSectionState = (): AppThunk => {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      await saveSection(
        state.exam.currentSectionId || "",
        GetSectionStateToSave(state)
      );
      dispatch({ type: SECTION_SAVED });
    } catch (error) {
      dispatch(UnrecoverableException(error as Error));
      return Promise.reject();
    }
  };
};

export const SaveExamState = (): AppThunk => {
  return CombineActions(
    { type: BEGIN_SAVE_EXAM },
    async (dispatch, getState) => {
      try {
        let state = getState();
        await saveExam(state.exam.id, state.exam);
        dispatch({ type: END_SAVE_EXAM });
      } catch (error) {
        dispatch(UnrecoverableException(error as Error));
        return Promise.reject();
      }
    }
  );
};

export const DialogToggleOpen = (dialog: string): DialogAction => ({
  type: DIALOG_TOGGLE_OPEN,
  dialog,
});

export const DialogClose = (dialog: string): DialogAction => ({
  type: DIALOG_CLOSE,
  dialog,
});

export const DialogOpen = (dialog: string): DialogAction => ({
  type: DIALOG_OPEN,
  dialog,
});

export const DialogHeaderMouseDown = (
  dialog: string,
  event: React.PointerEvent | PointerMovement
): DialogAction & PointerAction => ({
  type: DIALOG_HEADER_MOUSE_DOWN,
  dialog,
  movementX: event?.movementX || 0,
  movementY: event?.movementY || 0,
});

export const DialogHeaderMouseUp = (
  dialog: string,
  event: React.PointerEvent | PointerMovement
): DialogAction & PointerAction => ({
  type: DIALOG_HEADER_MOUSE_UP,
  dialog,
  movementX: event?.movementX || 0,
  movementY: event?.movementY || 0,
});

export const DialogHeaderMouseMove = (
  dialog: string,
  event: React.PointerEvent | PointerMovement
): DialogAction & PointerAction => ({
  type: DIALOG_HEADER_MOUSE_MOVE,
  dialog,
  movementX: event?.movementX || 0,
  movementY: event?.movementY || 0,
});

export const DialogSouthWestResizeDown = (
  dialog: string,
  event: React.PointerEvent | PointerMovement
): DialogAction & PointerAction => ({
  type: DIALOG_SOUTH_WEST_RESIZE_MOUSE_DOWN,
  dialog,
  movementX: event?.movementX || 0,
  movementY: event?.movementY || 0,
});

export const DialogSouthWestResizeUp = (
  dialog: string,
  event: React.PointerEvent | PointerMovement
): DialogAction & PointerAction => ({
  type: DIALOG_SOUTH_WEST_RESIZE_MOUSE_UP,
  dialog,
  movementX: event?.movementX || 0,
  movementY: event?.movementY || 0,
});

export const DialogSouthWestResizeMove = (
  dialog: string,
  event: React.PointerEvent | PointerMovement
): DialogAction & PointerAction => ({
  type: DIALOG_SOUTH_WEST_RESIZE_MOUSE_MOVE,
  dialog,
  movementX: event?.movementX || 0,
  movementY: event?.movementY || 0,
});

export const DialogSouthEastResizeDown = (
  dialog: string,
  event: React.PointerEvent | PointerMovement
): DialogAction & PointerAction => ({
  type: DIALOG_SOUTH_EAST_RESIZE_MOUSE_DOWN,
  dialog,
  movementX: event?.movementX || 0,
  movementY: event?.movementY || 0,
});

export const DialogSouthEastResizeUp = (
  dialog: string,
  event: React.PointerEvent | PointerMovement
): DialogAction & PointerAction => ({
  type: DIALOG_SOUTH_EAST_RESIZE_MOUSE_UP,
  dialog,
  movementX: event?.movementX || 0,
  movementY: event?.movementY || 0,
});

export const DialogSouthEastResizeMove = (
  dialog: string,
  event: React.PointerEvent | PointerMovement
): DialogAction & PointerAction => ({
  type: DIALOG_SOUTH_EAST_RESIZE_MOUSE_MOVE,
  dialog,
  movementX: event?.movementX || 0,
  movementY: event?.movementY || 0,
});

export const DialogNorthResizeDown = (
  dialog: string,
  event: React.PointerEvent | PointerMovement
): DialogAction & PointerAction => ({
  type: DIALOG_NORTH_RESIZE_MOUSE_DOWN,
  dialog,
  movementX: event?.movementX || 0,
  movementY: event?.movementY || 0,
});

export const DialogNorthResizeUp = (
  dialog: string,
  event: React.PointerEvent | PointerMovement
): DialogAction & PointerAction => ({
  type: DIALOG_NORTH_RESIZE_MOUSE_UP,
  dialog,
  movementX: event?.movementX || 0,
  movementY: event?.movementY || 0,
});

export const DialogNorthResizeMove = (
  dialog: string,
  event: React.PointerEvent | PointerMovement
): DialogAction & PointerAction => ({
  type: DIALOG_NORTH_RESIZE_MOUSE_MOVE,
  dialog,
  movementX: event?.movementX || 0,
  movementY: event?.movementY || 0,
});

export const DialogSouthResizeDown = (
  dialog: string,
  event: React.PointerEvent | PointerMovement
): DialogAction & PointerAction => ({
  type: DIALOG_SOUTH_RESIZE_MOUSE_DOWN,
  dialog,
  movementX: event?.movementX || 0,
  movementY: event?.movementY || 0,
});

export const DialogSouthResizeUp = (
  dialog: string,
  event: React.PointerEvent | PointerMovement
): DialogAction & PointerAction => ({
  type: DIALOG_SOUTH_RESIZE_MOUSE_UP,
  dialog,
  movementX: event?.movementX || 0,
  movementY: event?.movementY || 0,
});

export const DialogSouthResizeMove = (
  dialog: string,
  event: React.PointerEvent | PointerMovement
): DialogAction & PointerAction => ({
  type: DIALOG_SOUTH_RESIZE_MOUSE_MOVE,
  dialog,
  movementX: event?.movementX || 0,
  movementY: event?.movementY || 0,
});

export const DialogEastResizeDown = (
  dialog: string,
  event: React.PointerEvent | PointerMovement
): DialogAction & PointerAction => ({
  type: DIALOG_EAST_RESIZE_MOUSE_DOWN,
  dialog,
  movementX: event?.movementX || 0,
  movementY: event?.movementY || 0,
});

export const DialogEastResizeUp = (
  dialog: string,
  event: React.PointerEvent | PointerMovement
): DialogAction & PointerAction => ({
  type: DIALOG_EAST_RESIZE_MOUSE_UP,
  dialog,
  movementX: event?.movementX || 0,
  movementY: event?.movementY || 0,
});

export const DialogEastResizeMove = (
  dialog: string,
  event: React.PointerEvent | PointerMovement
): DialogAction & PointerAction => ({
  type: DIALOG_EAST_RESIZE_MOUSE_MOVE,
  dialog,
  movementX: event?.movementX || 0,
  movementY: event?.movementY || 0,
});

export const DialogWestResizeDown = (
  dialog: string,
  event: React.PointerEvent | PointerMovement
): DialogAction & PointerAction => ({
  type: DIALOG_WEST_RESIZE_MOUSE_DOWN,
  dialog,
  movementX: event?.movementX || 0,
  movementY: event?.movementY || 0,
});

export const DialogWestResizeUp = (
  dialog: string,
  event: React.PointerEvent | PointerMovement
): DialogAction & PointerAction => ({
  type: DIALOG_WEST_RESIZE_MOUSE_UP,
  dialog,
  movementX: event?.movementX || 0,
  movementY: event?.movementY || 0,
});

export const DialogWestResizeMove = (
  dialog: string,
  event: React.PointerEvent | PointerMovement
): DialogAction & PointerAction => ({
  type: DIALOG_WEST_RESIZE_MOUSE_MOVE,
  dialog,
  movementX: event?.movementX || 0,
  movementY: event?.movementY || 0,
});
