type State = {
  timeCollapsed: boolean;
  questionCounterCollapsed: boolean;
  highlightDropdownOpen: boolean;
};

export const Default: State = {
  timeCollapsed: false,
  questionCounterCollapsed: false,
  highlightDropdownOpen: false,
};

export default State;
