import { Button, Grid } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import { EditProductDefinition } from "./EditProductDefinition";

export interface Product {
  id: string;
  title: string;
  description: string;
  logo: string;
  examDefinitionId: string;
  price: number;
}

export interface Properties {
  newProduct: Product | undefined;
}

export interface Actions {
  createNewProduct: () => void;
  acceptNewProduct: () => void;
  cancelNewProduct: () => void;
  updateNewProduct: (product: Product) => void;
  updateNewProductLogo: (format: string, file: File) => void;
}

type AllProperties = Properties & Actions;

export const ManageProductsCreateNew: FunctionComponent<AllProperties> = ({
  newProduct,
  createNewProduct,
  acceptNewProduct,
  cancelNewProduct,
  updateNewProduct,
  updateNewProductLogo,
}) => {
  return (
    <Grid container className="new-product-container">
      {!newProduct && (
        <Grid item xs={12}>
          <Button variant="contained" onClick={createNewProduct}>
            Create New Product
          </Button>
        </Grid>
      )}
      {newProduct && (
        <EditProductDefinition
          isNew={true}
          product={newProduct}
          onUpdate={updateNewProduct}
          onLogoSelect={updateNewProductLogo}
          onAccept={acceptNewProduct}
          onCancel={cancelNewProduct}
        />
      )}
    </Grid>
  );
};
