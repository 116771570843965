import settings from "./settings";

const loginResource = () => `${settings.baseAddress}/Login`;

export const loginUser = (provider: string, idToken: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    fetch(loginResource(), {
      method: "POST",
      body: idToken,
      headers: {
        Authorization: `${provider} ${idToken}`,
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error();
        return response;
      })
      .then((response) => response.json())
      .then((data) => resolve(data))
      .catch(reject);
  });
};
