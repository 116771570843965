import { Box, Slide } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import { connect } from "react-redux";
import SectionBackToSummary from "./SectionBackToSummary";
import ScoreDistributionCurve from "./graphics/ScoreDistributionCurve";
import SectionDetailCards from "./SectionDetailCards";
import SectionTabs from "./detailtabs/SectionTabs";

interface OwnProps {
  sectionId: string;
}

interface Properties {}

type AllProperties = OwnProps & Properties;

const SectionDetails: FunctionComponent<AllProperties> = ({ sectionId }) => {
  return (
    <Slide direction="left" in={true}>
      <Box>
        <SectionBackToSummary />
        <Box className="vertical-stack">
          <Box className="horizontal-stack">
            <SectionDetailCards sectionId={sectionId} />
            <Box className="score-curve vertical-stack">
              <ScoreDistributionCurve sectionId={sectionId} />
            </Box>
          </Box>
          <SectionTabs sectionId={sectionId} />
        </Box>
      </Box>
    </Slide>
  );
};

export default connect()(SectionDetails);
