import { ApplicationError } from '../utils/AppError';
import { TokenCache } from '../utils/token';
import settings from './settings';

const assetsUploadResource = (filename: string) =>
  `${settings.baseAddress}/assets/${encodeURIComponent(filename)}`;

export const uploadAssets = (file: File): Promise<any> => {
  return new Promise((resolve, reject) => {
    fetch(assetsUploadResource(file.name), {
      method: "POST",
      body: file,
      headers: {
        Authorization: `${TokenCache.provider} ${TokenCache.token}`,
      },
    })
      .then(async (response) => {
        if (!response.ok) throw new ApplicationError(await response.text());
        return response;
      })
      .then(resolve)
      .catch((error) =>
        reject(new ApplicationError(`Unable to upload assets`, error))
      );
  });
};
