import { connect } from "react-redux";
import {
  AppDispatch,
  ChainActions,
  PAUSE_EXAM,
  SaveSectionState,
} from "../app/actions";
import State from "../app/state";
import { Actions, Pause, Properties } from "../components/Pause";

interface OwnProps {
  onCompletionOrSuspension: () => void;
}

const mapStateToProps = (state: State): Properties => {
  return {
    canPause: state.navigation.canPause,
  };
};

const mapDispatchToProps = (
  dispatch: AppDispatch,
  ownProps: OwnProps
): Actions => {
  return {
    onPause: () =>
      dispatch(
        ChainActions(SaveSectionState(), { type: PAUSE_EXAM }, () =>
          ownProps.onCompletionOrSuspension()
        )
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Pause);
