import { getRangesGivenOffsetsFromElement } from "./selection";

export interface AnnotationRange {
  start: number;
  end: number;
}

export type AnnotationDecoration = {
  type: "highlight" | "strikethrough";
  subtype: string;
};

export type Annotation = {
  range: AnnotationRange;
  decorations: Array<AnnotationDecoration>;
};

export const annotate = (content: string, annotations: Array<Annotation>) => {
  let wrapper = document.createElement("div");
  wrapper.insertAdjacentHTML("beforeend", content);

  annotateInPlace(wrapper, annotations);

  return wrapper.innerHTML;
};

export const annotateInPlace = (
  element: Node,
  annotations: Array<Annotation>
) => {
  let lastFirst = (one: Annotation, two: Annotation) =>
    one.range.end - two.range.end;

  (annotations || []).sort(lastFirst).forEach((annotation) => {
    let ranges = getRangesGivenOffsetsFromElement(
      element,
      annotation.range.start,
      annotation.range.end
    );
    (ranges || []).map((range) => wrap(range, className(annotation)));
  });
};

const wrap = (range: Range, className: string) => {
  if (
    range.startContainer === range.endContainer &&
    range.startOffset === range.endOffset &&
    range.startContainer.nodeName !== "IMG"
  )
    return;

  let wrapper = document.createElement("span");
  if (
    range.startContainer === range.endContainer &&
    range.startContainer.nodeName === "IMG"
  ) {
    wrapper.setAttribute("class", `user-annotation ${className} img`);
    var imgRange = document.createRange();
    imgRange.setStart(range.startContainer.parentElement!, 0);
    imgRange.setEnd(range.endContainer.parentElement!, 1);
    imgRange.surroundContents(wrapper);
  } else {
    wrapper.setAttribute("class", `user-annotation ${className}`);
    range.surroundContents(wrapper);
  }
};

const className = (annotation: Annotation) =>
  annotation.decorations
    .map(
      (decoration) =>
        `${decoration.type}${
          decoration.subtype ? `-${decoration.subtype}` : ""
        }`
    )
    .join(" ");
