import { connect } from "react-redux";
import { AppDispatch, EXAM_DEF_DRAWER_TOGGLE } from "../../../app/actions";
import State from "../../../app/state";
import {
  Actions,
  ManageExamsExtractedDefDrawer,
  Properties,
} from "../../../components/ManageExamsExtractedDefDrawer";

const mapStateToProps = (state: State): Properties => {
  return {
    extractedDef: state.manageExams.extractedDef,
    drawerOpen: state.manageExams.defJsonDrawerOpen,
  };
};

const mapDispatchToActions = (dispatch: AppDispatch): Actions => {
  return {
    toggleDrawer: () => dispatch({ type: EXAM_DEF_DRAWER_TOGGLE }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToActions
)(ManageExamsExtractedDefDrawer);
