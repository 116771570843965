import { connect } from "react-redux";
import State from "../app/state";
import { TimeoutWarning, Properties } from "../components/TimeoutWarning";

const mapStateToProps = (state: State): Properties => {
  return {
    warningMessage: state.timing.currentWarningMessage,
  };
};

export default connect(mapStateToProps)(TimeoutWarning);
