import { connect } from "react-redux";
import {
  AppDispatch,
  ChainActions,
  UserLogin,
  USER_LOGOUT,
  USER_PROFILE_MENU_TOGGLE,
} from "../app/actions";
import State from "../app/state";
import apiSettings from "../api/settings";
import { Actions, User, Properties } from "../components/User";
import { ValidateUserLogin } from "../User/reducer";
import { TokenCache } from "../utils/token";

const mapStateToProps = (state: State): Properties => {
  return {
    googleClientId: apiSettings.googleOauthClientId,
    provider: state.user.identityProvider || "",
    loggedIn: state.user.loggedIn,
    email: state.user.email || "",
    profilePic: state.user.profilePic || "",
    profileMenuOpen: state.user.profileMenuOpen,
  };
};

const mapDispatchToActions = (dispatch: AppDispatch): Actions => {
  return {
    onLogin: (
      provider: string,
      email: string,
      profilePic: string,
      idToken: string
    ) => {
      TokenCache.provider = provider;
      TokenCache.token = idToken;

      dispatch(
        ChainActions(
          UserLogin(provider, email, profilePic, idToken),
          ValidateUserLogin()
        )
      );
    },
    onLoginFailure: (error: any) => {
      TokenCache.provider = "";
      TokenCache.token = "";
      console.log(error);
    },
    onLogout: () => {
      TokenCache.provider = "";
      TokenCache.token = "";
      dispatch({ type: USER_LOGOUT });
    },
    onToggleProfileMenuOpen: () => dispatch({ type: USER_PROFILE_MENU_TOGGLE }),
  };
};

export default connect(mapStateToProps, mapDispatchToActions)(User);
