import './assets/css/UserPortalApp.scss';

import React, { FunctionComponent } from 'react';

import { Provider } from 'react-redux';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

import store from './app/store';
import Exam from './connections/Exam';
import Header from './connections/Header';
import ManageContentPage from './connections/manage/content/ManageContentPage';
import ManageExamsPage from './connections/manage/exams/ManageExamsPage';
import UploadPackagePage from './connections/manage/package/UploadPackagePage';
import ManageProductsPage
  from './connections/manage/products/ManageProductsPage';
import Marketplace from './connections/marketplace/MarketplacePage';
import MyPurchases from './connections/purchases/MyPurchases';
import Report from './connections/Report';
import User from './connections/User';
import {
  ExaminationRoute,
  ManageContentRoute,
  ManageExamsRoute,
  ManageProductsRoute,
  MarketplaceRoute,
  MyPurchasesRoute,
  ScoreReportRoute,
  UploadPackageRoute,
} from './Navigation/state';
import ErrorWrapper from './utils/ErrorWrapper';

const App: FunctionComponent = () => {
  return (
    <Provider store={store}>
      <ErrorWrapper
        message="An error has occurred"
        details="Please refresh your browser and try again - if the problem persists, please try again later"
      >
        <Router>
          <Switch>
            <Route path={ExaminationRoute()}>
              <Exam />
            </Route>
            <Route path={ScoreReportRoute()}>
              <Report />
            </Route>
            <Route path={MyPurchasesRoute}>
              <div className="portal">
                <Header>
                  <User />
                </Header>
                <MyPurchases />
              </div>
            </Route>
            <Route path={ManageExamsRoute}>
              <div className="portal">
                <Header>
                  <User />
                </Header>
                <ManageExamsPage />
              </div>
            </Route>
            <Route path={ManageProductsRoute}>
              <div className="portal">
                <Header>
                  <User />
                </Header>
                <ManageProductsPage />
              </div>
            </Route>
            <Route path={ManageContentRoute}>
              <div className="portal">
                <Header>
                  <User />
                </Header>
                <ManageContentPage />
              </div>
            </Route>
            <Route path={UploadPackageRoute}>
              <div className="portal">
                <Header>
                  <User />
                </Header>
                <UploadPackagePage />
              </div>
            </Route>
            <Route path={MarketplaceRoute}>
              <div className="portal">
                <Header>
                  <User />
                </Header>
                <Marketplace />
              </div>
            </Route>
            <Redirect from="" to={MarketplaceRoute} />
            <Redirect from="/" to={MarketplaceRoute} />
          </Switch>
        </Router>
      </ErrorWrapper>
    </Provider>
  );
};

export default App;
