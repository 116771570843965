import { combineReducers } from "redux";
import navigationReducer from "../Navigation/reducer";
import overallResultsReducer from "../OverallResults/reducer";
import sectionResultsReducer from "../SectionResults/reducer";
import itemAnnotationReducer from "../ItemAnnotation/reducer";
import itemContentReducer from "../ItemContent/reducer";
import itemsTableReducer from "../ItemsTable/reducer";
import itemRationaleReducer from "../ItemRationale/reducer";
import itemStateReducer from "../ItemState/reducer";
import skillsReducer from "../Skills/reducer";
import helpReducer from "../Help/reducer";
import itemReviewReducer from "../ItemReview/reducer";
import { errorCatchingReducer } from "../../utils/AppError";

const reducer = combineReducers({
  navigation: navigationReducer,
  overallResults: overallResultsReducer,
  sectionResults: sectionResultsReducer,
  itemAnnotation: itemAnnotationReducer,
  itemContent: itemContentReducer,
  itemsTable: itemsTableReducer,
  itemRationale: itemRationaleReducer,
  itemState: itemStateReducer,
  skills: skillsReducer,
  help: helpReducer,
  itemReview: itemReviewReducer,
  errorState: errorCatchingReducer,
});

export default reducer;
