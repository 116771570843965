export interface AnnotationRange {
  start: number;
  end: number;
}

type MappedNode = {
  total: number;
  children: Array<MappedNode>;
};

export const selectTextWithinElement = (id: string) => {
  let element = document.getElementById(id);
  if (!element) return;
  let selection = window.getSelection();
  if (!selection) return;
  let range = document.createRange();
  range.selectNodeContents(element);
  selection.removeAllRanges();
  selection.addRange(range);
};

export const getSelectedText = (text: string, range: AnnotationRange) => {
  return getTextRange(text, range).toString();
};

export const getSelectedHtml = (
  html: string,
  range: AnnotationRange,
  skipStyleTags = false
) => {
  let item = document.createElement("div");
  item.innerHTML = html;

  if (skipStyleTags) removeStyleTagsFrom(item);

  let selection = getOverallRangeGivenOffsetsFromElement(
    item,
    range.start,
    range.end
  );

  if (range.start > 0) {
    let cellPadding = 0;

    let current = selection.startContainer;
    while (current && current.parentNode) {
      if (current.parentNode.nodeName === "TR") {
        let neighbor = current.previousSibling;
        while (neighbor?.nodeName === "TD") {
          cellPadding += 1;
          neighbor = neighbor.previousSibling;
        }
        break;
      } else current = current.parentNode;
    }

    let start = document.createRange();
    start.selectNodeContents(item);
    start.setEnd(selection.startContainer, selection.startOffset);
    start.extractContents();

    if (cellPadding > 0)
      selection.startContainer.parentElement?.insertAdjacentHTML(
        "beforebegin",
        `<td colspan="${cellPadding}"></td>`
      );
  }

  if (Math.abs(range.start - range.end) < item.innerText.length) {
    let end = document.createRange();
    end.selectNodeContents(item);
    end.setStart(selection.endContainer, selection.endOffset);
    end.extractContents();
  }

  return item.innerHTML;
};

const removeStyleTagsFrom = (item: HTMLDivElement) => {
  item.childNodes.forEach((node) => {
    if (node.nodeName === "STYLE") node.remove();
  });
};

const getTextRange = (text: string, range: AnnotationRange) => {
  let element = document.createElement("div");
  element.setAttribute("id", "selection-source");
  element.innerHTML = text;
  return getOverallRangeGivenOffsetsFromElement(
    element,
    range.start,
    range.end
  );
};

export const selectionRange = (
  rootId: string,
  range: Range
): AnnotationRange => {
  let root = document.getElementById(rootId);
  if (!root) return { start: 0, end: 0 };

  let startElement = range.startContainer.hasChildNodes()
    ? range.startContainer.childNodes.item(range.startOffset)
    : range.startContainer;

  let start = isContainedWithin(startElement, root)
    ? textOffsetFromRoot(
        root,
        startElement,
        range.startContainer === startElement ? range.startOffset : 0
      )
    : 0;

  let endElement = range.endContainer.hasChildNodes()
    ? range.endContainer.childNodes.item(range.endOffset - 1)
    : range.endContainer;

  let end = isContainedWithin(endElement, root)
    ? textOffsetFromRoot(
        root,
        endElement,
        range.endContainer === endElement
          ? range.endOffset
          : getNodeLengthMappingsFrom(endElement).total
      )
    : getNodeLengthMappingsFrom(root).total;

  return {
    start: Math.min(start, end),
    end: Math.max(start, end),
  };
};

export const getRangesGivenOffsets = (
  rootId: string,
  start: number,
  end: number
): Array<Range> => {
  let root = document.getElementById(rootId);
  if (!root) return [];
  return getRangesGivenOffsetsFromElement(root, start, end);
};

export const getRangesGivenOffsetsFromElement = (
  root: Node,
  start: number,
  end: number
) => {
  let range = getOverallRangeGivenOffsetsFromElement(root, start, end);
  let individualRanges = getIndividualRanges(range);
  return individualRanges;
};

export const getOverallRangeGivenOffsetsFromElement = (
  root: Node,
  start: number,
  end: number
) => {
  let map = getNodeLengthMappingsFrom(root);

  let startAddress = getAddressContainingOffset(start + 1, map);
  let startElement = getElementFromOffset(root, startAddress);
  let startOffset = start - getLengthCoveredBeforeAddress(startAddress, map);

  if (startElement.nodeName === "IMG" && startOffset > 0) {
    startElement = getNextElement(startElement)!;
    startOffset = 0;
  }

  let endAddress = getAddressContainingOffset(end, map);
  let endElement = getElementFromOffset(root, endAddress);
  let endOffset = end - getLengthCoveredBeforeAddress(endAddress, map);

  if (endElement.nodeName === "IMG" && endOffset > 0) {
    endElement = endElement.parentElement!;
    endOffset = 0;
  }

  let range = document.createRange();
  if (endElement === root) {
    let sizeTester = document.createElement("div");
    sizeTester.append(startElement.cloneNode());
    let endAt = sizeTester.innerText.length;

    range.setStart(startElement, startOffset);
    range.setEnd(startElement, endAt);
  } else {
    range.setStart(startElement, startOffset);
    range.setEnd(endElement, endOffset);
  }

  return range;
};

export const clearUserTextSelection = () => {
  if (window.getSelection) {
    if (window.getSelection()?.empty) {
      // Chrome
      window.getSelection()?.empty();
    } else if (window.getSelection()?.removeAllRanges) {
      // Firefox
      window.getSelection()?.removeAllRanges();
    }
  } else if ((document as any).selection) {
    // IE?
    (document as any).selection.empty();
  }
};

const isContainedWithin = (element: Node, container: Node) => {
  let testNode: Node | null = element;
  while (testNode) {
    if (testNode === container) {
      return true;
    }
    testNode = testNode.parentNode;
  }
  return false;
};

function getTextContentLength(node: Node): number {
  let text = node.textContent || "";
  return text.length;
}

const textOffsetFromRoot = (
  root: HTMLElement,
  container: Node,
  offset: number
) => {
  let distance = offset;
  let current: Node | null = container;
  while (current && current !== root) {
    while (current.previousSibling) {
      current = current.previousSibling;
      if (
        (current as HTMLElement).hasAttribute &&
        (current as HTMLElement).hasAttribute("data-character-count")
      )
        distance += parseInt(
          (current as HTMLElement).getAttribute("data-character-count") || "0"
        );
      else distance += getTextContentLength(current);
    }
    current = current.parentNode;
  }
  return distance;
};

const getNodeLengthMappingsFrom = (node: Node): MappedNode => {
  if (!node.hasChildNodes())
    return {
      total: node.nodeName === "IMG" ? 1 : getTextContentLength(node),
      children: [],
    };

  let children: Array<MappedNode> = [];
  let current = node.firstChild;
  while (current) {
    children.push(getNodeLengthMappingsFrom(current));
    current = current.nextSibling;
  }

  return {
    total: children.reduce((total, child) => total + child.total, 0),
    children,
  };
};

const getAddressContainingOffset = (
  offset: number,
  map: MappedNode
): Array<number> => {
  if (offset > map.total) return [];

  let runningTotal = 0;
  for (let childIndex = 0; childIndex < map.children.length; childIndex++) {
    let child = map.children[childIndex];
    if (runningTotal + child.total >= offset)
      return [
        childIndex,
        ...getAddressContainingOffset(offset - runningTotal, child),
      ];
    runningTotal += child.total;
  }

  return [];
};

const getLengthCoveredBeforeAddress = (
  address: Array<number>,
  map: MappedNode
) => {
  let totalCovered = 0;
  let mapNode = map;

  for (const addressedEntry of address) {
    for (let coveredIndex = 0; coveredIndex < addressedEntry; coveredIndex++) {
      totalCovered += mapNode.children[coveredIndex].total;
    }

    mapNode = mapNode.children[addressedEntry];
  }

  return totalCovered;
};

const getElementFromOffset = (root: Node, address: Array<number>) => {
  let element = root;
  for (let addressIndex = 0; addressIndex < address.length; addressIndex++) {
    element = element.childNodes.item(address[addressIndex]);
  }

  return element;
};

const getIndividualRanges = (
  range: Range | undefined,
  elements: Array<Node> = []
): Array<Range> => {
  if (!range) return [];
  let singleElement = range.startContainer === range.endContainer;

  let startRange =
    elements.indexOf(range.startContainer) >= 0
      ? []
      : getIndividualElementRanges(
          range.startContainer,
          range.startOffset,
          singleElement ? range.endOffset : undefined
        );

  if (singleElement) return startRange;

  let endRange =
    elements.indexOf(range.endContainer) >= 0
      ? []
      : getIndividualElementRanges(range.endContainer, 0, range.endOffset);

  var nextStart = getNextElement(range.startContainer);

  var nextEnd = getPreviousElement(range.endContainer);

  if (!nextStart || !nextEnd) return [...startRange, ...endRange];

  var middleRange = document.createRange();
  middleRange.setStart(nextStart, 0);
  middleRange.setEnd(nextEnd, 0);
  return [
    ...startRange,
    ...getIndividualRanges(middleRange, [
      ...elements,
      range.startContainer,
      range.endContainer,
    ]),
    ...endRange,
  ];
};

const getNextElement = (element: Node): Node | null => {
  let currentElement: Node | HTMLElement | null = element;

  while (currentElement && !currentElement.nextSibling) {
    currentElement = currentElement?.parentElement;
  }

  if (!currentElement) return null;
  currentElement = currentElement.nextSibling;

  while (currentElement?.hasChildNodes()) {
    currentElement = currentElement.firstChild;
  }

  return currentElement;
};

const getPreviousElement = (element: Node): Node | null => {
  let currentElement: Node | HTMLElement | null = element;

  while (currentElement && !currentElement.previousSibling) {
    currentElement = currentElement?.parentElement;
  }

  if (!currentElement) return null;
  currentElement = currentElement.previousSibling;

  while (currentElement?.hasChildNodes()) {
    currentElement = currentElement.lastChild;
  }

  return currentElement;
};

const getIndividualElementRanges = (
  element: Node,
  start?: number,
  end?: number
) => {
  let ranges: Array<Range> = [];

  if (element.nodeType === Node.TEXT_NODE || element.nodeName === "IMG") {
    let selection = document.createRange();
    selection.setStart(element, start || 0);
    selection.setEnd(element, end || getTextContentLength(element));
    ranges.push(selection);
  } else if (element.hasChildNodes()) {
    for (
      let nodeIndex = 0;
      nodeIndex < element.childNodes.length;
      nodeIndex++
    ) {
      let childNode = element.childNodes.item(nodeIndex);
      let childNodeRanges = getIndividualElementRanges(
        childNode,
        nodeIndex === 0 ? start : undefined,
        nodeIndex === element.childNodes.length - 1 ? end : undefined
      );
      ranges.push(...childNodeRanges);
    }
  }

  return ranges;
};
