import { Action } from "redux";
import { LocationUpdateAction, UPDATE_LOCATION_STATE } from "../app/actions";
import State, { DefaultState } from "./state";

const onNavigate = (state: State, to: string, id?: string): State => {
  return {
    ...state,
    location: to,
    id,
  };
};

const reducer = (state: State, action: Action): State => {
  if (!state) return DefaultState;
  switch (action.type) {
    case UPDATE_LOCATION_STATE:
      return onNavigate(
        state,
        (action as LocationUpdateAction).to,
        (action as LocationUpdateAction).id
      );
    default:
      return state;
  }
};

export default reducer;
