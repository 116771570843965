import { connect } from "react-redux";
import State from "../../app/state";
import { Properties, ReviewDialog } from "../../components/ReviewDialog";

const mapStateToProps = (state: State): Properties => {
  return {
    open: state.itemReview.reviewing,
  };
};

export default connect(mapStateToProps)(ReviewDialog);
