import { TokenCache } from "../utils/token";
import settings from "./settings";

const productDefinitionQueryResource = (skip: number, take: number) =>
  `${settings.baseAddress}/ProductDefinition?skip=${skip}&take=${take}`;

const productDefinitionsResource = (id?: string) =>
  `${settings.baseAddress}/ProductDefinition/${id || ""}`;

export const getProductDefinitions = (
  skip: number,
  take: number
): Promise<Array<any>> => {
  return new Promise((resolve, reject) => {
    fetch(productDefinitionQueryResource(skip, take), {
      headers: {
        Authorization: `${TokenCache.provider} ${TokenCache.token}`,
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error();
        return response;
      })
      .then((response) => response.json())
      .then((data) => resolve(data))
      .catch(reject);
  });
};

export const updateProductDefinition = (
  id: string,
  product: any
): Promise<any> => {
  return new Promise((resolve, reject) => {
    fetch(productDefinitionsResource(id), {
      body: JSON.stringify(product),
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${TokenCache.provider} ${TokenCache.token}`,
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error();
        return response;
      })
      .then(resolve)
      .catch(reject);
  });
};

export const createProductDefinition = (product: any): Promise<any> => {
  return new Promise((resolve, reject) => {
    fetch(productDefinitionsResource(), {
      body: JSON.stringify(product),
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${TokenCache.provider} ${TokenCache.token}`,
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error();
        return response;
      })
      .then(resolve)
      .catch(reject);
  });
};
