import { Box, IconButton, Paper, Typography } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import VisibilityIcon from "@material-ui/icons/Visibility";

export interface Properties {
  label: string;
  itemId: string;
  sectionItems: Array<string>;
  annotations: Array<string>;
}

export interface Actions {
  onReviewItem: (id: string, sectionItems: Array<string>) => void;
}

type AllProperties = Properties & Actions;

export const ContentAnnotations: FunctionComponent<AllProperties> = ({
  label,
  itemId,
  sectionItems,
  annotations,
  onReviewItem,
}) => {
  if (!annotations || annotations.length === 0) return <></>;

  return (
    <Paper elevation={3} className="item-highlight">
      <Typography display="inline" variant="h6" className="header">
        {label}
      </Typography>
      <IconButton
        className="preview-button"
        onClick={() => onReviewItem(itemId, sectionItems)}
      >
        <VisibilityIcon />
      </IconButton>
      {annotations.map((annotation, index) => (
        <Box key={index} className="content content-container">
          <blockquote
            dangerouslySetInnerHTML={{ __html: annotation }}
          ></blockquote>
        </Box>
      ))}
    </Paper>
  );
};
