import React, { FunctionComponent, useEffect } from "react";
import { connect } from "react-redux";
import { UpdateLocationToMatchManageContent } from "../../../app/actions";
import State from "../../../app/state";
import { AppDispatch } from "../../../ExamApp/app/actions";
import ManageContent from "./ManageContent";

interface Properties {}

interface Actions {
  updateLocationInState: () => void;
}

type AllProperties = Properties & Actions;

const ManageContentPage: FunctionComponent<AllProperties> = ({
  updateLocationInState,
}) => {
  useEffect(() => {
    updateLocationInState();
  });
  return <ManageContent />;
};

const mapStateToProperties = (state: State): Properties => {
  return {};
};

const mapDispatchToActions = (dispatch: AppDispatch): Actions => {
  return {
    updateLocationInState: () => dispatch(UpdateLocationToMatchManageContent()),
  };
};

export default connect(
  mapStateToProperties,
  mapDispatchToActions
)(ManageContentPage);
