import { Box } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import { connect } from "react-redux";
import State from "../app/state";
import { ReportHeader } from "../components/ReportHeader";
import HeaderNavLink from "./HeaderNavLink";
import ScoreDistributionCurve from "./graphics/ScoreDistributionCurve";
import ForEachSection from "./structural/ForEachSection";

interface OwnProps {
  onReturn: () => void;
}

interface Properties {
  title: string;
}

type AllProperties = Properties & OwnProps;

const Header: FunctionComponent<AllProperties> = ({ title, onReturn }) => {
  return (
    <ReportHeader title={title} onReturn={onReturn}>
      <ForEachSection>
        {(id) => (
          <Box className="score-component vertical-stack">
            <ScoreDistributionCurve
              sectionId={id}
              height={125}
              width={250}
              textColor="white"
              textSize="default"
            />
            <HeaderNavLink sectionId={id} />
          </Box>
        )}
      </ForEachSection>
    </ReportHeader>
  );
};

const mapStateToProps = (state: State): Properties => {
  return {
    title: state.overallResults.title,
  };
};

export default connect(mapStateToProps)(Header);
