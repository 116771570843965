import { connect } from 'react-redux';

import {
  AppDispatch,
  ReviewItems,
} from '../../app/actions';
import State from '../../app/state';
import {
  Actions,
  Properties,
  SkillsTable,
} from '../../components/SkillsTable';

interface OwnProps {
  sectionId: string;
  groupId: string;
}

const mapStateToProps = (state: State, ownProps: OwnProps): Properties => {
  let sectionItemIds = state.itemContent.sectionItems[ownProps.sectionId];
  let sectionItems = sectionItemIds.map((id) => state.itemContent.items[id]);
  let skillItems = (skillId: string) =>
    sectionItems
      .filter((item) => item.skills.indexOf(skillId) > -1)
      .map((item) => item.id);

  let sectionSkills = state.skills.skills[ownProps.sectionId];
  let skills = Object.values(sectionSkills)
    .filter((skill) => skill.groupId === ownProps.groupId)
    .sort((a, b) => a.label.localeCompare(b.label))
    .map((skill) => ({
      ...skill,
      itemIds: skillItems(skill.id),
    }));
  return {
    skills,
  };
};

const mapDispatchToProps = (
  dispatch: AppDispatch,
  ownProps: OwnProps
): Actions => {
  return {
    onReview: (itemIds: Array<string>) =>
      itemIds.length > 0
        ? dispatch(ReviewItems(itemIds[0], itemIds, ownProps.sectionId))
        : {},
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SkillsTable);
