type State = {
  fileBeingUploaded?: File;
  uploadProcessing?: boolean;
  uploadSuccessful?: boolean;
  uploadFailed?: boolean;
};

export default State;

export const DefaultState: State = {};
