import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from "@material-ui/core";
import React, { FunctionComponent } from "react";

export interface SkillGroup {
  id: string;
  label: string;
  description: string;
}

export interface Properties {
  data: Array<SkillGroup>;
}

export const ManageContentDisplaySkillGroups: FunctionComponent<Properties> = ({
  data,
}) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            <Typography>Id</Typography>
          </TableCell>
          <TableCell>
            <Typography>Label</Typography>
          </TableCell>
          <TableCell>
            <Typography>Description</Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((group) => (
          <TableRow key={group.id}>
            <TableCell>
              <Typography>{group.id}</Typography>
            </TableCell>
            <TableCell>
              <Typography>{group.label}</Typography>
            </TableCell>
            <TableCell>
              <Typography>{group.description}</Typography>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
