import React, { FunctionComponent } from "react";
import { secondsToTimeDisplay } from "../../utils/formatting";

export interface Properties {
  title: string;
  timed: boolean;
  timerCollapsed: boolean;
  timeRemaining: number;
  showCounter: boolean;
  counterCollapsed: boolean;
  counterCurrent: number;
  counterTotal: number;
}

export interface Actions {
  onTimerClicked: () => void;
  onCounterClicked: () => void;
}

type AllProperties = Properties & Actions;

export const Header: FunctionComponent<AllProperties> = ({
  title,
  timed,
  timerCollapsed,
  timeRemaining,
  showCounter,
  counterCollapsed,
  counterCurrent,
  counterTotal,
  onTimerClicked,
  onCounterClicked,
}) => {
  return (
    <>
      <div className="header">
        <span className="title">{title}</span>

        {timed && (
          <span className="time-remaining" onClick={onTimerClicked}>
            <span className="icon"></span>
            {!timerCollapsed && (
              <>
                <span className="text">Time Remaining:</span>
                <span className="time">
                  {secondsToTimeDisplay(timeRemaining)}
                </span>
              </>
            )}
          </span>
        )}

        {showCounter && (
          <span className="question-counter" onClick={onCounterClicked}>
            <span className="icon"></span>
            {!counterCollapsed && (
              <span className="text">
                {counterCurrent} of {counterTotal}
              </span>
            )}
          </span>
        )}
      </div>
      <hr className="divider" />
    </>
  );
};
