import { ApplicationError } from "../utils/AppError";
import { TokenCache } from "../utils/token";
import settings from "./settings";

const skillQueryResource = (take?: number, skip?: number) =>
  `${settings.baseAddress}/Skill?take=${take || ""}&skip=${skip || ""}`;

const skillUploadResource = () => `${settings.baseAddress}/Skill`;

export const getSkills = (take: number, skip: number): Promise<any> => {
  return new Promise((resolve, reject) => {
    fetch(skillQueryResource(take, skip), {
      headers: {
        Authorization: `${TokenCache.provider} ${TokenCache.token}`,
      },
    })
      .then(async (response) => {
        if (!response.ok) throw new ApplicationError(await response.text());
        return response;
      })
      .then((response) => response.json())
      .then((data) => resolve(data))
      .catch((error) =>
        reject(new ApplicationError(`Unable to retrieve skills`, error))
      );
  });
};

export const uploadSkills = (file: File): Promise<any> => {
  return new Promise((resolve, reject) => {
    fetch(skillUploadResource(), {
      method: "POST",
      body: file,
      headers: {
        Authorization: `${TokenCache.provider} ${TokenCache.token}`,
      },
    })
      .then(async (response) => {
        if (!response.ok) throw new ApplicationError(await response.text());
        return response;
      })
      .then(resolve)
      .catch((error) =>
        reject(new ApplicationError(`Unable to upload skills`, error))
      );
  });
};
