import React, { FunctionComponent } from "react";

import { useHistory } from "react-router";

import { AppBar, Box, Button, Toolbar, Typography } from "@material-ui/core";
import { Assignment, Storefront, SupervisorAccount } from "@material-ui/icons";

import {
  ManageContentRoute,
  ManageExamsRoute,
  ManageProductsRoute,
  MarketplaceRoute,
  MyPurchasesRoute,
  UploadPackageRoute,
} from "../Navigation/state";

export interface Properties {
  location: string;
  loggedIn: boolean;
  isAdmin: boolean;
}

export const Header: FunctionComponent<Properties> = ({
  location,
  loggedIn,
  isAdmin,
  children,
}) => {
  let history = useHistory();
  return (
    <AppBar position="static" className="header">
      <Toolbar>
        <Box className="navigation-buttons">
          <Button
            onClick={() => history.push(MarketplaceRoute)}
            className={`navigation-button ${
              location === MarketplaceRoute && "here"
            }`}
            disabled={location === MarketplaceRoute}
          >
            <Storefront className="icon" />
            <Typography
              variant={location === MarketplaceRoute ? "h6" : "body1"}
              className="title"
            >
              Marketplace
            </Typography>
          </Button>
          {loggedIn && (
            <>
              <Button
                onClick={() => history.push(MyPurchasesRoute)}
                className={`navigation-button ${
                  location === MyPurchasesRoute && "here"
                }`}
                disabled={location === MyPurchasesRoute}
              >
                <Assignment className="icon" />
                <Typography
                  variant={location === MyPurchasesRoute ? "h6" : "body1"}
                  className="title"
                >
                  My Purchases
                </Typography>
              </Button>
              <Button className="navigation-button">
                <Assignment className="icon" />
                <Typography variant="body1" className="title">
                  Free Resources
                </Typography>
              </Button>
              <Button className="navigation-button">
                <Assignment className="icon" />
                <Typography variant="body1" className="title">
                  Accommodations
                </Typography>
              </Button>
              <Button className="navigation-button">
                <Assignment className="icon" />
                <Typography variant="body1" className="title">
                  Study Plan Guide
                </Typography>
              </Button>
            </>
          )}

          {loggedIn && isAdmin && (
            <>
              <Button
                onClick={() => history.push(ManageExamsRoute)}
                className={`navigation-button ${
                  location === ManageExamsRoute && "here"
                }`}
                disabled={location === ManageExamsRoute}
              >
                <SupervisorAccount className="icon" />
                <Typography
                  variant={location === ManageExamsRoute ? "h6" : "body1"}
                  className="title"
                >
                  Manage Exams
                </Typography>
              </Button>

              <Button
                onClick={() => history.push(ManageProductsRoute)}
                className={`navigation-button ${
                  location === ManageProductsRoute && "here"
                }`}
                disabled={location === ManageProductsRoute}
              >
                <SupervisorAccount className="icon" />
                <Typography
                  variant={location === ManageProductsRoute ? "h6" : "body1"}
                  className="title"
                >
                  Manage Products
                </Typography>
              </Button>

              <Button
                onClick={() => history.push(ManageContentRoute)}
                className={`navigation-button ${
                  location === ManageContentRoute && "here"
                }`}
                disabled={location === ManageContentRoute}
              >
                <SupervisorAccount className="icon" />
                <Typography
                  variant={location === ManageContentRoute ? "h6" : "body1"}
                  className="title"
                >
                  Manage Content
                </Typography>
              </Button>

              <Button
                onClick={() => history.push(UploadPackageRoute)}
                className={`navigation-button ${
                  location === UploadPackageRoute && "here"
                }`}
                disabled={location === UploadPackageRoute}
              >
                <SupervisorAccount className="icon" />
                <Typography
                  variant={location === UploadPackageRoute ? "h6" : "body1"}
                  className="title"
                >
                  Upload Package
                </Typography>
              </Button>
            </>
          )}
        </Box>
        {children}
      </Toolbar>
    </AppBar>
  );
};
