import React, { FunctionComponent, useEffect } from "react";
import { connect } from "react-redux";

import { ApplicationError, AppStateContainingError } from "./AppError";
import CentrallyDisplayedCard from "./CentrallyDisplayedCard";

export interface OwnProps {
  children: React.ReactNode;
  message?: string;
  details?: string;
}

export interface Properties {
  error: ApplicationError | undefined;
}

type AllProperties = Properties & OwnProps;

const ErrorWrapper: FunctionComponent<AllProperties> = ({
  error,
  children,
  message,
  details,
}) => {
  useEffect(() => {
    const trimString = (string: string) =>
      `${string.substr(0, 100)}${string.length >= 100 ? "..." : ""}`;

    const logAppError = (
      appError: ApplicationError,
      isInner: boolean = false
    ) => {
      if (isInner) {
        console.groupCollapsed(
          `Inner Exception: ${trimString(appError.message)}`
        );
        console.log(appError.message);
      } else
        console.groupCollapsed(
          `%cThere was an application error:%c ${trimString(appError.message)}`,
          "color: #ff3d30; background: #ffe7e6; font-size: large",
          ""
        );
      console.groupCollapsed("The error itself is here");
      console.error(appError);
      console.groupEnd();
      if (appError.underlyingError) logAppError(appError.underlyingError, true);
      console.groupEnd();
    };

    if (error) {
      logAppError(error);
    }
  });

  if (error)
    return (
      <CentrallyDisplayedCard
        message={
          message ||
          "An application level error has occurred and we could not recover from it"
        }
        details={
          details ||
          "Try refreshing your browser and attempting the action again - if that does not resolve the problem, try again later"
        }
      />
    );

  return <>{children}</>;
};

const mapStateToProps = (state: AppStateContainingError): Properties => {
  return {
    error: state.errorState.error,
  };
};

export default connect(mapStateToProps)(ErrorWrapper);
