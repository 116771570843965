import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Typography,
} from "@material-ui/core";
import { LocalAtm } from "@material-ui/icons";
import React, { FunctionComponent } from "react";

export interface Properties {
  index: number;
  loggedIn: boolean;
  name: string;
  description: string;
  logo: string;
  price: number;
  purchased: boolean;
}

export interface Actions {
  onPurchase: () => void;
}

type AllProperties = Properties & Actions;

export const Product: FunctionComponent<AllProperties> = ({
  index,
  loggedIn,
  name,
  description,
  logo,
  price,
  purchased,
  onPurchase,
}) => {
  return (
    <Card
      className="product"
      elevation={3}
      style={{ animationDuration: `${0.3 + 0.05 * index}s` }}
    >
      <CardHeader title={<Typography>{name}</Typography>}></CardHeader>
      {logo && <CardMedia className="product-image" image={logo} />}
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
          {description}
        </Typography>
      </CardContent>
      <CardActions>
        {loggedIn && !purchased && (
          <button role="link" className="purchase-button" onClick={onPurchase}>
            Purchase
          </button>
        )}
        {purchased && (
          <span className="purchased-label-wrapper">
            <span className="purchased-label">Purchased!</span>
          </span>
        )}
        <Box className="price-box">
          <LocalAtm />
          <Typography>{price.toFixed(2)}</Typography>
        </Box>
      </CardActions>
    </Card>
  );
};
