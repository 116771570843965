import { Action } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import State from "./state";

export const NAVIGATE_TO = "NAVIGATE_TO";
export const DETAIL_TAB_SWITCH = "DETAIL_TAB_SWITCH";

export const RESULTS_LOAD_BEGIN = "RESULTS_LOAD_BEGIN";
export const RESULTS_LOADED = "RESULTS_LOADED";

export const NEXT_FLAGGED_FILTER = "NEXT_FLAGGED_FILTER";
export const NEXT_CORRECT_FILTER = "NEXT_CORRECT_FILTER";

export const OPEN_PERCENTILE_RANK_HELP = "OPEN_PERCENTILE_RANK_HELP";
export const OPEN_SCALED_SCORE_HELP = "OPEN_SCALED_SCORE_HELP";
export const CLOSE_HELP = "CLOSE_HELP";

export const REVIEW_ITEMS = "REVIEW_ITEMS";
export const REVIEW_ITEM_NEXT = "REVIEW_ITEM_NEXT";
export const REVIEW_ITEM_PREVIOUS = "REVIEW_ITEM_PREVIOUS";
export const REVIEW_ITEM_FIRST = "REVIEW_ITEM_FIRST";
export const REVIEW_ITEM_LAST = "REVIEW_ITEM_LAST";
export const REVIEW_ITEMS_CLOSE = "REVIEW_ITEMS_CLOSE";

export interface NavigationAction extends Action {
  destination: string;
}

export interface DetailTabAction extends Action {
  index: number;
}

export interface PartialStateAction extends Action {
  newState: Partial<State>;
}

export interface ReviewItemsAction extends Action {
  itemId: string;
  items: Array<string>;
  sectionId: string;
}

export const NavigateTo = (destination: string): NavigationAction => ({
  type: NAVIGATE_TO,
  destination,
});

export const SwitchToDetailTab = (index: number): DetailTabAction => ({
  type: DETAIL_TAB_SWITCH,
  index,
});

export const ReviewItems = (
  itemId: string,
  items: Array<string>,
  sectionId: string
): ReviewItemsAction => ({
  type: REVIEW_ITEMS,
  itemId,
  items,
  sectionId,
});

export const ResultsLoaded = (
  newState: Partial<State>
): PartialStateAction => ({
  type: RESULTS_LOADED,
  newState,
});

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  State,
  unknown,
  Action<string>
>;

export type AppDispatch = ThunkDispatch<State, unknown, Action>;

export const CombineActions = (
  ...actions: Array<Action | AppThunk>
): AppThunk => {
  return (dispatch) => {
    actions.forEach(dispatch);
  };
};
