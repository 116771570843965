import { connect } from 'react-redux';
import {
  Action,
  Dispatch,
} from 'redux';

import { selectionRange } from '../../utils/selection';
import {
  ContentPointerDown,
  ContentPointerUp,
  ItemResponseSelected,
} from '../app/actions';
import State from '../app/state';
import {
  Actions,
  Item,
  Properties,
} from '../components/Item';

const mapStateToProps = (state: State): Properties => {
  let id = state.navigation.itemIdentifiers[state.navigation.currentItemIndex];
  let itemDef = state.itemContent.items[id];
  let itemState = state.itemState[id];

  let primaryLabel: string | null = null;
  let secondaryLabel: string | null = null;

  if (state.section.isContent) {
    let questionNumber = state.navigation.itemIdentifiers.indexOf(id) + 1;
    let passageId = itemDef.secondaryContent;

    let startIndex = questionNumber - 1;
    let endIndex = questionNumber - 1;
    let setMax = state.navigation.itemIdentifiers.length;

    for (let index = endIndex; index < setMax; index++)
      if (
        state.itemContent.items[state.navigation.itemIdentifiers[index]]
          .secondaryContent === passageId
      )
        endIndex = index;
      else break;

    for (let index = startIndex; index >= 0; index--)
      if (
        state.itemContent.items[state.navigation.itemIdentifiers[index]]
          .secondaryContent === passageId
      )
        startIndex = index;
      else break;

    primaryLabel = `Question ${questionNumber}`;

    if (!!passageId) {
      let passages = Object.values(state.itemContent.items)
        .filter((def) => !!def.secondaryContent)
        .map((def) => def.secondaryContent)
        .filter((val, index, vals) => vals.indexOf(val) === index);
      secondaryLabel = `Passage ${passages.indexOf(passageId) + 1} (Questions ${
        startIndex + 1
      } - ${endIndex + 1})`;
    } else
      secondaryLabel = `Question${
        startIndex === endIndex
          ? ` ${startIndex + 1} is`
          : `s ${startIndex + 1} - ${endIndex + 1} are `
      }presented independently and do not refer to any passage`;
  }

  return {
    itemId: state.navigation.itemIdentifiers[state.navigation.currentItemIndex],
    split: itemDef?.split,
    primaryContentId: itemDef?.primaryContent,
    primary: state.itemContent.contentLibrary[itemDef?.primaryContent] || [],
    primaryAnnotations:
      state.itemAnnotation.annotations[itemDef?.primaryContent] || [],
    primaryLabel: primaryLabel,
    response: itemState?.response,
    secondaryContentId: itemDef?.secondaryContent,
    secondary:
      state.itemContent.contentLibrary[itemDef?.secondaryContent || ""] || [],
    secondaryAnnotations:
      state.itemAnnotation.annotations[itemDef?.secondaryContent || ""] || [],
    secondaryLabel: secondaryLabel,
    isDecisionResponse: itemDef?.responseIsDecision,
    contentCss: state.itemContent.contentCss || "",
    currentAnnotationRange: state.itemAnnotation.currentAnnotationRange,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): Actions => {
  return {
    onResponseSelected: (
      item: string,
      response: string,
      currentResponse: string,
      isDecisionResponse: boolean
    ) =>
      dispatch(
        ItemResponseSelected(
          item,
          response,
          currentResponse,
          isDecisionResponse
        )
      ),
    onContentPointerUp: (item: string, content: string, containerId: string) => {
      const selection = window.getSelection();
      const range = (selection?.rangeCount || 0) > 0
        ? selection?.getRangeAt(0) || document.createRange()
        : document.createRange();
      return dispatch(
        ContentPointerUp(
          item,
          content,
          containerId,
          selectionRange(containerId, range),
        )
      );
    },
    onContentPointerDown: (
      item: string,
      content: string,
      containerId: string
    ) => dispatch(ContentPointerDown(item, content, containerId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Item);
