import React, { FunctionComponent } from 'react';

import {
  Button,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from '@material-ui/core';

export interface Properties {
  uploadFile: File | undefined;
  uploadProcessing: boolean;
  uploadSuccessful: boolean;
  uploadFailed: boolean;
}

export interface Actions {
  onFileSelected: (file: File) => void;
}

type AllProperties = Properties & Actions;

export const UploadPackage: FunctionComponent<AllProperties> = ({
  uploadFile,
  uploadProcessing,
  uploadSuccessful,
  uploadFailed,
  onFileSelected,
}) => {
  return (
    <Container className="query-results admin">
      <Grid container>
        <Grid item xs={12} className="upload-exam-container">
          <Button
            variant="contained"
            component="label"
            className="select-file-button"
          >
            Upload Package
            <input
              type="file"
              hidden
              onChange={(event) => {
                event.target?.files &&
                  event.target.files[0] &&
                  onFileSelected(event.target.files[0]);
                event.target.value = "";
              }}
            />
          </Button>
          <Typography display="inline" className="upload-status">
            {uploadProcessing && `Uploading ${uploadFile?.name}...`}
            {uploadSuccessful && `Upload Successful`}
            {uploadFailed && `Upload Failed`}
          </Typography>
          {uploadProcessing && <CircularProgress />}
        </Grid>
      </Grid>
    </Container>
  );
};
