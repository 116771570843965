import {
  FunctionComponent,
  useEffect,
} from 'react';

import { connect } from 'react-redux';

import {
  AppDispatch,
  UpdateLocationToMatchUploadPackage,
} from '../../../app/actions';
import State from '../../../Navigation/state';
import UploadPackage from './UploadPackage';

interface Properties {}

interface Actions {
  updateLocationInState: () => void;
}

type AllProperties = Properties & Actions;

const UploadPackagePage: FunctionComponent<AllProperties> = ({
  updateLocationInState,
}) => {
  useEffect(() => {
    updateLocationInState();
  });
  return <UploadPackage />;
};

const mapStateToProperties = (state: State): Properties => {
  return {};
};

const mapDispatchToActions = (dispatch: AppDispatch): Actions => {
  return {
    updateLocationInState: () => dispatch(UpdateLocationToMatchUploadPackage()),
  };
};

export default connect(
  mapStateToProperties,
  mapDispatchToActions
)(UploadPackagePage);
