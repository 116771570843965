import { Card, CardContent, Typography } from "@material-ui/core";
import React, { FunctionComponent } from "react";

export interface Properties {
  value: string;
  description: string | React.ReactNode;
  subDescription?: string;
  help?: React.ReactNode;
}

export const ResultDetailCard: FunctionComponent<Properties> = ({
  value,
  description,
  subDescription,
  help,
}) => {
  return (
    <Card variant="outlined" className="detail-card">
      <CardContent>
        <Typography variant="h4">{value}</Typography>
        <Typography variant="subtitle2" display="inline">
          {description}
        </Typography>
        {help}
        {subDescription && (
          <Typography variant="caption" className="detail-card-sub-description">
            {subDescription}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};
