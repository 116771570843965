type QueryResult<T> = {
  skip: number;
  take: number;
  totalRecords: number;
  data: Array<T>;
};

type ExamDef = {
  id: string;
  title: string;
};

type State = {
  defs: QueryResult<ExamDef>;
  extractedDef: string;
  defJsonDrawerOpen: boolean;

  fileBeingUploaded?: File;
  uploadProcessing?: boolean;
  uploadSuccessful?: boolean;
  uploadFailed?: boolean;
};

export default State;

export const DefaultState: State = {
  defs: {
    skip: 0,
    take: 10,
    totalRecords: 0,
    data: [],
  },
  extractedDef: "",
  defJsonDrawerOpen: false,
};
