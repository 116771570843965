import { Action, Reducer } from "redux";
import {
  PartialStateAction,
  RESULTS_LOADED,
  RESULTS_LOAD_BEGIN,
} from "../app/actions";
import State, { DefaultState } from "./state";

const reducer: Reducer<State, Action> = (state, action) => {
  if (!state) return DefaultState;

  switch (action.type) {
    case RESULTS_LOAD_BEGIN:
      return DefaultState;
    case RESULTS_LOADED:
      let newState = (action as PartialStateAction).newState.itemRationale;
      if (newState) return { ...newState };
      return state;
    default:
      return state;
  }
};

export default reducer;
