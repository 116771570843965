import {
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { FunctionComponent } from "react";
import FlagIcon from "@material-ui/icons/Flag";
import { secondsToTimeDisplay } from "../../utils/formatting";

export interface SectionData {
  id: string;
  name: string;
  minScaledScore: number;
  maxScaledScore: number;
  scaledScore: number;
  percentage: number;
  questionsCorrect: number;
  totalQuestions: number;
  incomplete: number;
  averageQuestionTimeInSeconds: number;
  flaggedCorrect: number;
  flaggedIncorrect: number;
}

export interface Properties {
  sections: Array<SectionData>;
}

export interface Actions {
  onNavigate: (to: string) => void;
}

type AllProperties = Properties & Actions;

export const SummarySectionTable: FunctionComponent<AllProperties> = ({
  sections,
  onNavigate,
}) => {
  return (
    <Grid container direction="column" className="section-summary">
      <Grid item>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Section</TableCell>
              <TableCell>Score</TableCell>
              <TableCell>Percentile</TableCell>
              <TableCell>Correct</TableCell>
              <TableCell>Incomplete</TableCell>
              <TableCell>Average TPQ</TableCell>
              <TableCell>
                <FlagIcon /> Correct
              </TableCell>
              <TableCell>
                <FlagIcon /> Incorrect
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sections.map((section) => (
              <TableRow key={section.id}>
                <TableCell>
                  <Link onClick={() => onNavigate(section.id)}>
                    {section.name}
                  </Link>
                </TableCell>
                <TableCell>{section.scaledScore}</TableCell>
                <TableCell>{section.percentage}</TableCell>
                <TableCell>
                  {section.questionsCorrect} of {section.totalQuestions}
                </TableCell>
                <TableCell>{section.incomplete}</TableCell>
                <TableCell>
                  {secondsToTimeDisplay(
                    section.averageQuestionTimeInSeconds,
                    true
                  )}
                </TableCell>
                <TableCell>{section.flaggedCorrect}</TableCell>
                <TableCell>{section.flaggedIncorrect}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};
