import React, { FunctionComponent } from "react";
import { connect } from "react-redux";
import State from "../../app/state";

interface OwnProps {
  sectionId: string;
  children: (
    contentId: string,
    itemId: string,
    label: string
  ) => React.ReactNode;
}

interface ContentEntry {
  contentId: string;
  itemId: string;
  label: string;
}

interface Properties {
  content: Array<ContentEntry>;
}

type AllProperties = OwnProps & Properties;

const ForEachPieceOfContentInSection: FunctionComponent<AllProperties> = ({
  content,
  children,
}) => {
  return (
    <>
      {content.map(({ contentId, itemId, label }) => (
        <div key={contentId}>{children(contentId, itemId, label)}</div>
      ))}
    </>
  );
};

const mapStateToProps = (state: State, ownProps: OwnProps): Properties => {
  let itemNumber = 0;
  let passageNumber = 0;
  let content: Array<ContentEntry> = [];
  let items = state.itemContent.sectionItems[ownProps.sectionId];
  let hasNotBeenEntered = (entry: string) =>
    entry && !content.find((c) => c.contentId === entry);
  items.forEach((itemId) => {
    let item = state.itemContent.items[itemId];
    let primary = item.primaryContent;
    let secondary = item.secondaryContent;

    if (secondary && hasNotBeenEntered(secondary))
      content.push({
        contentId: secondary,
        itemId,
        label: `Passage ${++passageNumber}`,
      });

    content.push({
      contentId: primary,
      itemId,
      label: `Item ${++itemNumber}`,
    });
  });
  return {
    content,
  };
};

export default connect(mapStateToProps)(ForEachPieceOfContentInSection);
