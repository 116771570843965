import { connect } from 'react-redux';

import {
  AppDispatch,
  BEGIN_END_SECTION,
  ChainActions,
  CombineActions,
  COMPLETE_END_SECTION,
  DialogOpen,
  DialogToggleOpen,
  END_EXAM,
  END_SECTION,
  NEXT,
  PREVIOUS,
  REVIEW_ALL,
  REVIEW_FLAGGED,
  REVIEW_INCOMPLETE,
  REVIEW_SCREEN,
  SaveExamState,
  SaveSectionState,
} from '../app/actions';
import State from '../app/state';
import {
  Actions,
  NavigationBar,
  Properties,
} from '../components/NavigationBar';
import {
  EndSectionIncompleteWarningDialog,
  ItemResponseRequiredDialog,
  NavigationDialog,
  PeriodicTableDialog,
} from '../Dialog/state';
import { isFirstItem } from '../Navigation/reducer';

const mapStateToProps = (state: State): Properties => {
  let onLastItem =
    state.navigation.currentItemIndex ===
    state.navigation.itemIdentifiers.length - 1;
  let noReviewScreen = !state.navigation.reviewable;

  let items = state.navigation.itemIdentifiers;
  let completable = items.filter(
    (id) => state.itemContent.items[id].completable
  ).length;
  let completed = state.navigation.itemsCompleted.length;

  let item =
    state.navigation.itemIdentifiers[state.navigation.currentItemIndex];
  let itemIsRequired = state.navigation.responsesRequiredFor.indexOf(item) >= 0;
  let itemIsCompleted = state.navigation.itemsCompleted.indexOf(item) >= 0;

  return {
    next: state.navigation.next,
    navigation: state.navigation.navigation,
    previous: state.navigation.previous && !isFirstItem(state.navigation),
    endExam: state.navigation.endExam,
    periodicTable:
      state.navigation.periodicTable && !state.navigation.suppressPeriodicTable,
    endSection: state.navigation.endSection,
    reviewAll: state.navigation.reviewAll,
    reviewIncomplete: state.navigation.reviewIncomplete,
    reviewFlagged: state.navigation.reviewFlagged,
    reviewScreen: state.navigation.reviewScreen,
    treatNextAsEndSection: onLastItem && noReviewScreen,
    completableItems: completable,
    completedItems: completed,
    warnIfNotAllItemsCompleted: state.navigation.warnOnIncompleteSubmission,
    itemIsCompleted: itemIsCompleted,
    itemIsRequired: itemIsRequired,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch): Actions => {
  return {
    onNextClicked: () =>
      dispatch(CombineActions({ type: NEXT }, SaveSectionState())),
    onNavigationClicked: () => dispatch(DialogToggleOpen(NavigationDialog)),
    onPreviousClicked: () =>
      dispatch(CombineActions({ type: PREVIOUS }, SaveSectionState())),
    onEndExamClicked: () =>
      dispatch(
        CombineActions(SaveSectionState(), { type: END_EXAM }, SaveExamState())
      ),
    onPeriodicTableClicked: () =>
      dispatch(DialogToggleOpen(PeriodicTableDialog)),
    onEndSectionClicked: () =>
      dispatch(
        ChainActions(
          { type: BEGIN_END_SECTION },
          SaveSectionState(),
          { type: END_SECTION },
          SaveExamState(),
          { type: COMPLETE_END_SECTION }
        )
      ),
    onReviewAllClicked: () =>
      dispatch(CombineActions({ type: REVIEW_ALL }, SaveSectionState())),
    onReviewIncompleteClicked: () =>
      dispatch(CombineActions({ type: REVIEW_INCOMPLETE }, SaveSectionState())),
    onReviewFlaggedClicked: () =>
      dispatch(CombineActions({ type: REVIEW_FLAGGED }, SaveSectionState())),
    onReviewScreenClicked: () =>
      dispatch(CombineActions({ type: REVIEW_SCREEN }, SaveSectionState())),
    onEndSectionWithPendingItemsWarning: () =>
      dispatch(DialogOpen(EndSectionIncompleteWarningDialog)),
    onRequiredItemAbandoned: () =>
      dispatch(DialogOpen(ItemResponseRequiredDialog)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavigationBar);
