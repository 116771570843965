import { TokenCache } from "../utils/token";
import settings from "./settings";

const checkoutSessionResource = (id: string) =>
  `${settings.baseAddress}/checkout/product/${id}`;

export const getCheckoutSession = (id: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    fetch(checkoutSessionResource(id), {
      method: "POST",
      headers: {
        Authorization: `${TokenCache.provider} ${TokenCache.token}`,
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error();
        return response;
      })
      .then((resp) => resp.text())
      .then(resolve)
      .catch(reject);
  });
};
