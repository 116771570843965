import { connect } from 'react-redux';

import State from '../../../../app/state';
import {
  ContentDefinition,
  ManageContentDisplayContentDefinitions,
  Properties,
} from '../../../../components/ManageContentDisplayContentDefinitions';

const mapStateToProperties = (state: State): Properties => {
  return {
    data: (state.manageContent.queryResults?.data ||
      []) as Array<ContentDefinition>,
  };
};

export default connect(mapStateToProperties)(
  ManageContentDisplayContentDefinitions
);
