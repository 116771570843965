import React, { FunctionComponent, useEffect } from "react";
import { connect } from "react-redux";
import { UpdateLocationToMatchManageProducts } from "../../../app/actions";
import { AppDispatch } from "../../../app/actions";
import ManageProducts from "./ManageProducts";

interface Actions {
  updateLocationInState: () => void;
}
const ManageProductsPage: FunctionComponent<Actions> = ({
  updateLocationInState,
}) => {
  useEffect(() => {
    updateLocationInState();
  }, [updateLocationInState]);
  return <ManageProducts />;
};

const mapDispatchToActions = (dispatch: AppDispatch): Actions => {
  return {
    updateLocationInState: () =>
      dispatch(UpdateLocationToMatchManageProducts()),
  };
};

export default connect(() => ({}), mapDispatchToActions)(ManageProductsPage);
