type State = {
  id: string;
  examDefinitionId: string;
  loading: boolean;
  ending: boolean;
  isOptional?: boolean;
  optionalMessageContent?: string;
  isBreak?: boolean;
  breakMessageContent?: string;
  isContent: boolean;
  showQuestionCounter: boolean;
};

export const Default: State = {
  id: "",
  examDefinitionId: "",
  ending: false,
  loading: false,
  isContent: false,
  showQuestionCounter: false,
};

export default State;
