type Run = {
  examId: string;
  createdOn: string;
  isCompleted: boolean;
  timeMultiplier: TimeMultiplier;
};

export type TimeMultiplier = {
  value: number;
  type: 'Value' | 'Unlimited';
};

export type Purchase = {
  id: string;
  name: string;
  description: string;
  examRuns: Array<Run>;
  runsExpanded: boolean;
};

type State = Array<Purchase>;

export default State;

export const DefaultState: State = [];
