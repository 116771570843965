type QueryResult<T> = {
  skip: number;
  take: number;
  totalRecords: number;
  data: Array<T>;
};

type ResponseOption = {
  id: string;
  label: string;
  content: string;
};

type PieceOfContentType = "HtmlContent" | "MultipleChoiceResponseContent";

type PieceOfContent = {
  typeOfContent: PieceOfContentType;
  content: string;
  options: Array<ResponseOption>;
};

type Content = Array<PieceOfContent>;

type ContentDefinition = {
  id: string;
  correctResponse: string;
  skills: Array<string>;
  isContentQuestion: boolean;
  isPassage: boolean;
  content: Content;
};

type Skill = {
  id: string;
  groupId: string;
  label: string;
  description: string;
};

type SkillGroup = {
  id: string;
  label: string;
  description: string;
};

export const ContentDefinitionsTab = "ContentDefinitionsTab";
export const SkillsTab = "SkillsTab";
export const SkillGroupsTab = "SkillGroupsTab";
export const AssetsTab = "AssetsTab";

type State = {
  tab: string;
  queryResults?: QueryResult<ContentDefinition | Skill | SkillGroup>;

  showFormat: boolean;

  fileBeingUploaded?: File;
  uploadProcessing?: boolean;
  uploadSuccessful?: boolean;
  uploadFailed?: boolean;
};

export default State;

export const DefaultState: State = {
  tab: ContentDefinitionsTab,
  showFormat: false,
};

export const ContentDefinitionUploadFormat = [
  {
    id: "string",
    correctResponse: "string",
    skills: ["string"],
    isContentQuestion: false,
    isPassage: false,
    content: [
      {
        type: "HtmlContent",
        content: "HTML content",
      },
      {
        type: "MultipleChoiceContent",
        options: [
          {
            id: "A",
            label: "A",
            content: "some option content 1",
          },
          {
            id: "B",
            label: "B",
            content: "some option content 2",
          },
          {
            id: "C",
            label: "C",
            content: "some option content 3",
          },
          {
            id: "D",
            label: "D",
            content: "some option content 4",
          },
        ],
        rationale: [
          "rationale one",
          "rationale two",
          "rationale three",
          "rationale four",
        ],
      },
    ],
  },
];

export const SkillsUploadFormat = [
  {
    id: "string",
    groupId: "string",
    label: "string",
    description: "string",
  },
];

export const SkillGroupsUploadFormat = [
  {
    id: "string",
    label: "string",
    description: "string",
  },
];
