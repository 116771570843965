import React, {
  FunctionComponent,
  useEffect,
} from 'react';

import useDeepCompareEffect from 'use-deep-compare-effect';

import { annotateInPlace } from '../../utils/annotation';
import {
  useDocumentEventHandler,
} from '../../utils/hooks/setDocumentEventHandler';
import { onSingleHotkey } from '../../utils/hotkeys';
import { PieceOfContent } from '../ItemContent/state';

interface AnnotationRange {
  start: number;
  end: number;
}

interface AnnotationDecoration {
  type: "highlight" | "strikethrough";
  subtype: string;
}

interface Annotation {
  range: AnnotationRange;
  decorations: Array<AnnotationDecoration>;
}

type ResponseOption = {
  id: string;
  label: string;
  content: string;
};

type MultipleChoiceResponseContent = {
  options: Array<ResponseOption>;
};

type ItemContent = Array<PieceOfContent>;

export interface Properties {
  itemId: string;
  split: boolean;
  primaryContentId: string;
  primary: ItemContent;
  primaryAnnotations: Array<Annotation>;
  primaryLabel: string | null;
  response: string;
  secondaryContentId?: string;
  secondary?: ItemContent;
  secondaryAnnotations: Array<Annotation>;
  secondaryLabel: string | null;
  isDecisionResponse?: boolean;
  contentCss: any;
  currentAnnotationRange?: AnnotationRange;
}

export interface Actions {
  onResponseSelected: (
    itemId: string,
    response: string,
    currentResponse: string,
    isDecisionResponse: boolean
  ) => void;
  onContentPointerUp: (
    itemId: string,
    contentId: string,
    containerId: string
  ) => void;
  onContentPointerDown: (
    itemId: string,
    contentId: string,
    containerId: string
  ) => void;
}

type AllProperties = Properties & Actions;

export const Item: FunctionComponent<AllProperties> = ({
  itemId,
  split,
  primaryContentId,
  primary,
  primaryAnnotations,
  primaryLabel,
  response,
  secondaryContentId,
  secondary,
  secondaryAnnotations,
  secondaryLabel,
  isDecisionResponse,
  contentCss,
  currentAnnotationRange,
  onResponseSelected,
  onContentPointerUp,
  onContentPointerDown,
}) => {
  useDeepCompareEffect(() => {
    let getRenderedAnnotations = () =>
      document.querySelectorAll(".content-container .user-annotation");

    let clearAnnotations = (annotations: NodeListOf<Element>) => {
      annotations.forEach(
        (annotation) => (annotation.outerHTML = annotation.innerHTML)
      );
    };

    let annotations = getRenderedAnnotations();
    do {
      clearAnnotations(annotations);
    } while ((annotations = getRenderedAnnotations()).length > 0);

    annotateInPlace(
      document.getElementById("primary-selection-source")!,
      primaryAnnotations
    );

    annotateInPlace(
      document.getElementById("secondary-selection-source")!,
      secondaryAnnotations
    );
  }, [primaryAnnotations, secondaryAnnotations]);

  useEffect(() => {
    let hotkeyListeners: Array<() => void> = [];
    let responseObj = primary.find(
      (entry) => entry.typeOfContent === "MultipleChoiceResponseContent"
    );
    if (responseObj) {
      hotkeyListeners = (
        responseObj as MultipleChoiceResponseContent
      ).options.map((option) =>
        onSingleHotkey(option.label.toLowerCase(), () =>
          onResponseSelected(itemId, option.id, response, !!isDecisionResponse)
        )
      );
    }

    return () => hotkeyListeners.forEach((unsub) => unsub());
  }, [isDecisionResponse, itemId, onResponseSelected, primary, response]);

  useEffect(() => {
    document.getElementById("primary-scrollable-container")?.scrollTo(0, 0);
  }, [primaryContentId]);

  useEffect(() => {
    document.getElementById("secondary-scrollable-container")?.scrollTo(0, 0);
  }, [secondaryContentId]);

  const [onNextPointerUp] = useDocumentEventHandler("pointerup", {
    once: true,
  });

  return (
    <div className={`content-container ${split && "split"}`}>
      <style>{contentCss}</style>
      {split && (
        <>
          <div
            id="secondary-scrollable-container"
            className="content scrollable"
            onPointerDown={() => {
              onContentPointerDown(
                itemId,
                secondaryContentId || "",
                "secondary-selection-source"
              );

              onNextPointerUp(() => {
                onContentPointerUp(
                  itemId,
                  secondaryContentId || "",
                  "secondary-selection-source"
                );
              });
            }}
          >
            <div className="content-label secondary">{secondaryLabel}</div>
            <div id="secondary-selection-source">
              {secondary?.map((piece, index) => (
                <div className="content-element" key={index}>
                  {piece.typeOfContent === "HtmlContent" && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: piece.content || "",
                      }}
                    ></div>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className="splitter" />
        </>
      )}
      <div
        id="primary-scrollable-container"
        className="content scrollable"
        onPointerDown={() => {
          onContentPointerDown(
            itemId,
            primaryContentId,
            "primary-selection-source"
          );

          onNextPointerUp(() => {
            onContentPointerUp(
              itemId,
              primaryContentId,
              "primary-selection-source"
            );
          });
        }}
      >
        <div className="content-label primary">{primaryLabel}</div>
        <div id="primary-selection-source">
          {primary?.map((piece, index) => (
            <div className="content-element" key={index}>
              {piece.typeOfContent === "HtmlContent" && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: piece.content || "",
                  }}
                ></div>
              )}
              {piece.typeOfContent === "MultipleChoiceResponseContent" && (
                <div className="multiple-choice-responses">
                  {piece.options.map((option) => (
                    <div
                      key={option.id}
                      onClick={() =>
                        onResponseSelected(
                          itemId,
                          option.id,
                          response,
                          !!isDecisionResponse
                        )
                      }
                      className="multiple-choice-response"
                    >
                      <span
                        className={`choice-radio ${
                          response === option.id ? "selected" : ""
                        }`}
                      ></span>
                      <span className="label">{option.label}</span>
                      <span
                        className="choice-content"
                        dangerouslySetInnerHTML={{ __html: option.content }}
                      ></span>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
