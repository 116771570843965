import { ApplicationError } from "../utils/AppError";
import { TokenCache } from "../utils/token";
import settings from "./settings";

const contentDefinitionQueryResource = (take?: number, skip?: number) =>
  `${settings.baseAddress}/ContentDefinition?take=${take || ""}&skip=${
    skip || ""
  }`;

const contentDefinitionUploadResource = () =>
  `${settings.baseAddress}/ContentDefinition`;

export const getContentDefs = (take: number, skip: number): Promise<any> => {
  return new Promise((resolve, reject) => {
    fetch(contentDefinitionQueryResource(take, skip), {
      headers: {
        Authorization: `${TokenCache.provider} ${TokenCache.token}`,
      },
    })
      .then(async (response) => {
        if (!response.ok) throw new ApplicationError(await response.text());
        return response;
      })
      .then((response) => response.json())
      .then((data) => resolve(data))
      .catch((error) =>
        reject(
          new ApplicationError(`Unable to retrieve content definitions`, error)
        )
      );
  });
};

export const uploadContentDefs = (file: File): Promise<any> => {
  return new Promise((resolve, reject) => {
    fetch(contentDefinitionUploadResource(), {
      method: "POST",
      body: file,
      headers: {
        Authorization: `${TokenCache.provider} ${TokenCache.token}`,
      },
    })
      .then(async (response) => {
        if (!response.ok) throw new ApplicationError(await response.text());
        return response;
      })
      .then(resolve)
      .catch((error) =>
        reject(
          new ApplicationError(`Unable to upload content definitions`, error)
        )
      );
  });
};
