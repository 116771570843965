import { Action } from "redux";
import {
  DIALOG_EAST_RESIZE_MOUSE_MOVE,
  DIALOG_HEADER_MOUSE_MOVE,
  DIALOG_NORTH_RESIZE_MOUSE_MOVE,
  DIALOG_SOUTH_EAST_RESIZE_MOUSE_MOVE,
  DIALOG_SOUTH_RESIZE_MOUSE_MOVE,
  DIALOG_SOUTH_WEST_RESIZE_MOUSE_MOVE,
  DIALOG_WEST_RESIZE_MOUSE_MOVE,
} from "../../ExamApp/app/actions";
import { TICK } from "../AppTimer";

const shouldFilter = (action: Action) => {
  return (
    action.type === TICK ||
    action.type === DIALOG_HEADER_MOUSE_MOVE ||
    action.type === DIALOG_NORTH_RESIZE_MOUSE_MOVE ||
    action.type === DIALOG_SOUTH_RESIZE_MOUSE_MOVE ||
    action.type === DIALOG_EAST_RESIZE_MOUSE_MOVE ||
    action.type === DIALOG_WEST_RESIZE_MOUSE_MOVE ||
    action.type === DIALOG_SOUTH_EAST_RESIZE_MOUSE_MOVE ||
    action.type === DIALOG_SOUTH_WEST_RESIZE_MOUSE_MOVE
  );
};

export default shouldFilter;
