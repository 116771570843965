import { connect } from "react-redux";
import State from "../app/state";
import {
  TimeoutWarningHeader,
  Properties,
} from "../components/TimeoutWarningHeader";

const mapStateToProps = (state: State): Properties => {
  return {
    warningTitle: state.timing.currentWarningTitle,
  };
};

export default connect(mapStateToProps)(TimeoutWarningHeader);
