import React, { FunctionComponent } from 'react';

import {
  Button,
  Grid,
  Typography,
} from '@material-ui/core';

export interface Properties {
  skip: number;
  take: number;
  totalRecords: number;
}

export interface Actions {
  onUpdateQueryParams: (skip: number, take: number) => void;
}

type AllProperties = Properties & Actions;

export const Pagination: FunctionComponent<AllProperties> = ({
  skip,
  take,
  totalRecords,
  onUpdateQueryParams,
}) => {
  let totalPages = Math.ceil(totalRecords / take) || 1;
  let skippedPages = skip / take || 0;
  let page = skippedPages + 1;

  const onPageNumber = (page: number) => {
    onUpdateQueryParams((page - 1) * take, take);
  };

  return (
    <Grid container spacing={1} justifyContent="center">
      <Grid item>
        <Button
          className={`page-number-button first ${page === 1 && "selected"}`}
          onClick={() => onPageNumber(1)}
        >
          1
        </Button>
      </Grid>
      {page > 3 && (
        <Grid item>
          <Typography display="inline" className="page-number-gap">
            ...
          </Typography>
        </Grid>
      )}
      {page - 2 > 1 && (
        <Grid item>
          <Button
            className="page-number-button"
            onClick={() => onPageNumber(page - 2)}
          >
            {page - 2}
          </Button>
        </Grid>
      )}
      {page - 1 > 1 && (
        <Grid item>
          <Button
            className="page-number-button"
            onClick={() => onPageNumber(page - 1)}
          >
            {page - 1}
          </Button>
        </Grid>
      )}
      {page !== 1 && page !== totalPages && (
        <Grid item>
          <Button className="page-number-button selected">{page}</Button>
        </Grid>
      )}
      {page + 1 < totalPages && (
        <Grid item>
          <Button
            className="page-number-button"
            onClick={() => onPageNumber(page + 1)}
          >
            {page + 1}
          </Button>
        </Grid>
      )}
      {page + 2 < totalPages && (
        <Grid item>
          <Button
            className="page-number-button"
            onClick={() => onPageNumber(page + 2)}
          >
            {page + 2}
          </Button>
        </Grid>
      )}
      {page < totalPages - 2 && (
        <Grid item>
          <Typography display="inline" className="page-number-gap">
            ...
          </Typography>
        </Grid>
      )}
      {totalPages > 1 && (
        <Grid item>
          <Button
            className={`page-number-button last ${
              page === totalPages && "selected"
            }`}
            onClick={() => onPageNumber(totalPages)}
          >
            {totalPages}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};
