import { ApplicationError } from "../utils/AppError";
import { TokenCache } from "../utils/token";
import settings from "./settings";

const examDefinitionQueryResource = (skip: number, take: number) =>
  `${settings.baseAddress}/ExamDefinition?skip=${skip}&take=${take}`;

const examDefinitionResource = (id?: string) =>
  `${settings.baseAddress}/ExamDefinition/${id || ""}`;

const examDefinitionExtractionResource = (id: string) =>
  `${settings.baseAddress}/ExamDefinition/${id}/extract`;

export const getExamDefs = (
  skip: number,
  take: number
): Promise<Array<any>> => {
  return new Promise((resolve, reject) => {
    fetch(examDefinitionQueryResource(skip, take), {
      headers: {
        Authorization: `${TokenCache.provider} ${TokenCache.token}`,
      },
    })
      .then(async (response) => {
        if (!response.ok) throw new ApplicationError(await response.text());
        return response;
      })
      .then((response) => response.json())
      .then((data) => resolve(data))
      .catch((error) =>
        reject(
          new ApplicationError(`Unable to retrieve exam definition data`, error)
        )
      );
  });
};

export const extractExamDef = (id: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    fetch(examDefinitionExtractionResource(id), {
      headers: {
        Authorization: `${TokenCache.provider} ${TokenCache.token}`,
      },
    })
      .then(async (response) => {
        if (!response.ok) throw new ApplicationError(await response.text());
        return response;
      })
      .then((response) => response.text())
      .then(resolve)
      .catch((error) =>
        reject(
          new ApplicationError(`Unable to retrieve exam definition data`, error)
        )
      );
  });
};

export const uploadExamDef = (file: File): Promise<any> => {
  return new Promise((resolve, reject) => {
    fetch(examDefinitionResource(), {
      method: "POST",
      body: file,
      headers: {
        Authorization: `${TokenCache.provider} ${TokenCache.token}`,
      },
    })
      .then(async (response) => {
        if (!response.ok) throw new ApplicationError(await response.text());
        return response;
      })
      .then(resolve)
      .catch((error) =>
        reject(
          new ApplicationError(`Unable to retrieve exam definition data`, error)
        )
      );
  });
};
