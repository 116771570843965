import { Dialog } from "@material-ui/core";
import React, { FunctionComponent } from "react";

export interface OwnProps {
  children: React.ReactNode;
}

export interface Properties {
  open: boolean;
}

type AllProperties = OwnProps & Properties;

export const ReviewDialog: FunctionComponent<AllProperties> = ({
  open,
  children,
}) => {
  return (
    <Dialog
      open={open}
      className="review-dialog"
      classes={{ container: "full-size", paper: "full-size" }}
    >
      {children}
    </Dialog>
  );
};
