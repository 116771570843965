import React from "react";
import { connect } from "react-redux";
import { Action, Dispatch } from "redux";
import {
  DialogClose,
  DialogEastResizeUp,
  DialogEastResizeDown,
  DialogEastResizeMove,
  DialogHeaderMouseDown,
  DialogHeaderMouseMove,
  DialogHeaderMouseUp,
  DialogNorthResizeDown,
  DialogNorthResizeMove,
  DialogNorthResizeUp,
  DialogSouthEastResizeDown,
  DialogSouthEastResizeMove,
  DialogSouthEastResizeUp,
  DialogSouthResizeDown,
  DialogSouthResizeMove,
  DialogSouthResizeUp,
  DialogSouthWestResizeDown,
  DialogSouthWestResizeMove,
  DialogSouthWestResizeUp,
  DialogWestResizeDown,
  DialogWestResizeMove,
  DialogWestResizeUp,
} from "../app/actions";
import State from "../app/state";
import { Actions, Dialog, OwnProps, Properties } from "../components/Dialog";

const mapStateToProps = (state: State, ownProps: OwnProps): Properties => {
  if (!state.dialog[ownProps.dialogName])
    return {
      open: false,
      top: 0,
      left: 0,
      width: 0,
      height: 0,
    };

  return {
    open: state.dialog[ownProps.dialogName].open,
    top: state.dialog[ownProps.dialogName].top,
    left: state.dialog[ownProps.dialogName].left,
    width: state.dialog[ownProps.dialogName].width,
    height: state.dialog[ownProps.dialogName].height,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<Action>,
  ownProps: OwnProps
): Actions => {
  return {
    onDialogCloseClicked: () => dispatch(DialogClose(ownProps.dialogName)),

    onHeaderMouseDown: (event: React.PointerEvent) =>
      dispatch(DialogHeaderMouseDown(ownProps.dialogName, event)),
    onHeaderMouseUp: (event: React.PointerEvent) =>
      dispatch(DialogHeaderMouseUp(ownProps.dialogName, event)),
    onHeaderMouseMove: (event: React.PointerEvent) =>
      dispatch(DialogHeaderMouseMove(ownProps.dialogName, event)),

    onSouthWestResizeDown: (event: React.PointerEvent) =>
      dispatch(DialogSouthWestResizeDown(ownProps.dialogName, event)),
    onSouthWestResizeUp: (event: React.PointerEvent) =>
      dispatch(DialogSouthWestResizeUp(ownProps.dialogName, event)),
    onSouthWestResizeMove: (event: React.PointerEvent) =>
      dispatch(DialogSouthWestResizeMove(ownProps.dialogName, event)),

    onSouthEastResizeDown: (event: React.PointerEvent) =>
      dispatch(DialogSouthEastResizeDown(ownProps.dialogName, event)),
    onSouthEastResizeUp: (event: React.PointerEvent) =>
      dispatch(DialogSouthEastResizeUp(ownProps.dialogName, event)),
    onSouthEastResizeMove: (event: React.PointerEvent) =>
      dispatch(DialogSouthEastResizeMove(ownProps.dialogName, event)),

    onNorthResizeDown: (event: React.PointerEvent) =>
      dispatch(DialogNorthResizeDown(ownProps.dialogName, event)),
    onNorthResizeUp: (event: React.PointerEvent) =>
      dispatch(DialogNorthResizeUp(ownProps.dialogName, event)),
    onNorthResizeMove: (event: React.PointerEvent) =>
      dispatch(DialogNorthResizeMove(ownProps.dialogName, event)),

    onSouthResizeDown: (event: React.PointerEvent) =>
      dispatch(DialogSouthResizeDown(ownProps.dialogName, event)),
    onSouthResizeUp: (event: React.PointerEvent) =>
      dispatch(DialogSouthResizeUp(ownProps.dialogName, event)),
    onSouthResizeMove: (event: React.PointerEvent) =>
      dispatch(DialogSouthResizeMove(ownProps.dialogName, event)),

    onEastResizeDown: (event: React.PointerEvent) =>
      dispatch(DialogEastResizeDown(ownProps.dialogName, event)),
    onEastResizeUp: (event: React.PointerEvent) =>
      dispatch(DialogEastResizeUp(ownProps.dialogName, event)),
    onEastResizeMove: (event: React.PointerEvent) =>
      dispatch(DialogEastResizeMove(ownProps.dialogName, event)),

    onWestResizeDown: (event: React.PointerEvent) =>
      dispatch(DialogWestResizeDown(ownProps.dialogName, event)),
    onWestResizeUp: (event: React.PointerEvent) =>
      dispatch(DialogWestResizeUp(ownProps.dialogName, event)),
    onWestResizeMove: (event: React.PointerEvent) =>
      dispatch(DialogWestResizeMove(ownProps.dialogName, event)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dialog);
