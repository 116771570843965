type ResponseOption = {
  id: string;
  label: string;
  content: string;
};

export type MultipleChoiceResponseContent = {
  options: Array<ResponseOption>;
};

export type Content = Array<PieceOfContent>;

export type PieceOfContentType =
  | "HtmlContent"
  | "MultipleChoiceResponseContent";

export type PieceOfContent = {
  typeOfContent: PieceOfContentType;
  content: string;
  options: Array<ResponseOption>;
};

type ItemContentDefinition = {
  id: string;

  primaryContent: string;
  secondaryContent?: string;

  correctResponse: string;
  skills: Array<string>;
};

type State = {
  contentLibrary: Record<string, Content>;
  items: Record<string, ItemContentDefinition>;
  sectionItems: Record<string, Array<string>>;
  contentCss?: string;
};

export const Default: State = {
  contentLibrary: {},
  items: {},
  sectionItems: {},
  contentCss:
    ".content { padding: 40px; font-family: Verdana; font-size: 16px }",
};

export default State;
