import { Typography } from "@material-ui/core";
import React, { FunctionComponent } from "react";

export interface OwnProps {
  sectionId: string;
}

export interface Properties {
  content: string;
}

export interface Actions {
  onNavigate: () => void;
}

type AllProperties = OwnProps & Properties & Actions;

export const HeaderNavLink: FunctionComponent<AllProperties> = ({
  sectionId,
  content,
  onNavigate,
}) => {
  return (
    <Typography className="nav-button" onClick={onNavigate}>
      {content}
    </Typography>
  );
};
