import { connect } from "react-redux";
import {
  AppDispatch,
  ChainActions,
  CreateNewProductDefinition,
  PRODUCT_DEFINITION_NEW_COMPLETED,
  UpdateNewProduct,
} from "../../../app/actions";
import State from "../../../app/state";
import {
  Actions,
  ManageProductsCreateNew,
  Product,
  Properties,
} from "../../../components/ManageProductsCreateNew";
import {
  createProduct,
  fetchProducts,
  serializeAndUpdateNewProductDefLogo,
} from "../../../ManageProducts/reducer";

const mapStateToProps = (state: State): Properties => {
  return {
    newProduct: state.manageProducts.newProduct,
  };
};

const mapDispatchToActions = (dispatch: AppDispatch): Actions => {
  return {
    createNewProduct: () => dispatch(CreateNewProductDefinition()),
    updateNewProduct: (product: Product) => dispatch(UpdateNewProduct(product)),
    acceptNewProduct: () =>
      dispatch(ChainActions(createProduct(), fetchProducts())),
    cancelNewProduct: () =>
      dispatch({ type: PRODUCT_DEFINITION_NEW_COMPLETED }),
    updateNewProductLogo: (format: string, file: File) =>
      dispatch(serializeAndUpdateNewProductDefLogo(format, file)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToActions
)(ManageProductsCreateNew);
