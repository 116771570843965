import { Action } from "redux";

export class ApplicationError extends Error {
  constructor(
    message: string,
    public underlyingError?: ApplicationError | Error | undefined
  ) {
    super(message);
  }
}

export const UNRECOVERABLE_EXCEPTION = "UNRECOVERABLE_EXCEPTION";

export interface ExceptionAction extends Action {
  error: ApplicationError;
}

export const UnrecoverableException = (error: Error): ExceptionAction => ({
  type: UNRECOVERABLE_EXCEPTION,
  error,
});

export type ErrorState = {
  error?: ApplicationError;
};

export interface AppStateContainingError {
  errorState: ErrorState;
}

export const errorCatchingReducer = (
  state: ErrorState,
  action: Action
): ErrorState => {
  if (!state) return {};
  switch (action.type) {
    case UNRECOVERABLE_EXCEPTION:
      return { error: (action as ExceptionAction).error };
    default:
      return state;
  }
};
