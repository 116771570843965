import { Action, Reducer } from "redux";
import {
  DIALOG_HEADER_MOUSE_DOWN,
  DIALOG_HEADER_MOUSE_MOVE,
  DIALOG_HEADER_MOUSE_UP,
  DIALOG_SOUTH_EAST_RESIZE_MOUSE_UP,
  DIALOG_SOUTH_WEST_RESIZE_MOUSE_UP,
  DIALOG_SOUTH_EAST_RESIZE_MOUSE_DOWN,
  DIALOG_SOUTH_WEST_RESIZE_MOUSE_DOWN,
  DIALOG_SOUTH_EAST_RESIZE_MOUSE_MOVE,
  DIALOG_SOUTH_WEST_RESIZE_MOUSE_MOVE,
  DIALOG_NORTH_RESIZE_MOUSE_UP,
  DIALOG_SOUTH_RESIZE_MOUSE_UP,
  DIALOG_EAST_RESIZE_MOUSE_UP,
  DIALOG_WEST_RESIZE_MOUSE_UP,
  DIALOG_NORTH_RESIZE_MOUSE_DOWN,
  DIALOG_SOUTH_RESIZE_MOUSE_DOWN,
  DIALOG_EAST_RESIZE_MOUSE_DOWN,
  DIALOG_WEST_RESIZE_MOUSE_DOWN,
  DIALOG_NORTH_RESIZE_MOUSE_MOVE,
  DIALOG_SOUTH_RESIZE_MOUSE_MOVE,
  DIALOG_EAST_RESIZE_MOUSE_MOVE,
  DIALOG_WEST_RESIZE_MOUSE_MOVE,
  DIALOG_TOGGLE_OPEN,
  DialogAction,
  DIALOG_CLOSE,
  PointerAction,
  NEXT,
  PREVIOUS,
  ITEM_NAVIGATION,
  REVIEW_SCREEN,
  SECTION_LOADED,
  PartialStateAction,
  DIALOG_OPEN,
  BEGIN_LOAD_EXAM,
} from "../app/actions";
import State, { Default, DefaultDialog } from "./state";

const toggleOpen = (state: State, dialog: string): State => {
  let dialogState = state[dialog];
  if (!dialogState) dialogState = DefaultDialog;
  if (dialogState.open)
    return { ...state, [dialog]: { ...dialogState, open: false } };
  else return open(state, dialog);
};

const open = (state: State, dialog: string): State => {
  let dialogState = state[dialog];
  if (!dialogState) dialogState = DefaultDialog;
  if (dialogState.open) return state;

  let height = Math.min(
    dialogState.maxHeight || Infinity,
    dialogState.resetSizeOnOpen ? dialogState.defaultHeight : dialogState.height
  );
  let width = Math.min(
    dialogState.maxWidth || Infinity,
    dialogState.resetSizeOnOpen ? dialogState.defaultWidth : dialogState.width
  );

  let top = dialogState.centerOnOpen
    ? (window.innerHeight - height) / 2
    : dialogState.top;
  let left = dialogState.centerOnOpen
    ? (window.innerWidth - width) / 2
    : dialogState.left;

  return {
    ...state,
    [dialog]: {
      ...dialogState,
      open: true,
      top,
      left,
      height,
      width,
    },
  };
};

const close = (state: State, dialog: string): State => {
  let dialogState = state[dialog];
  if (!dialogState) return state;
  if (!dialogState.open) return state;
  return { ...state, [dialog]: { ...dialogState, open: false } };
};

const dismissOnNavigationIfApplicable = (state: State): State => {
  let newState = Object.assign({}, state);

  for (let dialog in state)
    if (state[dialog].dismissOnNavigation) state[dialog].open = false;

  return newState;
};

const startMove = (state: State, dialog: string): State => {
  let dialogState = state[dialog];
  if (!dialogState) return state;
  if (!dialogState.open) return state;
  return { ...state, [dialog]: { ...dialogState, isMoving: true } };
};

const stopMove = (state: State, dialog: string): State => {
  let dialogState = state[dialog];
  if (!dialogState) return state;
  if (!dialogState.open) return state;
  return { ...state, [dialog]: { ...dialogState, isMoving: false } };
};

const startResize = (state: State, dialog: string): State => {
  let dialogState = state[dialog];
  if (!dialogState) return state;
  if (!dialogState.open) return state;
  return { ...state, [dialog]: { ...dialogState, isResizing: true } };
};

const stopResize = (state: State, dialog: string): State => {
  let dialogState = state[dialog];
  if (!dialogState) return state;
  if (!dialogState.open) return state;
  return { ...state, [dialog]: { ...dialogState, isResizing: false } };
};

const onMove = (state: State, dialog: string, event: PointerAction): State => {
  let dialogState = state[dialog];
  if (!dialogState) return state;
  if (!dialogState.open) return state;

  if (!dialogState.isMoving) return state;

  let topEdge = dialogState.topBoundary || -Infinity;
  let leftEdge = dialogState.leftBoundary || -Infinity;
  let rightEdge = (dialogState.rightBoundary || Infinity) - dialogState.width;
  let bottomEdge =
    (dialogState.bottomBoundary || Infinity) - dialogState.height;

  return {
    ...state,
    [dialog]: {
      ...dialogState,
      left: Math.min(
        Math.max(leftEdge, dialogState.left + event.movementX),
        rightEdge
      ),
      top: Math.min(
        Math.max(topEdge, dialogState.top + event.movementY),
        bottomEdge
      ),
    },
  };
};

const resizeNorth = (
  state: State,
  dialog: string,
  event: PointerAction
): State => {
  let dialogState = state[dialog];
  if (!dialogState) return state;
  if (!dialogState.open) return state;

  if (!dialogState.isResizing) return state;

  let newHeight = dialogState.height - event.movementY;

  if (
    newHeight >= (dialogState.maxHeight || Infinity) ||
    newHeight <= (dialogState.minHeight || -Infinity)
  )
    return state;

  return {
    ...state,
    [dialog]: {
      ...dialogState,
      height: newHeight,
      top: dialogState.top + event.movementY,
    },
  };
};

const resizeSouth = (
  state: State,
  dialog: string,
  event: PointerAction
): State => {
  let dialogState = state[dialog];
  if (!dialogState) return state;
  if (!dialogState.open) return state;

  if (!dialogState.isResizing) return state;

  let newHeight = dialogState.height + event.movementY;

  if (
    newHeight >= (dialogState.maxHeight || Infinity) ||
    newHeight <= (dialogState.minHeight || -Infinity)
  )
    return state;

  return {
    ...state,
    [dialog]: {
      ...dialogState,
      height: newHeight,
    },
  };
};

const resizeEast = (
  state: State,
  dialog: string,
  event: PointerAction
): State => {
  let dialogState = state[dialog];
  if (!dialogState) return state;
  if (!dialogState.open) return state;

  if (!dialogState.isResizing) return state;

  let newWidth = dialogState.width + event.movementX;

  if (
    newWidth >= (dialogState.maxWidth || Infinity) ||
    newWidth <= (dialogState.minWidth || -Infinity)
  )
    return state;

  return {
    ...state,
    [dialog]: {
      ...dialogState,
      width: newWidth,
    },
  };
};

const resizeWest = (
  state: State,
  dialog: string,
  event: PointerAction
): State => {
  let dialogState = state[dialog];
  if (!dialogState) return state;
  if (!dialogState.open) return state;

  if (!dialogState.isResizing) return state;

  let newWidth = dialogState.width - event.movementX;

  if (
    newWidth >= (dialogState.maxWidth || Infinity) ||
    newWidth <= (dialogState.minWidth || -Infinity)
  )
    return state;

  return {
    ...state,
    [dialog]: {
      ...dialogState,
      width: newWidth,
      left: dialogState.left + event.movementX,
    },
  };
};

const resizeSouthEast = (
  state: State,
  dialog: string,
  event: PointerAction
): State => {
  let dialogState = state[dialog];
  if (!dialogState) return state;
  if (!dialogState.open) return state;

  if (!dialogState.isResizing) return state;

  let newWidth = dialogState.width + event.movementX;
  let newHeight = dialogState.height + event.movementY;
  return {
    ...state,
    [dialog]: {
      ...dialogState,
      width: Math.min(
        dialogState.maxWidth || Infinity,
        Math.max(dialogState.minWidth || -Infinity, newWidth)
      ),
      height: Math.min(
        dialogState.maxHeight || Infinity,
        Math.max(dialogState.minHeight || -Infinity, newHeight)
      ),
    },
  };
};

const resizeSouthWest = (
  state: State,
  dialog: string,
  event: PointerAction
): State => {
  let dialogState = state[dialog];
  if (!dialogState) return state;
  if (!dialogState.open) return state;

  if (!dialogState.isResizing) return state;

  let newWidth = dialogState.width - event.movementX;
  let newHeight = dialogState.height + event.movementY;

  return {
    ...state,
    [dialog]: {
      ...dialogState,
      left:
        newWidth >= (dialogState.minWidth || -Infinity) &&
        newWidth <= (dialogState.maxWidth || Infinity)
          ? dialogState.left + event.movementX
          : dialogState.left,
      width: Math.min(
        dialogState.maxWidth || Infinity,
        Math.max(dialogState.minWidth || -Infinity, newWidth)
      ),
      height: Math.min(
        dialogState.maxHeight || Infinity,
        Math.max(dialogState.minHeight || -Infinity, newHeight)
      ),
    },
  };
};

const reducer: Reducer<State, Action> = (state, action): State => {
  if (!state) return Default;

  switch (action.type) {
    case BEGIN_LOAD_EXAM:
      return Default;
    case SECTION_LOADED:
      let newState = (action as PartialStateAction).state.dialog;
      if (newState) return { ...newState };
      return state;
    case NEXT:
    case PREVIOUS:
    case ITEM_NAVIGATION:
    case REVIEW_SCREEN:
      return dismissOnNavigationIfApplicable(state);
    case DIALOG_TOGGLE_OPEN:
      return toggleOpen(state, (action as DialogAction).dialog);
    case DIALOG_OPEN:
      return open(state, (action as DialogAction).dialog);
    case DIALOG_CLOSE:
      return close(state, (action as DialogAction).dialog);
    case DIALOG_HEADER_MOUSE_DOWN:
      return startMove(state, (action as DialogAction).dialog);
    case DIALOG_HEADER_MOUSE_UP:
      return stopMove(state, (action as DialogAction).dialog);
    case DIALOG_HEADER_MOUSE_MOVE:
      return onMove(
        state,
        (action as DialogAction).dialog,
        action as PointerAction
      );
    case DIALOG_SOUTH_EAST_RESIZE_MOUSE_UP:
    case DIALOG_SOUTH_WEST_RESIZE_MOUSE_UP:
    case DIALOG_NORTH_RESIZE_MOUSE_UP:
    case DIALOG_SOUTH_RESIZE_MOUSE_UP:
    case DIALOG_EAST_RESIZE_MOUSE_UP:
    case DIALOG_WEST_RESIZE_MOUSE_UP:
      return stopResize(state, (action as DialogAction).dialog);
    case DIALOG_SOUTH_EAST_RESIZE_MOUSE_DOWN:
    case DIALOG_SOUTH_WEST_RESIZE_MOUSE_DOWN:
    case DIALOG_NORTH_RESIZE_MOUSE_DOWN:
    case DIALOG_SOUTH_RESIZE_MOUSE_DOWN:
    case DIALOG_EAST_RESIZE_MOUSE_DOWN:
    case DIALOG_WEST_RESIZE_MOUSE_DOWN:
      return startResize(state, (action as DialogAction).dialog);
    case DIALOG_NORTH_RESIZE_MOUSE_MOVE:
      return resizeNorth(
        state,
        (action as DialogAction).dialog,
        action as PointerAction
      );
    case DIALOG_SOUTH_RESIZE_MOUSE_MOVE:
      return resizeSouth(
        state,
        (action as DialogAction).dialog,
        action as PointerAction
      );
    case DIALOG_EAST_RESIZE_MOUSE_MOVE:
      return resizeEast(
        state,
        (action as DialogAction).dialog,
        action as PointerAction
      );
    case DIALOG_WEST_RESIZE_MOUSE_MOVE:
      return resizeWest(
        state,
        (action as DialogAction).dialog,
        action as PointerAction
      );
    case DIALOG_SOUTH_EAST_RESIZE_MOUSE_MOVE:
      return resizeSouthEast(
        state,
        (action as DialogAction).dialog,
        action as PointerAction
      );
    case DIALOG_SOUTH_WEST_RESIZE_MOUSE_MOVE:
      return resizeSouthWest(
        state,
        (action as DialogAction).dialog,
        action as PointerAction
      );
    default:
      return state;
  }
};

export default reducer;
