import {
  Action,
  Reducer,
} from 'redux';

import { processContentString } from '../../utils/content-processing';
import {
  BEGIN_LOAD_EXAM,
  PartialStateAction,
  SECTION_LOADED,
} from '../app/actions';
import State, {
  Content,
  Default,
} from './state';

const processContent = (state: State | undefined): State | undefined => {
  if (!state) return state;

  let newLibrary: Record<string, Content> = {};
  Object.keys(state.contentLibrary).forEach((key) => {
    let value = state.contentLibrary[key];
    let updatedValue = value.map((entry) => {
      var entryCopy = Object.assign({}, entry);
      if (entryCopy.typeOfContent === "HtmlContent")
        entryCopy.content = processContentString(entryCopy.content);
      else if (entryCopy.typeOfContent === "MultipleChoiceResponseContent")
        entryCopy.options = entryCopy.options.map((op) => ({
          id: op.id,
          label: op.label,
          content: processContentString(op.content),
        }));

      return entryCopy;
    });
    newLibrary[key] = updatedValue;
  });

  return { ...state, contentLibrary: newLibrary };
};

const reducer: Reducer<State, Action> = (state, action) => {
  if (!state) return Default;

  switch (action.type) {
    case BEGIN_LOAD_EXAM:
      return Default;
    case SECTION_LOADED:
      let newState = processContent(
        (action as PartialStateAction).state.itemContent
      );
      if (newState) return { ...newState };
      return state;
    default:
      return state;
  }
};

export default reducer;
