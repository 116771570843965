export const MyPurchasesRoute = "/mypurchases";
export const MarketplaceRoute = "/marketplace";
export const ManageExamsRoute = "/manage/exams";
export const ManageProductsRoute = "/manage/products";
export const ManageContentRoute = "/manage/content";
export const UploadPackageRoute = "/upload/package";
export const ExaminationRoute = (id: string = ":id") => `/exam/${id}`;
export const ScoreReportRoute = (id: string = ":id") => `/report/${id}`;

type State = {
  location: string;
  id?: string;
};

export default State;

export const DefaultState: State = {
  location: MyPurchasesRoute,
};
