export const onAltHotkey = (
  onKey: string,
  callback: () => void,
  conditional: boolean = true
) => {
  if (!conditional) return () => {};

  let onKeyDown = (event: KeyboardEvent) => {
    if (!event.altKey) return;
    if (event.key === onKey) {
      event.preventDefault();
      callback();
    }
  };

  window.addEventListener("keydown", onKeyDown);

  return () => window.removeEventListener("keydown", onKeyDown);
};

export const onSingleHotkey = (
  onKey: string,
  callback: () => void,
  conditional: boolean = true
) => {
  if (!conditional) return () => {};

  let onKeyDown = (event: KeyboardEvent) => {
    if (event.key === onKey) {
      event.preventDefault();
      callback();
    }
  };

  window.addEventListener("keydown", onKeyDown);

  return () => window.removeEventListener("keydown", onKeyDown);
};
