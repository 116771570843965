import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import React, { FunctionComponent } from "react";

export interface SkillData {
  label: string;
  description: string;
  items: number;
  correct: number;
  itemIds: Array<string>;
}

export interface Properties {
  skills: Array<SkillData>;
}

export interface Actions {
  onReview: (itemIds: Array<string>) => void;
}

type AllProperties = Properties & Actions;

export const SkillsTable: FunctionComponent<AllProperties> = ({
  skills,
  onReview,
}) => {
  return (
    <Table className="skill-breakdown-table">
      <TableHead>
        <TableRow>
          <TableCell className="acronym">
            <Typography>Acronym</Typography>
          </TableCell>
          <TableCell className="description">
            <Typography>Description</Typography>
          </TableCell>
          <TableCell className="items">
            <Typography>Items</Typography>
          </TableCell>
          <TableCell className="correct">
            <Typography>Correct</Typography>
          </TableCell>
          <TableCell className="review"></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {skills.map((skill, index) => (
          <TableRow key={index}>
            <TableCell>
              <Typography>{skill.label}</Typography>
            </TableCell>
            <TableCell className="skill-description-cell">
              <Typography>{skill.description}</Typography>
            </TableCell>
            <TableCell>
              <Typography className="centered">{skill.items}</Typography>
            </TableCell>
            <TableCell>
              <Typography className="centered">{skill.correct}</Typography>
            </TableCell>
            <TableCell className="centered">
              <IconButton onClick={() => onReview(skill.itemIds)}>
                <VisibilityIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
