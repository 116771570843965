import React, { FunctionComponent, useEffect } from "react";
import { connect } from "react-redux";
import {
  AppDispatch,
  UpdateLocationToMatchMarketplace,
} from "../../app/actions";
import Marketplace from "./Marketplace";

interface Actions {
  updateLocationInState: () => void;
}

const MarketplacePage: FunctionComponent<Actions> = ({
  updateLocationInState,
}) => {
  useEffect(() => {
    updateLocationInState();
  }, [updateLocationInState]);
  return <Marketplace />;
};

const mapDispatchToActions = (dispatch: AppDispatch): Actions => {
  return {
    updateLocationInState: () => dispatch(UpdateLocationToMatchMarketplace()),
  };
};

export default connect(() => ({}), mapDispatchToActions)(MarketplacePage);
