import { Box, Container } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import { Provider } from "react-redux";

import "../assets/css/ScoreReportApp.scss";
import store from "./app/store";
import IfViewingOverallSummary from "./connections/structural/IfViewingOverallSummary";
import Report from "./connections/Report";
import Header from "./connections/Header";
import Summary from "./connections/Summary";

import ForEachSection from "./connections/structural/ForEachSection";
import IfViewingSection from "./connections/structural/IfViewingSection";
import Section from "./connections/Section";
import ReviewDialog from "./connections/review/ReviewDialog";
import ReviewHeader from "./connections/review/ReviewHeader";
import ContentContainer from "./connections/review/ContentContainer";
import InfoContainer from "./connections/review/InfoContainer";
import ErrorWrapper from "../utils/ErrorWrapper";
import LoadingSplash from "./connections/LoadingSplash";

interface AppProperties {
  reportId: string;
  onReturn: () => void;
}

const App: FunctionComponent<AppProperties> = ({ reportId, onReturn }) => {
  return (
    <Provider store={store}>
      <ErrorWrapper
        message="An error occurred while processing or viewing your report"
        details="Please refresh your browser and try again"
      >
        <Report id={reportId}>
          <LoadingSplash>
            <Header onReturn={onReturn} />
            <Container>
              <IfViewingOverallSummary>
                <Summary />
              </IfViewingOverallSummary>
              <ForEachSection>
                {(id) => (
                  <IfViewingSection key={id} sectionId={id}>
                    <Section sectionId={id} />
                  </IfViewingSection>
                )}
              </ForEachSection>
            </Container>
            <ReviewDialog>
              <ReviewHeader />
              <Box className="outer-container">
                <Box className="inner-container">
                  <ContentContainer />
                  <InfoContainer />
                </Box>
              </Box>
            </ReviewDialog>
          </LoadingSplash>
        </Report>
      </ErrorWrapper>
    </Provider>
  );
};

export default App;
