import { TokenCache } from "../utils/token";
import settings from "./settings";

const productQueryResource = (skip: number, take: number) =>
  `${settings.baseAddress}/Product?take=${take}&skip=${skip}`;

const productResource = (id: string) => `${settings.baseAddress}/Product/${id}`;

export const getProducts = (skip: number, take: number): Promise<any> => {
  return new Promise((resolve, reject) => {
    fetch(productQueryResource(skip, take), {
      headers: {
        Authorization: `${TokenCache.provider} ${TokenCache.token}`,
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error();
        return response;
      })
      .then((response) => response.json())
      .then((data) => resolve(data))
      .catch(reject);
  });
};

export const getProduct = (id: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    fetch(productResource(id), {
      headers: {
        Authorization: `${TokenCache.provider} ${TokenCache.token}`,
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error();
        return response;
      })
      .then((response) => response.json())
      .then((data) => resolve(data))
      .catch(reject);
  });
};
