import React, { FunctionComponent, useEffect } from "react";
import { connect } from "react-redux";
import { UpdateLocationToMatchManageExams } from "../../../app/actions";
import { AppDispatch } from "../../../app/actions";
import ManageExams from "./ManageExams";

interface Actions {
  updateLocationInState: () => void;
}

const ManageExamsPage: FunctionComponent<Actions> = ({
  updateLocationInState,
}) => {
  useEffect(() => {
    updateLocationInState();
  }, [updateLocationInState]);
  return <ManageExams />;
};

const mapDispatchToActions = (dispatch: AppDispatch): Actions => {
  return {
    updateLocationInState: () => dispatch(UpdateLocationToMatchManageExams()),
  };
};

export default connect(() => ({}), mapDispatchToActions)(ManageExamsPage);
