import React, { FunctionComponent } from 'react';

export interface SkillData {
  label: string;
  description: string;
  items: number;
  correct: number;
}

export interface Properties {
  width?: number;
  height?: number;
  margin?: { top: number; left: number; bottom: number; right: number };
  segmentMargin?: { left: number; right: number };
  skills: Array<SkillData>;
}

export const ContentAreaBarGraph: FunctionComponent<Properties> = ({
  width = 300,
  height = 300,
  margin = { top: 50, left: 10, bottom: 50, right: 10 },
  segmentMargin = { left: 5, right: 5 },
  skills,
}) => {
  const contentHeight = height - (margin.top + margin.bottom);
  const contentWidth = width - (margin.left + margin.right);

  const max = Math.max(...skills.map((skill) => skill.items));
  const segmentWidth = contentWidth / skills.length;
  const segmentContentWidth =
    segmentWidth - (segmentMargin.left + segmentMargin.right);
  const segmentHeight = (val: number) => (contentHeight / max) * val;

  return (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      style={{ userSelect: "none" }}
    >
      <g transform={`translate(${margin.left},${margin.top})`}>
        {skills.map((skill, index) => (
          <g key={index}>
            <rect
              fill="lightyellow"
              stroke="black"
              strokeDasharray={4}
              y={contentHeight - segmentHeight(skill.items)}
              x={segmentWidth * index + segmentMargin.left}
              width={segmentContentWidth}
              height={segmentHeight(skill.items)}
            />
            <rect
              fill="#F8B332"
              stroke="black"
              strokeWidth={2}
              y={contentHeight - segmentHeight(skill.correct)}
              x={segmentWidth * index + segmentMargin.left}
              width={segmentContentWidth}
              height={segmentHeight(skill.correct)}
            />
            <text
              textAnchor="middle"
              transform={`translate(${
                segmentWidth * index +
                segmentMargin.left +
                segmentContentWidth / 2
              } ${contentHeight + 15 + (segmentContentWidth < 30 ? 10 : 0)}) ${
                segmentContentWidth < 30 ? "rotate(60)" : ""
              }`}
            >
              {skill.label}
            </text>
          </g>
        ))}
      </g>
    </svg>
  );
};
