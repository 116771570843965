import { FunctionComponent, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router";
import { AppDispatch, UpdateLocationToMatchExam } from "../app/actions";
import State from "../app/state";
import ExamApp from "../ExamApp/App";
import { MyPurchasesRoute } from "../Navigation/state";

interface NavParams {
  id: string;
}

interface Properties {
  loggedIn: boolean;
}

interface Actions {
  updateLocationInState: (id: string) => void;
}

type AllProperties = Properties & Actions;

const Exam: FunctionComponent<AllProperties> = ({
  loggedIn,
  updateLocationInState,
}) => {
  let { id } = useParams<NavParams>();
  let history = useHistory();
  useEffect(() => {
    if (!loggedIn) history.push("/");
    else updateLocationInState(id);
  }, [id, updateLocationInState, loggedIn, history]);

  if (!loggedIn) return <></>;
  return (
    <ExamApp
      examId={id!}
      requestedAt={Date.now()}
      onCompletionOrSuspension={() => history.push(MyPurchasesRoute)}
    />
  );
};

const mapStateToProps = (state: State): Properties => {
  return {
    loggedIn: state.user.loggedIn,
  };
};

const mapDispatchToActions = (dispatch: AppDispatch): Actions => {
  return {
    updateLocationInState: (id: string) =>
      dispatch(UpdateLocationToMatchExam(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToActions)(Exam);
