import { ApplicationError } from '../utils/AppError';
import { TokenCache } from '../utils/token';
import settings from './settings';

const skillGroupQueryResource = (take?: number, skip?: number) =>
  `${settings.baseAddress}/SkillGroup?take=${take || ""}&skip=${skip || ""}`;

const skillGroupUploadResource = () => `${settings.baseAddress}/SkillGroup`;

export const getSkillGroups = (take: number, skip: number): Promise<any> => {
  return new Promise((resolve, reject) => {
    fetch(skillGroupQueryResource(take, skip), {
      headers: {
        Authorization: `${TokenCache.provider} ${TokenCache.token}`,
      },
    })
      .then(async (response) => {
        if (!response.ok) throw new ApplicationError(await response.text());
        return response;
      })
      .then((response) => response.json())
      .then((data) => resolve(data))
      .catch((error) =>
        reject(new ApplicationError(`Unable to retrieve skill groups`, error))
      );
  });
};

export const uploadSkillGroups = (file: File): Promise<any> => {
  return new Promise((resolve, reject) => {
    fetch(skillGroupUploadResource(), {
      method: "POST",
      body: file,
      headers: {
        Authorization: `${TokenCache.provider} ${TokenCache.token}`,
      },
    })
      .then(async (response) => {
        if (!response.ok) throw new ApplicationError(await response.text());
        return response;
      })
      .then(resolve)
      .catch((error) =>
        reject(new ApplicationError(`Unable to upload skill groups`, error))
      );
  });
};
