import { Action } from "redux";
import {
  RESULTS_LOAD_BEGIN,
  ReviewItemsAction,
  REVIEW_ITEMS,
  REVIEW_ITEMS_CLOSE,
  REVIEW_ITEM_FIRST,
  REVIEW_ITEM_LAST,
  REVIEW_ITEM_NEXT,
  REVIEW_ITEM_PREVIOUS,
} from "../app/actions";
import State, { DefaultState } from "./state";

const onReviewItems = (
  state: State,
  itemId: string,
  items: Array<string>,
  sectionId: string
): State => {
  return {
    itemsBeingReviewed: items,
    reviewing: true,
    itemReviewIndex: items.indexOf(itemId),
    sectionId,
  };
};

const onClose = (state: State): State => {
  return {
    ...state,
    reviewing: false,
  };
};

const onNext = (state: State): State => {
  return {
    ...state,
    itemReviewIndex: Math.min(
      state.itemReviewIndex + 1,
      state.itemsBeingReviewed.length - 1
    ),
    direction: "forward",
  };
};

const onLast = (state: State): State => {
  return {
    ...state,
    itemReviewIndex: state.itemsBeingReviewed.length - 1,
    direction: "forward",
  };
};

const onPrevious = (state: State): State => {
  return {
    ...state,
    itemReviewIndex: Math.max(state.itemReviewIndex - 1, 0),
    direction: "backward",
  };
};

const onFirst = (state: State): State => {
  return {
    ...state,
    itemReviewIndex: 0,
    direction: "backward",
  };
};

const reducer = (state: State, action: Action): State => {
  if (!state) return DefaultState;
  switch (action.type) {
    case RESULTS_LOAD_BEGIN:
      return DefaultState;
    case REVIEW_ITEMS:
      return onReviewItems(
        state,
        (action as ReviewItemsAction).itemId,
        (action as ReviewItemsAction).items,
        (action as ReviewItemsAction).sectionId
      );
    case REVIEW_ITEMS_CLOSE:
      return onClose(state);
    case REVIEW_ITEM_NEXT:
      return onNext(state);
    case REVIEW_ITEM_LAST:
      return onLast(state);
    case REVIEW_ITEM_PREVIOUS:
      return onPrevious(state);
    case REVIEW_ITEM_FIRST:
      return onFirst(state);
    default:
      return state;
  }
};

export default reducer;
