import { ApplicationError } from "../utils/AppError";
import { TokenCache } from "../utils/token";
import settings from "./settings";

const examResource = (id: string) => `${settings.baseAddress}/Exam/${id}`;
const examResetResource = (id: string) =>
  `${settings.baseAddress}/Exam/${id}/reset`;

export const getExam = (id: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    fetch(examResource(id), {
      headers: {
        Authorization: `${TokenCache.provider} ${TokenCache.token}`,
      },
    })
      .then(async (response) => {
        if (!response.ok) throw new ApplicationError(await response.text());
        return response;
      })
      .then((response) => response.json())
      .then((data) => resolve(data))
      .catch((error) =>
        reject(new ApplicationError(`Unable to retrieve exam data`, error))
      );
  });
};

export const saveExam = (id: string, data: any) => {
  return new Promise((resolve, reject) => {
    fetch(examResource(id), {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: `${TokenCache.provider} ${TokenCache.token}`,
      },
    })
      .then(async (response) => {
        if (!response.ok) throw new ApplicationError(await response.text());
        return response;
      })
      .then(resolve)
      .catch((error) =>
        reject(new ApplicationError(`Unable to save exam data`, error))
      );
  });
};

export const resetExam = (id: string) => {
  return new Promise((resolve, reject) => {
    fetch(examResetResource(id), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${TokenCache.provider} ${TokenCache.token}`,
      },
    })
      .then(async (response) => {
        if (!response.ok) throw new ApplicationError(await response.text());
        return response;
      })
      .then(resolve)
      .catch((error) =>
        reject(new ApplicationError(`Unable to reset exam data`, error))
      );
  });
};
