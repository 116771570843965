import { Container } from "@material-ui/core";
import React, { FunctionComponent, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  AppDispatch,
  UpdateLocationToMatchMyPurchases,
} from "../../app/actions";
import State from "../../app/state";
import { fetchPurchases } from "../../Purchases/reducer";
import PurchaseWrapper from "./PurchaseWrapper";

interface Properties {
  ids: Array<string>;
  loggedIn: boolean;
}

interface Actions {
  updateLocationInState: () => void;
  loadPurchases: () => void;
}

type AllProperties = Properties & Actions;

const MyPurchases: FunctionComponent<AllProperties> = ({
  ids,
  loggedIn,
  updateLocationInState,
  loadPurchases,
}) => {
  useEffect(() => {
    updateLocationInState();
    if (loggedIn) loadPurchases();
  }, [loadPurchases, updateLocationInState, loggedIn]);
  let history = useHistory();

  if (!loggedIn) return <></>;

  return (
    <Container className="purchases-container">
      {ids.map((id, index) => (
        <PurchaseWrapper key={id} id={id} index={index} history={history} />
      ))}
    </Container>
  );
};

const mapStateToProps = (state: State): Properties => {
  return {
    ids: state.purchases.map((p) => p.id),
    loggedIn: state.user.loggedIn,
  };
};

const mapDispatchToActions = (dispatch: AppDispatch): Actions => {
  return {
    updateLocationInState: () => dispatch(UpdateLocationToMatchMyPurchases()),
    loadPurchases: () => dispatch(fetchPurchases()),
  };
};

export default connect(mapStateToProps, mapDispatchToActions)(MyPurchases);
