type State = {
  title: string;
  minScaledScore: number;
  maxScaledScore: number;
  scaledScore: number;
  percent: number;
  totalQuestions: number;
  questionsCorrect: number;
  timeSpentInSeconds: number;
  dateAttempted: string;
};

export default State;

export const DefaultState: State = {
  title: "",
  minScaledScore: 0,
  maxScaledScore: 0,
  scaledScore: 0,
  percent: 0,
  totalQuestions: 0,
  questionsCorrect: 0,
  timeSpentInSeconds: 0,
  dateAttempted: "",
};
