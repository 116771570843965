import { Action, Reducer } from "redux";
import {
  ContentAction,
  CONTENT_POINTER_DOWN,
  CONTENT_POINTER_UP,
  PartialStateAction,
  HighlightSelectionAction,
  HIGHLIGHT_CLEAR_DROPDOWN_CLICKED,
  HIGHLIGHT_CLICKED,
  HIGHLIGHT_DROPDOWN_ROW_CLICKED,
  SECTION_LOADED,
  STRIKETHROUGH_CLICKED,
  ItemContentSelectionAction,
  BEGIN_LOAD_EXAM,
} from "../app/actions";
import { clearUserTextSelection } from "../../utils/selection";
import State, {
  Annotation,
  AnnotationRange,
  Default,
  CLEAR_DECORATION,
  AnnotationDecoration,
} from "./state";

const onHighlightColorSelection = (state: State, color: string): State => {
  return {
    ...state,
    currentHighlightSelection: color,
  };
};

const onHighlightDropdownClearClicked = (state: State): State => {
  return {
    ...state,
    currentHighlightSelection: CLEAR_DECORATION,
  };
};

const onContentPointerUp = (
  state: State,
  contentId: string,
  containerId: string,
  range: AnnotationRange
): State => {
  if (!range) return state;
  return {
    ...state,
    currentContainerId: containerId,
    currentAnnotationTarget: contentId,
    currentAnnotationRange: range,
  };
};

const onContentPointerDown = (state: State): State => {
  return {
    ...state,
    currentContainerId: undefined,
    currentAnnotationRange: undefined,
    currentAnnotationTarget: undefined,
  };
};

const onHighlight = (state: State): State => {
  return addAnnotation(state, "highlight", state.currentHighlightSelection);
};

const onStrikethrough = (state: State): State => {
  return addAnnotation(state, "strikethrough");
};

const isEmptyRange = (range: AnnotationRange) => range.end - range.start === 0;

const isNotEmptyAnnotation = (annotation: Annotation) =>
  !isEmptyRange(annotation.range);

const compareByStartEnd = (one: Annotation, two: Annotation) => {
  let result = one.range.start - two.range.start;
  if (result === 0) result = one.range.end - two.range.end;
  return result;
};

const cleanDecorations = (annotation: Annotation): Annotation => {
  let cleanedDecorations = [];

  let highlights = annotation.decorations.filter(
    (dec) => dec.type === "highlight"
  );
  let containsHighlightClear = highlights.some(
    (dec) => dec.subtype === CLEAR_DECORATION
  );
  if (!containsHighlightClear && highlights.length > 0)
    cleanedDecorations.push(highlights[highlights.length - 1]);

  let strikethroughs = annotation.decorations.filter(
    (dec) => dec.type === "strikethrough"
  );
  let containsStrikethroughClear = strikethroughs.some(
    (dec) => dec.subtype === CLEAR_DECORATION
  );
  if (!containsStrikethroughClear && strikethroughs.length > 0)
    cleanedDecorations.push(strikethroughs[strikethroughs.length - 1]);

  return { ...annotation, decorations: cleanedDecorations };
};

const annotationDecorationsEquivalent = (
  one: Annotation,
  two: Annotation
): boolean => {
  if (one.decorations.length !== two.decorations.length) return false;
  for (const decOne of one.decorations) {
    let decTwo = two.decorations.find(
      (d) => d.type === decOne.type && d.subtype === decOne.subtype
    );
    if (!decTwo) return false;
  }
  return true;
};

const coalesceAdjacentAnnotations = (
  annotations: Array<Annotation>
): Array<Annotation> => {
  let coalescedAnnotations: Array<Annotation> = [];
  let latestPush: Annotation | null = null;

  let sortedAnnotations = annotations.sort(compareByStartEnd);

  let touchesLatestPush = (annotation: Annotation) =>
    latestPush && annotation.range.start === latestPush.range.end;
  let decorationMatchesLatestPush = (annotation: Annotation) =>
    latestPush && annotationDecorationsEquivalent(latestPush, annotation);

  for (let annotation of sortedAnnotations) {
    if (
      touchesLatestPush(annotation) &&
      decorationMatchesLatestPush(annotation)
    ) {
      latestPush!.range.end = annotation.range.end;
    } else {
      latestPush = { ...annotation };
      coalescedAnnotations.push(latestPush);
    }
  }

  return coalescedAnnotations;
};

const collapseExactRangeMatch = (annotations: Array<Annotation>) => {
  let collapsed: Array<Annotation> = [];
  for (const annotation of annotations) {
    let foundIndex = collapsed.findIndex(
      (a) =>
        a.range.start === annotation.range.start &&
        a.range.end === annotation.range.end
    );

    if (foundIndex < 0) collapsed.push(annotation);
    else {
      const foundAnnotation = collapsed[foundIndex];
      collapsed[foundIndex] = {
        ...foundAnnotation,
        decorations: foundAnnotation.decorations.concat(annotation.decorations),
      };
    }
  }
  return collapsed;
};

const addAnnotation = (
  state: State,
  type: AnnotationDecoration["type"],
  subtype?: string
): State => {
  if (
    !state.currentAnnotationRange ||
    !state.currentAnnotationTarget ||
    !state.currentContainerId ||
    isEmptyRange(state.currentAnnotationRange)
  )
    return state;

  let target = state.currentAnnotationTarget;
  let existingAnnotations = state.annotations[target] || [];

  let newAnnotation: Annotation = {
    range: state.currentAnnotationRange,
    decorations: [
      {
        type,
        subtype: subtype || "",
      },
    ],
  };

  let newStart = newAnnotation.range.start;
  let newEnd = newAnnotation.range.end;

  /** [ newStart ... start  ...  end ... newEnd ] */
  let isEnvelopedByNew = ({ range }: Annotation) =>
    newStart <= range.start && range.end <= newEnd;

  /** start ... [ newStart ... newEnd ] ... end */
  let envelopesNew = ({ range }: Annotation) =>
    range.start <= newStart && newEnd <= range.end;

  /** start ... [ newStart ... end ... newEnd ] */
  let overhangsNewStart = ({ range }: Annotation) =>
    range.start <= newStart && newStart < range.end && range.end < newEnd;

  /** [ newStart ... start ... newEnd ] ... end */
  let overhangsNewEnd = ({ range }: Annotation) =>
    newStart <= range.start && range.start < newEnd && newEnd < range.end;

  let overlapsNew = (annotation: Annotation) =>
    isEnvelopedByNew(annotation) ||
    envelopesNew(annotation) ||
    overhangsNewStart(annotation) ||
    overhangsNewEnd(annotation);

  if (type === "strikethrough") {
    let strikethroughAnnotations = existingAnnotations.filter((a) =>
      a.decorations.some((d) => d.type === "strikethrough")
    );
    let annotationsEnvelopeNew = strikethroughAnnotations.filter(envelopesNew);
    if (annotationsEnvelopeNew.length > 0) {
      newAnnotation.decorations[0].subtype = CLEAR_DECORATION;
    } else {
      let relatedAnnotations = strikethroughAnnotations.filter(
        (a) => isEnvelopedByNew(a) || overhangsNewStart(a) || overhangsNewEnd(a)
      );
      let relatedAnnotationsRangeLength = relatedAnnotations
        .map((a) => {
          let end = Math.min(a.range.end, newAnnotation.range.end);
          let start = Math.max(a.range.start, newAnnotation.range.start);
          return end - start;
        })
        .reduce((sum, val) => sum + val, 0);
      let newAnnotationRangeLength =
        newAnnotation.range.end - newAnnotation.range.start;
      if (newAnnotationRangeLength === relatedAnnotationsRangeLength) {
        newAnnotation.decorations[0].subtype = CLEAR_DECORATION;
      }
    }
  }

  let newAnnotations = [];

  let overlappers = existingAnnotations.filter(overlapsNew);
  if (overlappers.length > 0) {
    let splitOverlappers: Array<Annotation> = overlappers
      .map((annotation) => {
        /** start ... [ newStart ... newEnd ] ... end */
        if (envelopesNew(annotation))
          return [
            {
              ...annotation,
              range: {
                start: annotation.range.start,
                end: newAnnotation.range.start,
              },
            },
            {
              range: {
                start: newAnnotation.range.start,
                end: newAnnotation.range.end,
              },
              decorations: [
                ...annotation.decorations,
                ...newAnnotation.decorations,
              ],
            },
            {
              ...annotation,
              range: {
                start: newAnnotation.range.end,
                end: annotation.range.end,
              },
            },
          ];
        /** start ... [ newStart ... end ... newEnd ] */
        if (overhangsNewStart(annotation))
          return [
            {
              ...annotation,
              range: {
                start: annotation.range.start,
                end: newAnnotation.range.start,
              },
            },
            {
              range: {
                start: newAnnotation.range.start,
                end: annotation.range.end,
              },
              decorations: [
                ...annotation.decorations,
                ...newAnnotation.decorations,
              ],
            },
          ];

        /** [ newStart ... start ... newEnd ] ... end */
        if (overhangsNewEnd(annotation))
          return [
            {
              range: {
                start: annotation.range.start,
                end: newAnnotation.range.end,
              },
              decorations: [
                ...annotation.decorations,
                ...newAnnotation.decorations,
              ],
            },
            {
              ...annotation,
              range: {
                start: newAnnotation.range.end,
                end: annotation.range.end,
              },
            },
          ];

        /** [ newStart ... start  ...  end ... newEnd ] */
        return [
          {
            ...annotation,
            decorations: [
              ...annotation.decorations,
              ...newAnnotation.decorations,
            ],
          },
        ];
      })
      .flat()
      .filter(isNotEmptyAnnotation);

    let newGaps = splitOverlappers
      .filter(isEnvelopedByNew)
      .sort(compareByStartEnd)
      .map((annotation, index, arr) => {
        let gaps = [];
        if (index === 0 && annotation.range.start !== newAnnotation.range.start)
          gaps.push({
            ...newAnnotation,
            range: {
              start: newAnnotation.range.start,
              end: annotation.range.start,
            },
          });
        if (annotation.range.end <= newAnnotation.range.end) {
          let next = arr.length > index + 1 ? arr[index + 1] : undefined;
          gaps.push({
            ...newAnnotation,
            range: {
              start: annotation.range.end,
              end: next?.range.start || newAnnotation.range.end,
            },
          });
        }
        return gaps;
      })
      .flat()
      .filter(isNotEmptyAnnotation);

    let unaffected = existingAnnotations.filter(
      (a) => !overlappers.includes(a)
    );

    newAnnotations = state.annotations[target]
      ? [...unaffected, ...splitOverlappers, ...newGaps]
      : [newAnnotation];
  } else {
    newAnnotations = [...existingAnnotations, newAnnotation];
  }

  let cleanedAnnotations = collapseExactRangeMatch(newAnnotations)
    .map(cleanDecorations)
    .filter((annotation) => annotation.decorations.length > 0);

  let finalizedAnnotations = coalesceAdjacentAnnotations(cleanedAnnotations);

  clearUserTextSelection();

  return {
    ...state,
    annotations: { ...state.annotations, [target]: finalizedAnnotations },
  };
};

const reducer: Reducer<State, Action> = (state, action) => {
  if (!state) return Default;
  switch (action.type) {
    case BEGIN_LOAD_EXAM:
      return Default;
    case SECTION_LOADED:
      let newState = (action as PartialStateAction).state.itemAnnotation;
      if (newState)
        return {
          ...newState,
          currentHighlightSelection:
            newState.currentHighlightSelection ||
            newState.availableHighlightColors[0],
        };
      return state;
    case HIGHLIGHT_DROPDOWN_ROW_CLICKED:
      return onHighlightColorSelection(
        state,
        (action as HighlightSelectionAction).color
      );
    case HIGHLIGHT_CLEAR_DROPDOWN_CLICKED:
      return onHighlightDropdownClearClicked(state);
    case CONTENT_POINTER_UP:
      return onContentPointerUp(
        state,
        (action as ContentAction).contentId,
        (action as ContentAction).containerId,
        (action as ItemContentSelectionAction).range
      );
    case CONTENT_POINTER_DOWN:
      return onContentPointerDown(state);
    case HIGHLIGHT_CLICKED:
      return onHighlight(state);
    case STRIKETHROUGH_CLICKED:
      return onStrikethrough(state);
    default:
      return state;
  }
};

export default reducer;
