import { connect } from "react-redux";
import {
  AppDispatch,
  ChainActions,
  UploadExamFileSelected,
} from "../../../app/actions";
import State from "../../../app/state";
import {
  Actions,
  ManageExamsUploadNew,
  Properties,
} from "../../../components/ManageExamsUploadNew";
import { BeginExamFileUpload } from "../../../ManageExams/reducer";

const mapStateToProps = (state: State): Properties => {
  return {
    uploadFile: state.manageExams.fileBeingUploaded,
    uploadProcessing: state.manageExams.uploadProcessing || false,
    uploadSuccessful: state.manageExams.uploadSuccessful || false,
    uploadFailed: state.manageExams.uploadFailed || false,
  };
};

const mapDispatchToActions = (dispatch: AppDispatch): Actions => {
  return {
    onFileSelected: (file: File) =>
      dispatch(
        ChainActions(UploadExamFileSelected(file), BeginExamFileUpload())
      ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToActions
)(ManageExamsUploadNew);
