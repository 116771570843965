import { Action } from 'redux';

import { getCheckoutSession } from '../api/checkout';
import {
  getProduct,
  getProducts,
} from '../api/product';
import {
  AppThunk,
  CHANGE_MARKETPLACE_QUERY_PARAMETERS,
  PartialStateAction,
  PRODUCT_UPDATED,
  PRODUCTS_LOADED,
  PRODUCTS_LOADING,
  ProductsLoaded,
  ProductUpdated,
  ProductUpdatedAction,
  QueryParametersUpdateAction,
} from '../app/actions';
import { UnrecoverableException } from '../utils/AppError';
import { redirectToCheckoutSession } from '../utils/stripe';
import State, {
  DefaultState,
  Product,
} from './state';

const onProductUpdated = (state: State, update: Product): State => {
  let updatedElement = state.data.find((p) => p.id === update.id);
  if (!updatedElement) return state;

  let updateIndex = state.data.indexOf(updatedElement);
  if (updateIndex === -1) return state;

  return {
    ...state,
    data: [
      ...state.data.slice(0, updateIndex),
      update,
      ...state.data.slice(updateIndex + 1),
    ],
  };
};

const reducer = (state: State, action: Action): State => {
  if (!state) return DefaultState;
  switch (action.type) {
    case PRODUCTS_LOADED:
      return (action as PartialStateAction).state.products || state;
    case PRODUCT_UPDATED:
      return onProductUpdated(state, (action as ProductUpdatedAction).product);
    case CHANGE_MARKETPLACE_QUERY_PARAMETERS:
      let skip = (action as QueryParametersUpdateAction).skip;
      let take = (action as QueryParametersUpdateAction).take;
      return { ...state, skip, take };
    case PRODUCTS_LOADING:
      return { ...state, loading: true };
    default:
      return state;
  }
};

export default reducer;

export const fetchProducts = (): AppThunk => async (dispatch, getState) => {
  try {
    let state = getState().products;
    let request = getProducts(state.skip, state.take);
    dispatch({ type: PRODUCTS_LOADING });
    let response = await request;
    dispatch(ProductsLoaded(response));
  } catch (error) {
    dispatch(UnrecoverableException(error as Error));
  }
};

export const refreshProduct =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      let purchase = await getProduct(id);
      dispatch(ProductUpdated(purchase));
    } catch (error) {
      dispatch(UnrecoverableException(error as Error));
    }
  };

export const redirectToCheckoutThunk =
  (productId: string): AppThunk =>
  async (dispatch) => {
    try {
      let sessionId = await getCheckoutSession(productId);
      redirectToCheckoutSession(sessionId);
    } catch (error) {
      dispatch(UnrecoverableException(error as Error));
    }
  };
