import {
  Box,
  Fade,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import React, { FunctionComponent } from "react";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import { GetColor } from "../../utils/color";
import { ContentAndSkillsHelp } from "./ContentAndSkillsHelp";

export interface Skill {
  label: string;
  description: string;
}

export interface Properties {
  itemId: string;
  answer: string;
  correct: boolean;
  correctAnswer: string;
  skills: Array<Skill>;
  timeInSeconds: number;
}

export const InfoContainer: FunctionComponent<Properties> = ({
  itemId,
  answer,
  correct,
  correctAnswer,
  skills,
  timeInSeconds,
}) => {
  return (
    <Box className="info-container">
      <Fade in={true} timeout={250} key={itemId}>
        <Box className="inner">
          <Table className="answer-table">
            <TableBody>
              <TableRow className={`${!answer ? "wrong" : ""}`}>
                <TableCell colSpan={2} className="answer">
                  {correct ? (
                    <Typography variant="h4">Correct</Typography>
                  ) : !answer ? (
                    <Typography variant="h4">Unanswered</Typography>
                  ) : (
                    <Typography variant="h4">Incorrect</Typography>
                  )}
                </TableCell>
              </TableRow>
              <TableRow
                className={
                  correctAnswer === "A"
                    ? "right"
                    : answer === "A"
                    ? "wrong"
                    : ""
                }
              >
                <TableCell className="answer-label-cell">
                  <Typography variant="h5">A</Typography>
                </TableCell>
                <TableCell>
                  <Typography>
                    {correctAnswer === "A" && <CheckIcon />}
                    {answer === "A" && !correct && <CloseIcon />}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow
                className={
                  correctAnswer === "B"
                    ? "right"
                    : answer === "B"
                    ? "wrong"
                    : ""
                }
              >
                <TableCell className="answer-label-cell">
                  <Typography variant="h5">B</Typography>
                </TableCell>
                <TableCell>
                  <Typography>
                    {correctAnswer === "B" && <CheckIcon />}
                    {answer === "B" && !correct && <CloseIcon />}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow
                className={
                  correctAnswer === "C"
                    ? "right"
                    : answer === "C"
                    ? "wrong"
                    : ""
                }
              >
                <TableCell className="answer-label-cell">
                  <Typography variant="h5">C</Typography>
                </TableCell>
                <TableCell>
                  <Typography>
                    {correctAnswer === "C" && <CheckIcon />}
                    {answer === "C" && !correct && <CloseIcon />}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow
                className={
                  correctAnswer === "D"
                    ? "right"
                    : answer === "D"
                    ? "wrong"
                    : ""
                }
              >
                <TableCell className="answer-label-cell">
                  <Typography variant="h5">D</Typography>
                </TableCell>
                <TableCell>
                  <Typography>
                    {correctAnswer === "D" && <CheckIcon />}
                    {answer === "D" && !correct && <CloseIcon />}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Typography variant="subtitle2">Time Taken</Typography>
          <Typography variant="h5">
            {moment
              .utc(moment.duration(timeInSeconds, "seconds").asMilliseconds())
              .format("m:ss")}
          </Typography>
          <br />
          <Typography variant="subtitle2" display="inline">
            Content & Skills
          </Typography>
          <ContentAndSkillsHelp />
          <Grid container spacing={2}>
            {skills.map((skill, index) => (
              <Grid item key={`${itemId}-${index}`}>
                <Tooltip
                  title={
                    <Typography variant="subtitle2">
                      {skill.description}
                    </Typography>
                  }
                >
                  <Typography
                    variant="h6"
                    className="skill"
                    style={{
                      background: GetColor(skill.label).main,
                      border: `1px solid ${GetColor(skill.label).darker}`,
                    }}
                  >
                    {skill.label}
                  </Typography>
                </Tooltip>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Fade>
    </Box>
  );
};
