import {
  Action,
  Reducer,
} from 'redux';

import { TICK } from '../../utils/AppTimer';
import {
  BEGIN_LOAD_EXAM,
  FLAG_FOR_REVIEW_CLICKED,
  ITEM_NAVIGATION,
  ITEM_RESPONSE_SELECTED,
  ItemAction,
  NEXT,
  PartialStateAction,
  PREVIOUS,
  ResponseAction,
  REVIEW_ALL,
  REVIEW_FLAGGED,
  REVIEW_INCOMPLETE,
  REVIEW_ITEMS_COLLAPSE_TOGGLE,
  REVIEW_SCREEN,
  REVIEW_SUMMARY_COLLAPSE_TOGGLE,
  SECTION_LOADED,
} from '../app/actions';
import State, { Default } from './state';

const onNext = (state: State): State => {
  const isLastItem =
    state.itemIdentifiersReviewSubset &&
    state.itemIdentifiersReviewSubset.length > 0
      ? state.currentItemIndexInSubset! >=
        state.itemIdentifiersReviewSubset.length - 1
      : state.currentItemIndex >= state.itemIdentifiers.length - 1;

  if (isLastItem) {
    if (state.reviewable) {
      return {
        ...state,
        reviewMode: true,
        onReviewScreen: true,
        canPause: false,
        next: false,
        previous: false,
        navigation: false,
        suppressPeriodicTable: true,
        reviewAll: true,
        reviewIncomplete: true,
        reviewFlagged: true,
        endSection: true,
        reviewScreen: false,
        currentItemIndexInSubset: undefined,
        itemIdentifiersReviewSubset: undefined,
      };
    } else return state;
  }

  if (
    state.itemIdentifiersReviewSubset &&
    state.itemIdentifiersReviewSubset.length > 0
  ) {
    let nextSubsetIndex = state.currentItemIndexInSubset! + 1;
    let nextSubsetIdentifier =
      state.itemIdentifiersReviewSubset![nextSubsetIndex];
    let nextActualIndex = state.itemIdentifiers.indexOf(nextSubsetIdentifier);

    return {
      ...state,
      currentItemIndexInSubset: nextSubsetIndex,
      currentItemIndex: nextActualIndex,
      itemsPreviouslyPresented: [
        ...state.itemsPreviouslyPresented,
        state.itemIdentifiers[state.currentItemIndex],
        state.itemIdentifiers[nextActualIndex],
      ].filter((id, index, arr) => index === arr.indexOf(id)),
    };
  }

  return {
    ...state,
    currentItemIndex: state.currentItemIndex + 1,
    itemsPreviouslyPresented: [
      ...state.itemsPreviouslyPresented,
      state.itemIdentifiers[state.currentItemIndex],
      state.itemIdentifiers[state.currentItemIndex + 1],
    ].filter((id, index, arr) => index === arr.indexOf(id)),
  };
};

export const isFirstItem = (state: State): boolean => {
  let usingSubset = (state.itemIdentifiersReviewSubset?.length || 0) > 0;
  return (
    (usingSubset && state.currentItemIndexInSubset === 0) ||
    state.currentItemIndex === 0
  );
};

const onPrevious = (state: State): State => {
  if (isFirstItem(state)) return state;

  if (
    state.itemIdentifiersReviewSubset &&
    state.itemIdentifiersReviewSubset.length > 0
  ) {
    let prevSubsetIndex = state.currentItemIndexInSubset! - 1;
    let prevSubsetIdentifier =
      state.itemIdentifiersReviewSubset![prevSubsetIndex];
    let prevActualIndex = state.itemIdentifiers.indexOf(prevSubsetIdentifier);

    return {
      ...state,
      currentItemIndexInSubset: prevSubsetIndex,
      currentItemIndex: prevActualIndex,
      itemsPreviouslyPresented: [
        ...state.itemsPreviouslyPresented,
        state.itemIdentifiers[state.currentItemIndex],
        state.itemIdentifiers[prevActualIndex],
      ].filter((id, index, arr) => index === arr.indexOf(id)),
    };
  }

  return {
    ...state,
    currentItemIndex: state.currentItemIndex - 1,
    itemsPreviouslyPresented: [
      ...state.itemsPreviouslyPresented,
      state.itemIdentifiers[state.currentItemIndex],
      state.itemIdentifiers[state.currentItemIndex - 1],
    ].filter((id, index, arr) => index === arr.indexOf(id)),
  };
};

const onItemNavigation = (state: State, itemId: string): State => {
  let newIndex = state.itemIdentifiers.indexOf(itemId);
  if (newIndex === -1) return state;

  let newState = {
    ...state,
    currentItemIndex: newIndex,
    itemsPreviouslyPresented: [
      ...state.itemsPreviouslyPresented,
      state.itemIdentifiers[state.currentItemIndex],
      state.itemIdentifiers[newIndex],
    ].filter((id, index, arr) => index === arr.indexOf(id)),
  };

  if (state.reviewMode && state.onReviewScreen)
    return {
      ...newState,
      onReviewScreen: false,
      suppressPeriodicTable: false,
      reviewAll: false,
      reviewIncomplete: false,
      reviewFlagged: false,
      endSection: false,
      reviewScreen: true,
      next: true,
      previous: true,
    };

  return newState;
};

const onReviewScreen = (state: State): State => {
  return {
    ...state,
    onReviewScreen: true,
    canPause: false,
    next: false,
    previous: false,
    navigation: false,
    suppressPeriodicTable: true,
    reviewAll: true,
    reviewIncomplete: true,
    reviewFlagged: true,
    endSection: true,
    reviewScreen: false,
    currentItemIndexInSubset: undefined,
    itemIdentifiersReviewSubset: undefined,
  };
};

const onFlagForReviewClicked = (state: State, itemId: string): State => {
  let index = state.itemsFlaggedForReview.indexOf(itemId);
  if (index === -1)
    return {
      ...state,
      itemsFlaggedForReview: [...state.itemsFlaggedForReview, itemId],
    };

  return {
    ...state,
    itemsFlaggedForReview: [
      ...state.itemsFlaggedForReview.slice(0, index),
      ...state.itemsFlaggedForReview.slice(index + 1),
    ],
  };
};

const onReviewIncomplete = (state: State): State => {
  return onReviewSubset(
    state,
    state.itemIdentifiers.filter(
      (id) => state.itemsCompleted.indexOf(id) === -1
    )
  );
};

const onReviewFlagged = (state: State): State => {
  return onReviewSubset(
    state,
    state.itemsFlaggedForReview.sort(
      (a, b) =>
        state.itemIdentifiers.indexOf(a) - state.itemIdentifiers.indexOf(b)
    )
  );
};

const onReviewSubset = (state: State, identifiers: Array<string>): State => {
  let actualIdentifiers = identifiers.filter(
    (id) => state.itemIdentifiers.indexOf(id) > -1
  );

  if (actualIdentifiers.length === 0) return state;

  return {
    ...state,
    onReviewScreen: false,
    suppressPeriodicTable: false,
    reviewAll: false,
    reviewIncomplete: false,
    reviewFlagged: false,
    endSection: false,
    reviewScreen: true,
    next: true,
    previous: true,
    itemIdentifiersReviewSubset: actualIdentifiers,
    currentItemIndexInSubset: 0,
    currentItemIndex: state.itemIdentifiers.indexOf(actualIdentifiers[0]),
  };
};

const onToggleSummaryCollapse = (state: State): State => {
  return {
    ...state,
    reviewSummaryExpanded: !state.reviewSummaryExpanded,
  };
};

const onToggleItemCollapse = (state: State): State => {
  return {
    ...state,
    reviewItemsExpanded: !state.reviewItemsExpanded,
  };
};

const onItemResponseSelected = (
  state: State,
  itemId: string,
  response: string,
  originalResponse: string
): State => {
  if (response && response !== originalResponse)
    return {
      ...state,
      itemsCompleted: [...state.itemsCompleted, itemId].filter(
        (id, index, arr) => index === arr.indexOf(id)
      ),
    };

  return {
    ...state,
    itemsCompleted: state.itemsCompleted.filter((id) => id !== itemId),
  };
};

const onTimerTick = (state: State): State => {
  if (state.onReviewScreen) return state;
  var itemId = state.itemIdentifiers[state.currentItemIndex];
  var timeSpent = state.timeSpentInSeconds[itemId] || 0;
  return {
    ...state,
    timeSpentInSeconds: {
      ...state.timeSpentInSeconds,
      [itemId]: timeSpent + 1,
    },
  };
};

const reducer: Reducer<State, Action> = (state, action) => {
  if (!state) return Default;

  switch (action.type) {
    case BEGIN_LOAD_EXAM:
      return Default;
    case SECTION_LOADED:
      let newState = (action as PartialStateAction).state.navigation;
      if (newState) return { ...newState };
      return state;
    case NEXT:
      return onNext(state);
    case PREVIOUS:
      return onPrevious(state);
    case ITEM_NAVIGATION:
      return onItemNavigation(state, (action as ItemAction).itemId);
    case FLAG_FOR_REVIEW_CLICKED:
      return onFlagForReviewClicked(state, (action as ItemAction).itemId);
    case REVIEW_SUMMARY_COLLAPSE_TOGGLE:
      return onToggleSummaryCollapse(state);
    case REVIEW_ITEMS_COLLAPSE_TOGGLE:
      return onToggleItemCollapse(state);
    case REVIEW_SCREEN:
      return onReviewScreen(state);
    case REVIEW_ALL:
      return onItemNavigation(state, state.itemIdentifiers[0]);
    case REVIEW_INCOMPLETE:
      return onReviewIncomplete(state);
    case REVIEW_FLAGGED:
      return onReviewFlagged(state);
    case ITEM_RESPONSE_SELECTED:
      return onItemResponseSelected(
        state,
        (action as ItemAction).itemId,
        (action as ResponseAction).response,
        (action as ResponseAction).currentResponse
      );
    case TICK:
      return onTimerTick(state);
    default:
      return state;
  }
};

export default reducer;
