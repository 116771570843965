import { Button, Typography } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { connect } from "react-redux";
import { AppDispatch } from "../../ExamApp/app/actions";
import { NavigateTo } from "../app/actions";

interface Actions {
  onBack: () => void;
}

const SectionBackToSummary: FunctionComponent<Actions> = ({ onBack }) => {
  return (
    <Button onClick={onBack} className="back-navigation">
      <ArrowBackIcon />
      <Typography>Back To Summary</Typography>
    </Button>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: AppDispatch): Actions => {
  return {
    onBack: () => dispatch(NavigateTo("")),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SectionBackToSummary);
