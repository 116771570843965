import { TokenCache } from "../utils/token";
import settings from "./settings";

const imageSerializationResource = (format: string) =>
  `${settings.baseAddress}/ImageSerialization/${format}`;

export const serializeImage = (format: string, file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    fetch(imageSerializationResource(format), {
      method: "POST",
      body: file,
      headers: {
        Authorization: `${TokenCache.provider} ${TokenCache.token}`,
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error();
        return response;
      })
      .then((response) => response.text())
      .then(resolve)
      .catch(reject);
  });
};
