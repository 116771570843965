import { Box, Container } from "@material-ui/core";
import React, { FunctionComponent, useEffect } from "react";
import { connect } from "react-redux";
import { UpdateManageProductsQueryParams } from "../../../app/actions";
import State from "../../../app/state";
import { AppDispatch } from "../../../app/actions";
import { fetchProducts } from "../../../ManageProducts/reducer";
import { Pagination } from "../../../components/Pagination";
import DisplayTable from "./DisplayTable";
import NewProduct from "./NewProduct";

interface Properties {
  skip: number;
  take: number;
  totalRecords: number;
  loggedIn: boolean;
}

interface Actions {
  loadProductDefs: () => void;
  onUpdateQueryParams: (skip: number, take: number) => void;
}

type AllProperties = Properties & Actions;

const ManageProducts: FunctionComponent<AllProperties> = ({
  skip,
  take,
  totalRecords,
  loggedIn,
  loadProductDefs,
  onUpdateQueryParams,
}) => {
  useEffect(() => {
    if (loggedIn) loadProductDefs();
  }, [loadProductDefs, skip, take, loggedIn]);
  return (
    <>
      <Container className="query-results admin">
        <NewProduct />
        <DisplayTable />
      </Container>
      <Box className="query-pagination">
        <Pagination
          skip={skip}
          take={take}
          totalRecords={totalRecords}
          onUpdateQueryParams={onUpdateQueryParams}
        />
      </Box>
    </>
  );
};

const mapStateToProperties = (state: State): Properties => {
  return {
    skip: state.manageProducts.products.skip,
    take: state.manageProducts.products.take,
    totalRecords: state.manageProducts.products.totalRecords,
    loggedIn: state.user.loggedIn,
  };
};

const mapDispatchToActions = (dispatch: AppDispatch): Actions => {
  return {
    loadProductDefs: () => dispatch(fetchProducts()),
    onUpdateQueryParams: (skip: number, take: number) =>
      dispatch(UpdateManageProductsQueryParams(skip, take)),
  };
};

export default connect(
  mapStateToProperties,
  mapDispatchToActions
)(ManageProducts);
